{#if order}
  <div class="order-preview">
    <SlideOutPanel
      {expanded}
      on:toggled={toggled}
      tileCollapsedIconText="Auftragsvorschau öffnen"
      tileExpandedIconText="Auftragsvorschau schliessen"
    >
      <Link title="zum Auftrag" href={link(orderUrl(order))} on:click={event => event.stopPropagation()} slot="title"
        >{order.kind === OrderKind.quote ? 'Preisauskunft' : 'Auftrag'} {titleSuffix}</Link
      >

      <Grid noGutter>
        {#if rows.length > 0}
          <Row padding>
            <Column>
              <DataTable
                size="short"
                headers={[
                  { key: 'sku', value: 'Artikel-Nr.' },
                  { key: 'name', value: 'Bezeichnung' },
                  { key: 'quantity', value: 'Menge' },
                  { key: 'total', value: 'Total' },
                ]}
                {rows}
              >
                <div slot="cell-header" let:header>
                  {#if header.key === 'total'}
                    <div class="right-aligned">
                      {header.value}
                    </div>
                  {:else}
                    {header.value}
                  {/if}
                </div>
                <div slot="cell" let:cell let:row class="row">
                  {#if cell.key === 'total'}
                    <div class="right-aligned">
                      {row.total}
                    </div>
                  {:else}
                    <span>
                      {row[cell.key]}
                    </span>
                  {/if}
                </div>
              </DataTable>
            </Column>
          </Row>
          <Row padding class="sum">
            <Column><strong>Summe</strong></Column>
            <Column class="right-aligned">{formatValue(order.sum)}</Column>
          </Row>
        {/if}
      </Grid>
    </SlideOutPanel>
  </div>
{/if}

<script lang="ts">
  import SlideOutPanel from '../shared/SlideOutPanel.svelte'
  import { liveQuery } from 'dexie'
  import { db } from '../db'
  import { DataTable, Link, Row, Column, Grid } from 'carbon-components-svelte'
  import type { Customer, LineItem } from '../models'
  import { OrderKind, OrderState } from '../models'
  import { orderUrl, link } from '../urls'
  import { localeFromlanguage, localizedAttribute } from '../i18n'
  import { formatValue } from '../format'
  import { rejectEmptyLineItems } from '../orders/order'

  export let customer: Customer

  let titleSuffix = ''
  let rows = []
  let expanded: boolean
  let storageKey

  const toggled = event => sessionStorage.setItem(storageKey, event.detail.expanded)

  const orders = liveQuery(() =>
    db.orders.where({ state: OrderState.draft, customer_id: customer.id }).reverse().sortBy('created_at')
  )

  $: order = $orders?.[0]
  $: {
    if (order) {
      const lineItems: LineItem[] = rejectEmptyLineItems(order.line_items)
      titleSuffix = `(${lineItems.length})`
      rows = lineItems
        .filter(item => item.product)
        .map((item, index) => ({
          id: index,
          sku: item.product.sku,
          name: localizedAttribute(item.product, 'name', localeFromlanguage(customer.language)),
          quantity: item.quantity,
          total: formatValue(item.total),
        }))
      storageKey = `order-preview-expanded-${order.id}`
      expanded = sessionStorage.getItem(storageKey) !== 'false'
    }
  }
</script>

<style lang="scss">
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/generated/_spacing';
  @import '../styles/variables';

  .order-preview {
    :global {
      $th-bg-color: #393939;
      $th-fg-color: #f4f4f4;
      $td-bg-color: #262626;
      $td-fg-color: #c6c6c6;
      th {
        background-color: $th-bg-color;
        color: $th-fg-color;
      }
      td {
        color: $td-fg-color;
        background-color: $td-bg-color;
        border-bottom-color: $th-bg-color;
        border-top-color: $th-bg-color;
      }
      tr:hover td {
        color: $td-fg-color;
        background-color: $td-bg-color;
        border-bottom-color: $th-bg-color;
        border-top-color: $th-bg-color;
      }
      a {
        color: #78a9ff;
        &:hover {
          color: #a6c8ff;
        }
      }
      .bx--data-table-container {
        max-height: calc($slide-out-max-height - 9rem);
        overflow-y: scroll;
      }
      .sum {
        padding-left: $spacing-04;
        padding-right: $spacing-04;
      }
    }
  }
</style>
