import type { TagProps } from 'carbon-components-svelte/types/Tag/Tag.svelte'
import { daysFromNow, daysFromNowAsText } from '../date'

export function getNew(customer) {
  return {
    customer_id: customer.id,
    date: new Date(),
    note: '',
  }
}

export function dateTag(date: string): { text: string; type: TagProps['type'] } {
  const days = daysFromNow(date)
  const text = daysFromNowAsText(days)

  let type = null

  if (days < 0) return null
  else if (days === 0) type = 'teal'
  else if (days === 1) type = 'cyan'
  else if (days > 1) type = 'blue'

  return { text, type }
}
