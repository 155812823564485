<Select
  loaded={loaded(customer)}
  labelText="Währung"
  name="currency"
  selected={customer.currency}
  invalid={errors.currency !== undefined}
  invalidText={errors.currency}
  on:input
>
  <SelectItem />
  {#each Object.entries(currencies) as [key, value]}
    <SelectItem value={key} text={value} />
  {/each}
</Select>

<script lang="ts">
  import type { Customer, AttrErrors } from '../../models'
  import Select from '../../form/Select.svelte'
  import { SelectItem } from 'carbon-components-svelte'
  import { loaded } from '../../helper'
  import currencies from '../../data/customer_currencies.json'

  export let customer: Customer
  export let errors: AttrErrors
</script>
