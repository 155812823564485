<Tag size="sm" class="{shop} no-wrap mt-0 ml-0">{label || shops[shop]}</Tag>

<script lang="ts">
  import { Tag } from 'carbon-components-svelte'
  import shops from '../data/shops.json'

  export let shop
  export let label = undefined
</script>

<style lang="scss">
  :global {
    .hoelzle {
      color: #fff;
      background-color: #0072bd;
    }

    .soltark {
      background-color: #ffb500;
    }
  }
</style>
