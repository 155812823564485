export function extractData(event: Event): Record<string, unknown> {
  if (event instanceof CustomEvent) {
    return (event as CustomEvent).detail
  }

  // eslint-disable-next-line prefer-const
  let data: string | boolean
  const { name, value, type, checked } = event.target as HTMLInputElement
  if (type === 'checkbox') data = checked
  else data = value
  return { [name]: data }
}
