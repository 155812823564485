{#if channels[channel]}
  <svelte:component
    this={channels[channel].icon}
    aria-label={channels[channel].label}
    style="vertical-align: text-top;"
  />
{/if}

<script lang="ts">
  import { Email, Partnership, Phone } from 'carbon-icons-svelte'
  import channelLabels from '../data/customer_engagements_channels.json'

  export let channel

  const channels = {
    visit: {
      icon: Partnership,
      label: channelLabels.visit,
    },
    phone: {
      icon: Phone,
      label: channelLabels.phone,
    },
    email: {
      icon: Email,
      label: channelLabels.email,
    },
  }
</script>
