<OverflowMenuItemWithIcon on:click disabled={!$online}>
  {text}
  {#if !$online}
    <CloudOffline slot="icon" />
  {/if}
</OverflowMenuItemWithIcon>

<script lang="ts">
  import { CloudOffline } from 'carbon-icons-svelte'
  import { online } from '../stores'
  import OverflowMenuItemWithIcon from './OverflowMenuItemWithIcon.svelte'

  export let text
</script>
