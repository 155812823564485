<Row>
  <Column class="mb-07" md={4}>
    <Toggle
      loaded={loaded(customer)}
      labelText="Auftragsbestätigung via E-Mail"
      labelA="Keine Auftragsbestätigung via E-Mail"
      labelB="Auftragsbestätigung via E-Mail"
      name="email_confirmation"
      toggled={customer.email_confirmation}
      on:change={publish}
    />
  </Column>
  <Column class="mb-07" md={4}>
    <TextInput
      model={customer}
      name="email_confirmation_address"
      on:change={publish}
      labelText="E-Mail-Adresse für Auftragsbestätigung"
      disabled={!customer.email_confirmation}
      {errors}
    />
  </Column>
</Row>

<script lang="ts">
  import type { AttrErrors, Customer } from '../models'
  import TextInput from '../form/model/TextInput.svelte'
  import Toggle from '../form/Toggle.svelte'
  import { Row, Column } from 'carbon-components-svelte'
  import { createEventDispatcher } from 'svelte'
  import { loaded } from '../helper'
  import { extractData } from '../event'

  export let customer: Customer
  export let errors: AttrErrors

  const dispatch = createEventDispatcher()

  const publish = event => {
    const data = extractData(event)

    const changedCustomer = { id: customer.id, ...data }

    Object.entries(data).forEach(([name, value]) => {
      if (name == 'email_confirmation_address' && value) {
        changedCustomer['email_confirmation'] = true
      }
    })

    dispatch('change', { customer: changedCustomer })
  }
</script>
