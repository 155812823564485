import SumoLogger from 'sumo-logger'
import { get } from 'svelte/store'
import { buildNumber, currentUser } from './stores'

const opts = {
  endpoint: window.SUMO_LOGIC_ENDPOINT,
  interval: 20000, // Send messages in batches every 20 seconds
  returnPromise: false,
  sendErrors: true,
  sourceName: 'crm/src/logging.ts',
  sourceCategory: 'hoelzle/js/crm',
  raw: true,
}

const logger = opts.endpoint ? new SumoLogger(opts) : console

export enum Level {
  info = 'info',
  warn = 'warn',
  error = 'error',
}

export function slog(event, data = {}, level = Level.info) {
  if (!logger) return

  const time = new Date().toJSON()
  const logLine = [`time=${time} at=${level} event=${event} subsystem=crm build_number=${get(buildNumber)}`]
  const user = get(currentUser)
  if (user) {
    logLine.push(`user_id=${user.id}`)
  }
  const attributes = Object.entries(data)
    .map(([key, value]) => `${key}=${quote(value)}`)
    .join(' ')
  logLine.push(attributes)
  logger.log(logLine.join(' '))
}

// global error handler for rejected Promises
export function enableGlobalErrorHandler() {
  if (!logger) return

  addEventListener('unhandledrejection', function (event) {
    const message = event.reason.message || event.reason?.toString()
    slog('promise_rejected', { message: `"${message}"`, stack: `"${event.reason?.stack}"` }, Level.error)
  })
}

// keep `undefined` values by converting them to `null`.
// by default `JSON.stringify({ a: undefined })` would result in
//   '{}'
// with this we get
//   '{"a":null}'
const keepUndefinedValuesReplacer = (key, value) => (value === undefined ? null : value)

function quote(value) {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value, keepUndefinedValuesReplacer)
  } else {
    return value
  }
}
