<div class="multi-line-combo-box--image">
  <div class="multi-line-combo-box--image__image" style:background-image={url && `url(${url})`} />
  <div>
    <strong>{localizedAttribute(product, 'name', locale)}</strong><br />
    {#if localizedAttribute(product, 'summary', locale)}
      {localizedAttribute(product, 'summary', locale)}<br />
    {/if}
    {#if !isCustom(product)}
      Art. <strong>{product.sku}</strong>
    {:else}
      <br />
    {/if}
  </div>
</div>

<script lang="ts">
  import { productImageUrl } from '../../cloudinary'
  import { localizedAttribute } from '../../i18n'
  import type { Product } from '../../models'
  import { isCustom } from '../../products/product'

  export let product: Product
  export let locale

  let url

  $: {
    if (product) url = productImageUrl(product)
  }
</script>
