<h4 class="mb-07">Faktura</h4>
<EmailConfirmation {customer} {errors} on:change />
<PaperlessInvoice {customer} {errors} on:change />
<Row>
  <Column class="mb-07" md={4}>
    <TextArea model={customer} name="invoice_remark" on:change={publish} labelText="Fakturabemerkung" />
  </Column>
  <Column class="mb-07" md={4}>
    <div class="mb-07">
      <Toggle
        loaded={loaded(customer)}
        labelText="Kein Fakturazusammenzug"
        labelA="Fakturazusammenzug"
        labelB="Kein Fakturazusammenzug"
        name="no_invoice_bundling"
        toggled={customer.no_invoice_bundling}
        on:change={publish}
      />
    </div>
    <div>
      <Toggle
        loaded={loaded(customer)}
        labelText="QR Rechnung"
        labelA="ohne Betrag"
        labelB="mit Betrag"
        name="invoice_with_amount"
        toggled={customer.invoice_with_amount}
        on:change={publish}
      />
    </div>
  </Column>
</Row>
<Row class="mb-10">
  <Column md={4}>
    <Toggle
      loaded={loaded(customer)}
      labelText="Artikel-Ursprungsland und -Zolldetails auf Fakturas"
      labelA="Nicht anzeigen"
      labelB="Anzeigen"
      name="needs_country_of_origin"
      toggled={customer.needs_country_of_origin}
      on:change={publish}
    />
  </Column>
  <Column md={4}>
    <Toggle
      loaded={loaded(customer)}
      labelText="Keine Track E-Mail"
      labelA="Track E-Mail"
      labelB="Keine Track E-Mail"
      name="no_tracking_email"
      toggled={customer.no_tracking_email}
      on:change={publish}
    />
  </Column>
</Row>

<script lang="ts">
  import type { AttrErrors, Customer } from '../models'
  import { Row, Column } from 'carbon-components-svelte'
  import TextArea from '../form/model/TextArea.svelte'
  import { loaded } from '../helper'
  import { extractData } from '../event'
  import Toggle from '../form/Toggle.svelte'
  import PaperlessInvoice from './PaperlessInvoice.svelte'
  import { createEventDispatcher } from 'svelte'
  import EmailConfirmation from './EmailConfirmation.svelte'

  export let customer: Customer
  export let errors: AttrErrors

  const dispatch = createEventDispatcher()

  const publish = event => {
    const data = { customer: { id: customer.id, ...extractData(event) } }

    dispatch('change', data)
  }
</script>
