<Notification {error} />

<Row>
  <Column>
    <h3>Kunden</h3>
  </Column>
</Row>

<Row padding>
  {#if hasRole($currentUser, UserRole.back_office)}
    <Column md={2}>
      <RepSelect selectedId={rep_id} blankItemText="Alle" on:change={setRep} inline size="sm" />
    </Column>
  {/if}
  {#if hasRole($currentUser, UserRole.rep)}
    <Column md={2}>
      <TextInput
        name="rep_category"
        value={rep_category}
        labelText="Kat."
        inline
        loaded
        size="sm"
        on:keyup={debounce(changeRepCategory, 200)}
      />
    </Column>
    <Column md={2}>
      <TextInput
        name="tour"
        value={tour}
        labelText="Tour"
        size="sm"
        inline
        loaded
        on:keyup={debounce(changeTour, 200)}
      />
    </Column>
    <Column md={2} class="mb-05 pt-03">
      <Toggle
        name="include_inactive"
        labelA="Inaktive anzeigen"
        labelB="Inaktive anzeigen"
        size="sm"
        toggled={include_inactive}
        on:toggle={changeIncludeInactive}
      />
    </Column>
  {/if}
  <Column md={2} class="mb-05 right-aligned">
    <Button size="small" kind="ghost" on:click={clear}>Alle aktiven anzeigen</Button>
  </Column>
</Row>

<Row narrow>
  <Column>
    <DataTable
      {headers}
      rows={customers}
      on:click:header={clickHeader}
      on:click:row={editCustomer}
      sortable
      size="short"
      bind:sortKey
      bind:sortDirection
    >
      <Toolbar>
        <ToolbarContent>
          <ToolbarSearch
            placeholder="Kunde nach Nummer oder Stichwörtern suchen"
            on:input={debounce(changeQuery, 200)}
            on:clear={() => setFilter({ query: null })}
            value={query}
            persistent
          />
          <Button icon={Add} on:click={addCustomer}>Kunde hinzufügen</Button>
        </ToolbarContent>
      </Toolbar>
      <svelte:fragment slot="cell-header" let:header>
        {#if header.key.match(/statistics/g)}
          <div class="right-aligned">
            {header.value}
          </div>
        {:else}
          {header.value}
        {/if}
      </svelte:fragment>
      <span slot="cell" let:cell let:row>
        {#if customers.length === 1 && Object.keys(customers[0]).length === 0}
          <SkeletonText />
        {:else if cell.key === 'last_engagement.date'}
          {#if cell.value}
            <nobr>
              <Link href={link(customerUrl(row, 'customer_engagements'))}>
                {tinyDate(cell.value)}
              </Link>
              <Channel channel={row.last_engagement?.channel} />
            </nobr>
          {/if}
        {:else if cell.key.match(/statistics/g)}
          <div class="right-aligned">
            {formatValue(cell.value)}
          </div>
        {:else if cell.key === 'discount'}
          {cell.value || 0}
        {:else if cell.key === 'phone'}
          <nobr>{cell.value || ''}</nobr>
        {:else}
          {cell.value || ''}
          {#if cell.key === 'primary_delivery_address.name'}
            <StatusBadge customer={row} />
          {/if}
        {/if}
      </span>
    </DataTable>
    <PaginationNav {page} total={pages} {shown} on:change={visitPage} class="mb-03" />
  </Column>
</Row>

{#if hasRole($currentUser, UserRole.rep) && statistics}
  <Row narrow>
    <Column>
      <StructuredList condensed>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell><span class="mr-04">Anzahl Kunden:</span> {total}</StructuredListCell>
            <StructuredListCell head class="right-aligned" style="width: 6%;">DJ</StructuredListCell>
            <StructuredListCell head class="right-aligned" style="width: 6%;">LJ</StructuredListCell>
            <StructuredListCell head class="right-aligned" style="width: 6%;">VLJ</StructuredListCell>
            <StructuredListCell head class="right-aligned" style="width: 6%;">VVLJ</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell><strong>Umsatz</strong></StructuredListCell>
            <StructuredListCell class="right-aligned">{formatValue(statistics.year0_revenue_total)}</StructuredListCell>
            <StructuredListCell class="right-aligned">{formatValue(statistics.year1_revenue_total)}</StructuredListCell>
            <StructuredListCell class="right-aligned">{formatValue(statistics.year2_revenue_total)}</StructuredListCell>
            <StructuredListCell class="right-aligned">{formatValue(statistics.year3_revenue_total)}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
    </Column>
  </Row>
{/if}

<svelte:window bind:innerWidth />

<script lang="ts">
  import Notification from '../shared/Notification.svelte'
  import StatusBadge from './StatusBadge.svelte'
  import TextInput from '../form/TextInput.svelte'
  import { getCustomers } from '../persistence'
  import RepSelect from '../customers/rep_and_sector/RepSelect.svelte'
  import {
    Button,
    DataTable,
    PaginationNav,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
    Row,
    Column,
    SkeletonText,
    Link,
    StructuredList,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
    Toggle,
  } from 'carbon-components-svelte'
  import debounce from 'just-debounce'
  import { type Customer, type Pagination, type CustomerStatistics, UserRole } from '../models'
  import { push, querystring } from 'svelte-spa-router'
  import { Add } from 'carbon-icons-svelte'
  import { extractData } from '../event'
  import { hasRole } from '../users/user'
  import { currentUser } from '../stores'
  import { customerUrl, link } from '../urls'
  import { tinyDate } from '../date'
  import { formatValue } from '../format'
  import { LocalObject } from '../local'
  import { visit } from '../query'
  import Channel from '../customer_engagements/Channel.svelte'
  import { paginatePer } from '../pagination'
  import { onMount } from 'svelte'

  let customers: Customer[] = [{}]
  let pagination: Pagination
  let statistics: CustomerStatistics
  let pages: number
  let total: number
  let error: string
  let shown: number
  let innerWidth: number

  // Component values
  let query
  let tour
  let page
  let rep_id
  let rep_category
  let include_inactive

  // These values are concatenated into sort_by that is then sent to the API
  let sortKey
  let sortDirection

  let params
  const defaults = {
    query: '',
    tour: '',
    include_inactive: false,
    rep_id: '',
    page: 0,
    rep_category: '',
    per: paginatePer(200),
  }

  $: pages = (pagination || {}).last
  $: shown = innerWidth < 672 ? 4 : 10
  $: refreshList($querystring)

  let localParams = new LocalObject('crm-customer-list-params')
  onMount(() => visit(localParams.get(), false))

  const editCustomer = event => push(customerUrl(event.detail.id))
  const addCustomer = () => push(customerUrl('new'))

  const setFilter = params => {
    localParams.set(params)
    visit({ ...params, page: null })
  }

  const clickHeader = async event => {
    const key = event.detail.header.key
    if (key.match(/statistics/g)) {
      if (sortDirection === 'ascending') {
        sortDirection = 'descending'
      } else if (sortDirection === 'descending') {
        sortKey = undefined
        sortDirection = undefined
      } else {
        sortKey = key
        sortDirection = 'ascending'
      }
    }
    const sort_by = sortKey && sortDirection ? `${key}_${sortDirection}` : undefined
    setFilter({ sort_by })
  }

  const visitPage = ({ detail: { page } }) => visit({ page: page === defaults.page ? null : page + 1 })
  const changeQuery = event => setFilter({ query: event.target.value })
  const changeTour = event => setFilter(({ tour } = extractData(event)))
  const changeRepCategory = event => setFilter(({ rep_category } = extractData(event)))
  const changeIncludeInactive = event => setFilter({ include_inactive: event.detail.toggled || null })
  const setRep = event => setFilter({ rep_id: extractData(event)?.id })
  const clear = () => setFilter({ query: null, rep_category: null, tour: null, include_inactive: null, sort_by: null })

  const refreshList = (queryString = '') => {
    const urlParams = parseQueryString(queryString) || {}
    params = { ...defaults, ...localParams.get(), ...urlParams }
    ;({ query, tour, page, rep_id, rep_category, include_inactive, sortKey, sortDirection } = params)
    makeRequest()
  }
  const parseQueryString = (queryString = '') => {
    let params = Object.fromEntries(new URLSearchParams(queryString).entries())
    if ('page' in params) params.page = parseInt(params.page) - 1
    if ('include_inactive' in params) params.include_inactive = params.include_inactive === 'true'
    if ('sort_by' in params) {
      const sort = params.sort_by?.match(/(.+)_(ascending|descending)/)?.splice(1) || [undefined, undefined]
      params.sortKey = sort[0]
      params.sortDirection = sort[1]
    }
    return params
  }

  const makeRequest = () => {
    customers = [{}]
    let requestParams = { ...params }
    let page = requestParams.page + 1
    let query = requestParams.query
    delete requestParams.sortKey
    delete requestParams.sortDirection
    delete requestParams.page
    delete requestParams.query
    ;(async () =>
      ({
        customers = [{}],
        pagination = {},
        statistics = {},
        total,
        error,
      } = await getCustomers(page, query, requestParams, true)))()
  }

  // comparer function that keeps the order as is. if we don't do this,
  // DataTable tries to be smart and starts ordering stuff. This fails especially
  // for formatted numbers and dates, and undefined values that always come last
  const keepOrderSorter = () => 0

  let headers = [
    { key: 'id', value: 'Nr.', sort: false },
    { key: 'primary_delivery_address.name', value: 'Name', sort: keepOrderSorter },
    { key: 'primary_delivery_address.industry', value: 'Zusatz', sort: false },
    { key: 'primary_delivery_address.street', value: 'Strasse', sort: false },
    { key: 'primary_delivery_address.zip_code', value: 'PLZ', sort: keepOrderSorter },
    { key: 'primary_delivery_address.city', value: 'Ort', sort: keepOrderSorter },
    { key: 'phone', value: 'Telefon', sort: false },
    { key: 'last_engagement.date', value: 'Kontakt', sort: keepOrderSorter },
  ]

  $: if (hasRole($currentUser, UserRole.rep)) {
    headers = [
      { key: 'rep_category', value: 'Kat.', sort: keepOrderSorter },
      { key: 'id', value: 'Nr.', sort: false },
      { key: 'primary_delivery_address.name', value: 'Name', sort: keepOrderSorter },
      { key: 'primary_delivery_address.zip_code', value: 'PLZ', sort: keepOrderSorter },
      { key: 'primary_delivery_address.city', value: 'Ort', sort: keepOrderSorter },
      { key: 'discount', value: 'GR', sort: false },
      { key: 'phone', value: 'Telefon', sort: false },
      { key: 'tour', value: 'Tour', sort: keepOrderSorter },
      { key: 'last_engagement.date', value: 'Kontakt', sort: keepOrderSorter },
      { key: 'statistics.year0_revenue', value: 'DJ', sort: keepOrderSorter },
      { key: 'statistics.year1_revenue', value: 'LJ', sort: keepOrderSorter },
      { key: 'statistics.year2_revenue', value: 'VLJ', sort: keepOrderSorter },
      { key: 'statistics.year3_revenue', value: 'VVLJ', sort: keepOrderSorter },
    ]
  }
</script>
