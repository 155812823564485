{#if show}
  <div data-invalid-customer-notification>
    <InlineNotification
      class="max-width-100"
      lowContrast
      hideCloseButton
      kind="warning"
      title="Dieser Kunde hat unvollständige Daten."
      subtitle="Solange Pflichtfelder auf dem Kunden ungültig sind, kann es zu Fehlern in der Bearbeitung kommen."
    >
      <div slot="actions">
        <NotificationActionButton on:click>Kunde vervollständigen</NotificationActionButton>
      </div>
    </InlineNotification>
  </div>
{/if}

<script lang="ts">
  import { NotificationActionButton, InlineNotification } from 'carbon-components-svelte'

  export let show: boolean
</script>
