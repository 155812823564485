<Row class="mb-07">
  <Column>
    <DatePicker
      datePickerType="single"
      locale="de"
      dateFormat="d.m.Y"
      flatpickrProps={{ static: true }}
      value={shortDate(date)}
      on:change={changeDate}
    >
      <DatePickerInput labelText="Datum" placeholder="dd.mm.yyyy" />
    </DatePicker>
  </Column>
</Row>
<Row class="mb-07">
  <Column>
    <TextArea model={customerEngagement} name="note" labelText="Notiz" on:change={change} />
  </Column>
</Row>
<Row>
  <Column class="mb-07">
    <Select labelText="Form" name="channel" selected={customerEngagement.channel} on:input={change} loaded>
      <SelectItem />
      {#each Object.entries(channels) as [key, value]}
        <SelectItem value={key} text={value} />
      {/each}
    </Select>
  </Column>
  <Column class="mb-07">
    <Select labelText="Ergebnis" name="outcome" selected={customerEngagement.outcome} on:input={change} loaded>
      <SelectItem />
      {#each Object.entries(outcomes) as [key, value]}
        <SelectItem value={key} text={value} />
      {/each}
    </Select>
  </Column>
</Row>

<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { SelectItem, Row, Column, DatePicker, DatePickerInput } from 'carbon-components-svelte'
  import TextArea from '../form/model/TextArea.svelte'
  import Select from '../form/Select.svelte'
  import { extractData } from '../event'
  import channels from '../data/customer_engagements_channels.json'
  import outcomes from '../data/customer_engagements_outcomes.json'
  import { shortDate } from '../date'

  export let customerEngagement

  const dispatch = createEventDispatcher()

  // since we can't have a different internal date format from the display
  // format in the datepicker we need to convert from the local format to an
  // iso date format
  $: date = new Date(customerEngagement?.date)
  const changeDate = event => {
    const [day, month, year] = extractData(event).dateStr.split('.')
    const newDate = `${year}-${month}-${day}`

    dispatch('change', { customerEngagement: { ...customerEngagement, date: newDate } })
  }

  const change = event => {
    dispatch('change', { customerEngagement: extractData(event) })
  }
</script>
