{#if product && product.non_doc}
  <Tag type="purple" size="sm">
    <TooltipDefinition tooltipText="Kein Doku-Artikel">KDA</TooltipDefinition>
  </Tag>
{/if}

<script lang="ts">
  import { Tag, TooltipDefinition } from 'carbon-components-svelte'
  import type { Product } from '../models'

  export let product: Product
</script>
