{#if loaded}
  <TextArea
    bind:value
    {name}
    {labelText}
    {...$$restProps}
    on:input={e => input(e, name)}
    on:focus={e => focus(e, name)}
    on:change
  >
    <slot name="labelText" slot="labelText">
      {labelText}
    </slot>
  </TextArea>
{:else}
  <FormGroup legendText={$$props.labelText}>
    <SkeletonText heading {width} />
  </FormGroup>
{/if}

<script lang="ts">
  import { TextArea, SkeletonText, FormGroup } from 'carbon-components-svelte'
  import { focus, input } from '../dirty'

  export let name: string
  export let value: string = undefined
  export let loaded = false
  export let width = '100%'
  export let labelText: string
</script>
