<div class="row">
  {#if cell.key === 'position'}
    <div class="sub-row">
      <Position {row} {index} />
    </div>
  {:else if cell.key === 'product'}
    <div class="sub-row">
      <ProductComboBox
        {product}
        {locale}
        shop={customer.shop}
        tabindex={tabindex(index, 1)}
        on:change={event => setLineItemProduct(event)}
        bind:this={productComboBoxRef}
      />
    </div>
    {#if product}
      {#if !isCustom(product)}
        <div class="mb-03">
          {localizedAttribute(product, 'name', locale)}
        </div>
      {/if}

      <div class="mb-03">
        {#if lineItem.comment || showCommentInput}
          <TextInput
            model={row}
            name="comment"
            labelText="Bemerkung"
            hideLabel
            loaded
            bind:ref={commentInputElement}
            on:change={updateComment}
            on:blur={updateComment}
            tabindex={tabindex(index, 3)}
          />
        {:else}
          <Link
            href="#"
            class="pointer"
            tabindex="-1"
            on:click={event => {
              event.preventDefault()
              showCommentInput = true
            }}
            >Notiz hinzufügen
          </Link>
        {/if}
      </div>

      {#if isCustom(product)}
        {#if lineItem.alternative_for}
          <div class="product-warning">Dies ist eine Fremdnummer für "{lineItem.alternative_for}".</div>
          <Link
            href="#"
            class="pointer"
            tabindex="-1"
            on:click={event => replaceProduct(event, lineItem.alternative_for)}>Ersetzen</Link
          >
        {:else}
          <span class="unknown-product">Unbekannter Artikel</span>
        {/if}
      {:else if product && product.non_doc}
        <span class="product-warning">Preis und Verfügbarkeit ohne Gewähr!</span>
      {/if}

      {#if lineItem.successor}
        <div class="product-warning">
          Der Nachfolger lautet "{lineItem.successor}".
          <Link href="#" class="pointer" tabindex="-1" on:click={event => replaceProduct(event, lineItem.successor)}
            >Ersetzen</Link
          >
        </div>
      {/if}

      {#if lineItem.customization}
        <div>
          Artikelnummer Kunde "{lineItem.customization}".
        </div>
      {/if}
    {:else if !isEmptyLineItem(lineItem)}
      Produkt wird geladen...
    {/if}
  {:else if cell.key === 'image_name'}
    <div class="centered sub-row">
      <Image {product} />
      <ProductStatusTag {product} />
    </div>
  {:else if cell.key === 'quantity'}
    <div class="sub-row">
      <TextInput
        model={row}
        name="quantity"
        labelText="Menge"
        hideLabel
        loaded
        light
        readonly={!product}
        on:focus={focus}
        on:blur={setLineItemQuantity}
        bind:ref={quantityElement}
        tabindex={tabindex(index, 2)}
        skipWriteOnFocus={row.quantity}
      />
    </div>
    <div class="right-aligned">
      <PackingUnit {product} />
    </div>
  {:else if cell.key === 'availability'}
    <div class="sub-row centered">
      <Availability {row} />
    </div>
  {:else if cell.key === 'delete'}
    <div class="sub-row">
      {#if !isEmptyLineItem(lineItem)}
        <OverflowMenu {showDelete} {alreadyDelivered} on:setOneOffPrice on:setAlreadyDelivered on:delete />
      {/if}
    </div>
  {:else if cell.key === 'price'}
    <div class="right-aligned sub-row">
      {#if updateInProgress}
        <SkeletonText />
      {:else if row.product?.previous_price}
        <div class="sub-row__multiline">
          {formatValue(attributeValue(row, cell.key))}
          <span class="strikethrough">{row.product?.previous_price}</span>
        </div>
      {:else}
        {formatValue(attributeValue(row, cell.key))}
      {/if}
    </div>

    {#if product}
      <div class="right-aligned">
        <PerUnit {product} />
      </div>
    {/if}
  {:else}
    <div class="right-aligned sub-row">
      {#if updateInProgress}
        <SkeletonText />
      {:else}
        {formatValue(attributeValue(row, cell.key))}
      {/if}
    </div>

    {#if cell.key === 'total'}
      <div class="right-aligned rtl">
        {#if product?.volume_prices}
          <div class="price__row"><VolumePrice volumePrices={product.volume_prices} /></div>
        {/if}

        {#if alreadyDelivered}
          <div class="price__row green">Bereits geliefert <Checkmark size={16} slot="icon" /></div>
        {/if}

        {#if product}
          {#if row.latest_created_on}
            <div class="price__row">
              <Link
                href={link(customerUrl(customer.id, 'purchased_products', { query: product.sku, expandAll: true }))}
                class="pointer"
                tabindex="-1"
              >
                Bezogen am {shortDate(row.latest_created_on)}
              </Link>
            </div>
          {/if}
        {/if}
      </div>
    {/if}
  {/if}
</div>

<script lang="ts">
  import { createEventDispatcher, tick } from 'svelte'
  import { Link, SkeletonText } from 'carbon-components-svelte'
  import { Checkmark } from 'carbon-icons-svelte'
  import ProductComboBox from '../../products/ComboBox.svelte'
  import ProductStatusTag from '../../../shared/ProductStatusTag.svelte'
  import TextInput from '../../../form/model/TextInput.svelte'
  import Image from '../../products/Image.svelte'
  import Position from './Position.svelte'
  import OverflowMenu from './OverflowMenu.svelte'
  import VolumePrice from './VolumePrice.svelte'
  import Availability from './Availability.svelte'
  import PerUnit from './PerUnit.svelte'
  import { attributeValue, isEmptyLineItem } from '../../order'
  import { extractData } from '../../../event'
  import PackingUnit from './PackingUnit.svelte'
  import { localizedAttribute } from '../../../i18n'
  import { formatValue } from '../../../format'
  import { shortDate } from '../../../date'
  import { customerUrl, link } from '../../../urls'
  import { isCustom } from '../../../products/product'
  import type { Product, LineItem } from '../../../models'

  export let cell
  export let row: LineItem
  export let index
  export let showDelete
  export let updateInProgress
  export let locale
  export let customer

  $: lineItem = { ...row }
  $: product = lineItem?.product
  $: alreadyDelivered = lineItem.already_delivered

  let quantityElement
  let commentInputElement
  let showCommentInput = false
  let productComboBoxRef

  const tabindex = (index, position) => index * 3 + position

  const dispatch = createEventDispatcher()

  const focus = async () => {
    // on setting the initial value (after choosing a product) we need to wait
    // for the value to propagate
    while (!quantityElement?.value) await new Promise(r => setTimeout(r, 100))
    quantityElement.select()
  }

  const setLineItemProduct = async event => {
    const updatedProduct: Product = extractData(event)?.product

    if (!updatedProduct) return
    if (product?.sku === updatedProduct.sku) return

    lineItem = {
      ...lineItem,
      product: updatedProduct,
      quantity: updatedProduct.minimum_quantity,
      adjustment: undefined,
      one_off_price: undefined,
    }

    dispatch('change', { lineItem })
  }

  const setLineItemQuantity = async event => {
    const updated: LineItem = extractData(event)

    if (lineItem.quantity === parseInt(updated.quantity)) return

    lineItem = { ...lineItem, adjustment: undefined, ...updated }

    dispatch('change', { lineItem })
  }

  const updateComment = event => {
    const { comment } = extractData(event)

    if (comment !== lineItem.comment) {
      lineItem = { ...lineItem, comment }
      dispatch('change', { lineItem })
    }

    showCommentInput = false
  }

  const replaceProduct = async (event, product_id) => {
    event.preventDefault()

    lineItem.product = { sku: product_id, name: product_id }

    dispatch('change', { lineItem })
    await tick()
    productComboBoxRef.refresh()
  }

  // when the input is shown its reference is present, and we can focus the input
  // we can't directly focus when `showCommentInput` is true since the input
  // element may not be rendered yet at this point
  $: if (showCommentInput && commentInputElement) commentInputElement.focus()
</script>

<style lang="scss">
  @import '../../../styles/variables';
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/themes/scss/generated/_tokens';
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/generated/_spacing';

  .price__row {
    white-space: nowrap;
    direction: ltr;
    display: inline-block;
  }

  .rtl {
    direction: rtl;
  }

  .green {
    color: $support-success;
  }

  .unknown-product {
    color: $support-error;
  }

  .product-warning {
    color: $support-warning-dark;
  }
</style>
