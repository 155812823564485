{#if $orders?.length}
  <DataTable {headers} rows={$orders} class="overflow-bottom " on:click:row={edit} aria-label="Auftragsliste">
    <svelte:fragment slot="cell-header" let:header>
      {#if header.key === 'total'}
        <div class="right-aligned">
          {header.value}
        </div>
      {:else}
        {header.value}
      {/if}
    </svelte:fragment>
    <div
      slot="cell"
      class="bx--data-table__row bx--data-table__row--pointer-cursor"
      class:flex-end={cell.key === 'edit'}
      let:cell
      let:row
    >
      {#if cell.key === 'created_at'}
        {shortDate(row.created_at)}
      {:else if cell.key === 'kind'}
        {row.kind == OrderKind.quote ? 'Preisauskunft' : 'Auftrag'}
      {:else if cell.key === 'customer'}
        {#await getCustomer(row) then orderCustomer}
          {orderCustomer.name} (<Link href={link(customerUrl(orderCustomer.id))}>{orderCustomer.id}</Link>)
        {/await}
      {:else if cell.key === 'line_items'}
        {lineItemsCount(row)}
      {:else if cell.key === 'total'}
        <div class="right-aligned">
          {formatValue(cell.value, '')}
        </div>
      {:else if cell.key === 'state'}
        <StatusBadge order={row} />
      {/if}
    </div>
  </DataTable>
{:else}
  <Notification info="Es sind momentan keine Aufträge vorhanden" />
{/if}

<script lang="ts">
  import { push } from 'svelte-spa-router'
  import { DataTable, Link } from 'carbon-components-svelte'
  import { liveQuery } from 'dexie'
  import { db } from '../db'
  import StatusBadge from './StatusBadge.svelte'
  import Notification from '../shared/Notification.svelte'
  import { shortDate } from '../date'
  import { orderUrl, customerUrl, link } from '../urls'
  import { formatValue } from '../format'
  import { rejectEmptyLineItems } from '../orders/order'
  import { OrderKind } from '../models'

  export let customer = undefined

  let headers = [
    { key: 'created_at', value: 'Datum' },
    { key: 'kind', value: 'Typ' },
    { key: 'line_items', value: 'Bestellzeilen' },
    { key: 'total', value: 'Total' },
    { key: 'state', value: 'Status' },
  ]

  if (!customer) {
    headers.splice(1, 0, { key: 'customer', value: 'Kunde' })
  }

  let orders = liveQuery(() => {
    let query = db.orders

    if (customer) {
      query = query.where({ customer_id: customer.id })
    }

    return query.reverse().sortBy('created_at')
  })

  const edit = ({ detail: order }) => {
    push(orderUrl(order))
  }

  const lineItemsCount = order => rejectEmptyLineItems(order.line_items)?.length || order.line_items_count || 0

  const getCustomer = order => db.customers.get(order.customer_id)
</script>
