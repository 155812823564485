export interface Customer {
  cart_type: string | null
  city: string | null
  solvency_check: string | null
  solvency_checked_at: string | null
  category: string | null
  country: string | null
  currency: string | null
  delivery_addresses: DeliveryAddress[] | null
  delivery_cost_setting: string | null
  delivery_option_id: string | null
  discount: string | null
  dispobox: boolean | null
  postbox: string | null
  ebill: boolean | null
  electronic_billing: boolean | null
  electronic_billing_address: string | null
  eori: string | null
  email: string | null
  email_invoice_copy: boolean | null
  exchange_rate: number | null
  free_delivery_from: number | null
  id: string
  important_note: string | null
  industry: string | null
  joker_used: boolean | null
  language: Language
  logistics_note: string | null
  monthly_invoice: boolean | null
  name: string | null
  new_customer: boolean | null
  no_invoice_bundling: boolean | null
  no_tracking_email: boolean | null
  note: string | null
  payment_behavior: string | null
  payment_conditions: string | null
  phone: string | null
  primary_delivery_address: DeliveryAddress
  prints: string | null
  exports: string | null
  rep_id: string | null
  rep_category: string | null
  rep_contact: string | null
  rep_note: string | null
  reseller: string | null
  special_note: string | null
  street: string | null
  superuser_confirmation: boolean | null
  tax_number: string | null
  tour: string | null
  email_confirmation: boolean | null
  email_confirmation_address: string | null
  website: string | null
  zip_code: string | null
  sector_ids: number[]
  sectors: Sector[]
  status: string | null
  invoice_remark: string | null
  can_activate?: boolean
  can_deactivate?: boolean
  can_be_deleted?: boolean
  active?: boolean
  applied?: boolean
  includeInactive?: boolean
  external_id_required: boolean
  product_export_enabled: boolean
  needs_country_of_origin: boolean
  sector: string | null
  interests: string | null
  comment: string | null
  updated_at: Date | null
  invoice_with_amount: boolean
  skip_risk_assessment: boolean
  users: User[] | null
  has_offer_items: boolean
  shop: Shop
}

export type CustomerStatistics = {
  year0_revenue_total: string
  year1_revenue_total: string
  year2_revenue_total: string
  year3_revenue_total: string
}

export interface FlatFee extends FlatFeeParams {
  id: number
  customer_id: string
  status: string
  subscriber_name: string
  subscriber_email: null | string
  created_at: string
  updated_at: string
}

export type FlatFeeParams = {
  starts_on: Date
  subscriber_id: string
  delivery_option_id: string
}

export type Note = {
  note: string
  headline: string
  key: string
}

export interface DeliveryOption {
  id: number | null
  name: string
  price: number
  localized: {
    price: string
  }
}

export type DeliveryAddress = {
  city: string
  id: string
  industry?: string
  name: string
  postbox?: string
  zip_code: string
  street: string
  country: string
}

export type Rep = {
  id: string
  name: string
}

export type SectorGroup = {
  shop: Shop
  name: string
  sectors: Sector[]
}

export type Sector = {
  id: number
  name: string
}

export type CustomerEngagement = {
  id: number
  date: Date
  note: string
  channel: string
  outcome: string
  customer: Customer
}

export interface User {
  id: string
  full_name: string | null
  first_name: string | null
  last_name: string | null
  gender: 'male' | 'female' | null
  email: string | null
  language?: Language
  phone?: string | null
  mobile?: string | null
  role?: string | null
  owner?: boolean
  net_price?: boolean
  print?: boolean
  deactivated?: boolean
  extended_roles: string[]
  notes: string | null
}

export type UserErrors = {
  full_name?: string
  first_name?: string
  last_name?: string
  gender?: string
  email?: string
  language?: Language
  phone?: string
  mobile?: string
  role?: string
  owner?: string
  net_price?: string
  deactivated?: string
}

export enum UserRole {
  it = 'it',
  back_office = 'back_office',
  rep = 'rep',
}

export enum Language {
  de = 'de',
  fr = 'fr',
  en = 'en',
}

export enum Locale {
  de = 'de',
  fr = 'fr',
}

export enum Shop {
  hoelzle = 'hoelzle',
  soltark = 'soltark',
  undefined = 'undefined',
}

export const ShopAssignment = {
  [Shop.hoelzle]: [1, 3],
  [Shop.soltark]: [2, 3],
  [Shop.undefined]: [1, 2, 3],
}

export type City = {
  zip_code: number
  name: string
}

export interface LineItem {
  id: string
  product_id: string
  product: Product
  quantity: number
  price: string
  net_price: string
  discount: string
  volume_discount: string
  total: string
  availability: string
  adjustment?: Adjustment
  already_delivered: boolean
  comment: boolean
  latest_created_on?: Date
}

export type BillingItem = LineItem

export enum OrderKind {
  order = 'order',
  quote = 'quote',
}

export enum OrderState {
  draft = 'draft',
  draftAdjusted = 'draftAdjusted',
  pending = 'pending',
  completed = 'completed',
  quote = 'quote',
}

export interface Order {
  joker_used: boolean
  id?: string
  customer_id: string
  created_at: Date
  state: OrderState
  vat: string
  vat_rate: string
  delivery_cost: string
  sum: string
  total: string
  line_items?: LineItem[]
  line_items_count?: number
  delivery_address_id: number
  user_id?: string
  buyer_name?: string
  delivery_option_id: number
  external_id: string
  voucher_code: string
  comment: string
  one_time_invoice_in_package: boolean
  dangerous_goods: {
    has_dangerous_goods: boolean
    level: string
    human_level: string
    points: number
    capacity: number
  }
  available_delivery_options: DeliveryOption[]
  locale: Locale // FE only
  kind: OrderKind
}

export interface Product {
  sku: string
  name: string
  name_de: string
  name_fr: string
  summary: string
  summary_de: string
  summary_fr: string
  minimum_quantity: number
  industry_quantity: number
  image_name: string
  image_version: string
  updated_at: Date | null
  per: number
  previous_price: string
  volume_prices: VolumePrice[]
  unit: string
  stock: number
  non_doc: boolean
  published_internally: boolean
}

export interface VolumePrice {
  quantity: number
  price: string
  discount: string
  volume_discount: string
  net_price: string
  kind: string
}

export interface PurchasedProduct {
  product: Product
  latest_created_on: Date
}

export type AttrErrors = Record<string, string>

export type Credentials = {
  username: string
  password: string
}

export type Pagination = {
  next?: number
  last?: number
}

export type Adjustment = {
  quantity: {
    to: string
  }
}

export type Category = {
  id: string
  name_de: string
  name_fr: string
  seq: number
  parent_id: string
  image_url: string
  image_name: string
  slug: string
  updated_at: Date
  is_root: boolean
  has_products: boolean
  ancestors: Category[]
  children: Category[]
}

export type Categorization = {
  category_id: string
  product_id: string
}

// just a shell type without declaring the attributes
export type SevenDay = unknown

export type Meta = {
  pagination?: Pagination
  permitted_associations?: string[]
  permitted_attributes?: string[]
  statistics?: unknown
  total?: number
}

export type JsonAPI<Data> = {
  data: Data
  meta?: Meta
  errors?: Error[]
}

export interface Error {
  code: string
  detail: string
  source: {
    pointer: string
  }
  title: string
  meta: Record<string, string | number>
}
