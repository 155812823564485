{#if order?.state == OrderState.draft}
  <Tag type="magenta" size="sm">Entwurf</Tag>
{:else if order?.state == OrderState.draftAdjusted}
  <Tag type="magenta" size="sm">Entwurf</Tag>
  <Tag type="purple" size="sm">Korrigiert</Tag>
{:else if order?.state == OrderState.pending}
  <Tag type="teal" size="sm">Pendent</Tag>
{:else}
  <Tag type="green" size="sm">Übermittelt</Tag>
{/if}

<script lang="ts">
  import { Tag } from 'carbon-components-svelte'
  import { type Order, OrderState } from '../models'

  export let order: Order
</script>
