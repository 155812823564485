<ComposedModal
  bind:open
  on:close={clear}
  on:click:button--secondary={clear}
  on:submit={() => (flatFee.starts_on ? put() : post())}
  class="flat-fee-modal"
>
  <ModalHeader {title} />
  <ModalBody hasForm class="overflow-visible">
    <Form>
      <Row>
        <Column>
          <div class="mb-07">
            <DatePicker
              datePickerType="single"
              minDate={starts_on}
              class="spacing"
              locale="de"
              dateFormat="d.m.Y"
              flatpickrProps={{ static: true }}
              bind:value={starts_on}
            >
              <DatePickerInput
                disabled={flatFee.starts_on}
                labelText="Startdatum"
                placeholder="dd.mm.yyyy"
                invalid={errors.starts_on !== undefined}
                invalidText={errors.starts_on}
              />
            </DatePicker>
          </div>
        </Column>
        <Column>
          <div class="mb-07">
            <Select
              bind:selected={subscriber_id}
              labelText="Ansprechperson"
              invalid={errors.subscriber !== undefined}
              invalidText={errors.subscriber}
            >
              <SelectItem value="" text="Bitte auswählen…" disabled />
              {#each users as user}
                <SelectItem value={user.id} text={`${user.full_name} – ${user.email}`} />
              {/each}
            </Select>
          </div>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <Select
            disabled={flatFee.starts_on}
            bind:selected={delivery_option_id}
            labelText="Lieferart"
            helperText="Die Lieferart wird bei Aktivierung der Jahreslieferpauschale automatisch als Standartlieferart des Kunden gesetzt."
          >
            <SelectItem text="Ohne" />
            {#each deliveryOptions as option}
              <SelectItem value={option.id} text={`${option.name} – ${option.price}`} />
            {/each}
          </Select>
        </Column>
      </Row>
    </Form>
    <Notification {error} />
  </ModalBody>
  <ModalFooter {primaryButtonText} secondaryButtonText="Abbrechen" />
</ComposedModal>

<script lang="ts">
  import type { FlatFee, User, DeliveryOption } from '../models'
  import {
    ComposedModal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    DatePicker,
    DatePickerInput,
    Column,
    Row,
    Select,
    SelectItem,
  } from 'carbon-components-svelte'
  import { createEventDispatcher, onMount } from 'svelte'
  import { createFlatFee, getDeliveryOptions, updateFlatFee } from '../persistence'
  //flatpickr locale needs to be imported by hand
  import 'flatpickr/dist/l10n/de'
  import Notification from '../shared/Notification.svelte'
  import { fetchSuccessful } from '../helper'
  import { usersWithEmail } from '../users/user'

  export let customer
  export let flatFee: FlatFee
  export let open: boolean

  let users: User[] = []
  let subscriber_id = ''
  let delivery_option_id = ''
  let deliveryOptions: DeliveryOption[] = []
  let starts_on = new Date()
  let errors: { subscriber?: string; starts_on?: string } = {}
  let error: string
  let title
  let primaryButtonText

  const dispatch = createEventDispatcher()
  const clear = () => {
    errors = {}
    error = undefined
    open = false
    flatFee = {} as FlatFee
  }

  const post = async () => {
    ;({ errors = {}, error } = await createFlatFee({
      starts_on,
      subscriber_id,
      delivery_option_id,
    }))
    if (fetchSuccessful(error, errors)) {
      open = false
      dispatch('success')
    }
  }

  const put = async () => {
    ;({ errors = {}, error } = await updateFlatFee({
      subscriber_id,
      id: flatFee.id,
    } as FlatFee))
    if (fetchSuccessful(error, errors)) {
      open = false
      dispatch('success')
    }
  }

  $: (async () =>
    ({ deliveryOptions = [], error } = await getDeliveryOptions({
      flat_fee: true,
    })))()
  $: if (customer) users = usersWithEmail(customer.users)
  $: {
    if (flatFee.starts_on) {
      title = 'JLP ändern'
      primaryButtonText = 'Speichern'
    } else {
      title = 'JLP abschliessen'
      primaryButtonText = 'Abschliessen'
    }
  }

  onMount(() => {
    if (flatFee.starts_on) {
      ;({ subscriber_id, delivery_option_id } = flatFee)
      starts_on = new Date(flatFee.starts_on)
    }
  })
</script>

<style lang="scss" global>
  .flat-fee-modal .bx--modal-container {
    overflow: visible;
  }
</style>
