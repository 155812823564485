export const BLANK = [null, undefined, '']

// See https://stackoverflow.com/questions/5467129/sort-javascript-object-by-key/31102605#31102605
export function sortKeys(object) {
  return Object.keys(object)
    .sort()
    .reduce((sorted, key) => {
      sorted[key] = object[key]
      return sorted
    }, {})
}

export function compact(object) {
  const copy = { ...object }
  Object.keys(copy).forEach(key => isBlank(copy[key]) && delete copy[key])
  return copy
}

export function isBlank(value) {
  return BLANK.includes(value)
}
