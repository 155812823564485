import { get, readable, writable, type Writable } from 'svelte/store'
import { getBuildNumber } from './buildNumber'
import type { Customer, User } from './models'

function signalsStore() {
  const { subscribe, update } = writable({})

  function reset(signal) {
    update(signals => {
      if (signals[signal]) {
        signals[signal].abort()
      }
      return { ...signals, [signal]: new AbortController() }
    })
    return get(this)[signal]
  }

  return {
    subscribe,
    reset,
  }
}

export const currentUser: Writable<User> = writable(undefined)
export const signals = signalsStore()
export const customerId: Writable<Customer['id']> = writable(undefined)
export const fetching: Writable<boolean> = writable(false)
export const dirty: Writable<boolean> = writable(false)
export const success: Writable<{ timeOut?: ReturnType<typeof setTimeout>; successful: boolean }> = writable({
  successful: undefined,
})
export const online: Writable<boolean> = writable(true)
export const syncCount: Writable<number> = writable(0)

export const buildNumber = readable(undefined, set => {
  getBuildNumber().then(set)
})
