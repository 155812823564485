{#if product.per}
  per<br />
  {product.per}
  {product.unit}
{/if}

<script lang="ts">
  import type { Product } from '../../../models'

  export let product: Product
</script>
