<Modal
  size="xs"
  bind:open={confirmActivate}
  modalHeading="Kunde aktivieren?"
  primaryButtonText="Kunde aktivieren"
  secondaryButtonText="Abbrechen"
  on:close={() => (confirmActivate = false)}
  on:click:button--secondary={() => (confirmActivate = false)}
  on:submit={activate}
>
  Eine Willkommens-E-Mail wird an den Kunden gesendet wenn du bestätigst.
</Modal>

<Row>
  <div data-error-container>
    <Notification {error} />
  </div>
  <Notification {success} hideCloseButton={false} on:close={() => (success = undefined)} />
</Row>

<Row>
  <Column>
    <h5>{customer.id}</h5>
    {#if loaded(customer)}
      <h3 class="mb-05">
        {customer.primary_delivery_address.name}

        <ShopBadge shop={customer.shop} />
        <StatusBadge {customer} />

        <div class="float-right flex-end">
          <Button size="field" kind="ghost" href={erpUrl(`customers/${customer.id}`)} target="_blank">ERP ↗︎</Button>

          {#if customer.has_offer_items}
            <Button size="field" kind="ghost" href={erpUrl(`/customers/${customer.id}/special_prices`)} target="_blank"
              >Spezialpreise ↗︎</Button
            >
          {/if}
          <Button size="field" kind="ghost" on:click={() => push(catalogUrl(customer))}>Katalog</Button>

          <DisabledFields disabled={!$online}>
            <HeadingOverflowMenu {customer} on:change on:delete={destroy} />
          </DisabledFields>

          {#if customer['active?']}
            <Button size="field" on:click={createOrder}>Auftrag erstellen</Button>
          {/if}
          {#if customer['can_activate?'] && customer['applied?']}
            <DisabledFields disabled={!$online}>
              <Button size="field" on:click={() => (confirmActivate = true)}>Kunde aktivieren</Button>
            </DisabledFields>
          {/if}
        </div>
      </h3>
    {:else}
      <SkeletonText heading width="30%" />
    {/if}
  </Column>
</Row>

<script lang="ts">
  import { loaded } from '../helper'
  import { Row, Column, SkeletonText, Button } from 'carbon-components-svelte'
  import Modal from '../shared/Modal.svelte'
  import { push, replace } from 'svelte-spa-router'
  import { createEventDispatcher } from 'svelte'
  import StatusBadge from './StatusBadge.svelte'
  import HeadingOverflowMenu from './HeadingOverflowMenu.svelte'
  import Notification from '../shared/Notification.svelte'
  import type { Customer } from '../models'
  import { db } from '../db'
  import { deleteCustomer } from '../persistence'
  import { getNewOrder } from '../modelDefaults'
  import { catalogUrl, orderUrl, erpUrl } from '../urls'
  import { slog } from '../logging'
  import ShopBadge from './ShopBadge.svelte'
  import DisabledFields from '../shared/DisabledFields.svelte'
  import { online } from '../stores'

  export let customer: Customer
  export let success: string
  export let error: string

  let confirmActivate = false

  const dispatch = createEventDispatcher()

  const activate = () => {
    confirmActivate = false
    const data = {
      customer: { id: customer.id, status: 'active' },
      successMessage: 'Dieser Kunde ist nun aktiv. Willkommens-E-Mails wurden versendet.',
    }

    slog('customer_activate', { customer_id: customer.id })
    dispatch('change', data)
  }

  const createOrder = async () => {
    const id = await db.orders.add(await getNewOrder(customer))

    slog('order_create', { order_offline_id: id, customer_id: customer.id })
    push(orderUrl(id, customer))
  }

  const destroy = async event => {
    ;({ error } = await deleteCustomer(event.detail.customer.id))
    if (!error) {
      replace('/')
    }
  }
</script>
