import cl from 'cloudinary-core'

export const assetHost = 'assets.hoelzle.ch'

const cloudinary = cl.Cloudinary.new({
  cloud_name: 'hoelzle',
  private_cdn: true,
  cname: assetHost,
  secure_distribution: assetHost,
})

export function productImageUrl(product, number = undefined) {
  let imageName
  let imageVersion

  if (number) {
    imageName = product[`image${number}_name`] || product.images?.[number]?.name
    imageVersion = product[`image${number}_version`] || product.images?.[number]?.version
  } else {
    imageName = product.image_name || product.images?.[0]?.name
    imageVersion = product.image_version || product.images?.[0]?.version
  }

  if (!imageName) return undefined

  return cloudinary.url(imageName, {
    effect: 'make_transparent',
    width: 40,
    height: 40,
    crop: 'fit',
    format: 'png',
    version: imageVersion,
  })
}
