import 'carbon-components-svelte/css/white.css'
import App from './App.svelte'
import './styles/App.scss'
import * as Sentry from '@sentry/svelte'

const dsn = window.SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN
const env = window.SENTRY_CURRENT_ENV || import.meta.env.MODE
if (import.meta.env.DEV) {
  console.debug(`[Sentry] dsn=${dsn} env=${env}`)
}
Sentry.init({
  dsn: dsn,
  environment: env,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.2,
  tracePropagationTargets: ['localhost', /^\/api/, /^https:\/\/api\..*hoelzle.*/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
})

const app = new App({
  target: document.body,
})

export default app
