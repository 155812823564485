<script lang="ts">
  import { onMount, onDestroy } from 'svelte'
  import { online } from '../stores'
  import { ping } from '../api'
  import { slog } from '../logging'

  let timeout

  async function poll() {
    if (timeout) clearTimeout(timeout)

    if (!navigator.onLine) {
      $online = false
    } else {
      $online = await ping()
    }

    timeout = setTimeout(poll, 5000)
  }

  async function offline() {
    if (timeout) clearTimeout(timeout)

    $online = false
  }

  onMount(async () => {
    window.addEventListener('online', poll)
    window.addEventListener('offline', offline)

    poll()
  })

  onDestroy(() => {
    window.removeEventListener('online', poll)
    window.removeEventListener('offline', offline)

    if (timeout) clearTimeout(timeout)
  })

  let onlineBefore = $online
  $: {
    if (onlineBefore !== $online) {
      let connection = {}
      if (navigator.connection) {
        // see https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation#instance_properties
        for (const property of ['type', 'effectiveType', 'rtt', 'downlink', 'downlinkMax', 'saveData']) {
          connection[property] = navigator.connection[property]
        }
      }
      slog($online ? 'app_online' : 'app_offline', connection)
      onlineBefore = $online
    }
  }
</script>
