// The single responsibility of this file is to make api calls including
// setting headers and url parameters.
// No assumptions are made about the consumers — no error handling, just http requests.

import { get } from 'svelte/store'
import type {
  AttrErrors,
  BillingItem,
  Categorization,
  Category,
  City,
  Customer,
  CustomerEngagement,
  DeliveryAddress,
  DeliveryOption,
  FlatFee,
  FlatFeeParams,
  JsonAPI,
  Locale,
  Order,
  Product,
  PurchasedProduct,
  Rep,
  SectorGroup,
  SevenDay,
  Shop,
  User,
} from './models'
import { paginatePer } from './pagination'
import { customerId, dirty, fetching, signals, success } from './stores'
import { location, querystring } from 'svelte-spa-router'
import { erpUrl } from './urls'
import isTest from './testing'
import { attributeErrors, requestModel, requestOrderModel } from './api-model'

const baseUrl = () => (window.API !== undefined ? window.API : import.meta.env.VITE_API || '/api')

const defaultHeaders = {
  'Content-Type': 'application/json',
  ClientId: 'crm',
}
const query = params => new URLSearchParams(params).toString()
export const options = (includeCustomerId = true, includeCredentials = true) => {
  const headers = { ...defaultHeaders }

  if (includeCustomerId) {
    const customer = get(customerId)
    if (customer) {
      headers['CustomerId'] = customer
    }
  }

  const options = { headers }

  if (includeCredentials) {
    options['credentials'] = 'include'
  }

  return options
}

let setFetchingTimeout
function setFetching(done: boolean) {
  if (setFetchingTimeout) clearTimeout(setFetchingTimeout)

  if (done) fetching.set(done)
  else setFetchingTimeout = setTimeout(() => fetching.set(done), 1000)
}

export function setHeaderSuccess(successful: boolean) {
  // clear existing timeout
  const previousTimeout = get(success)?.timeOut
  if (previousTimeout) clearTimeout(previousTimeout)

  if (successful === undefined) {
    success.set({ successful })
  } else {
    const displayDuration = isTest ? 5000 : 2000
    const timeOut = setTimeout(() => success.set({ successful: undefined }), displayDuration)
    success.set({ timeOut, successful })
  }
}

const controllers = []
export async function dataFetch<Type>(
  url: string,
  {
    fetchOptions = options(),
    hasAttrErrors = false,
    returnsData = false,
    showErrorMessage = true,
    controller = new AbortController(),
  }: {
    fetchOptions?
    hasAttrErrors?
    returnsData?
    showErrorMessage?
    controller?
  }
): Promise<{ error?: string; errors?: AttrErrors; json?: Type; status?: number }> {
  try {
    setFetching(true)

    controllers.push(controller)

    const response = await fetch(url, { ...fetchOptions, signal: controller.signal })
    if (response.ok) {
      if (returnsData) {
        const json = await response.json()
        const responseObject = { json }
        if (hasAttrErrors && json.errors) {
          responseObject.json.errors = attributeErrors(json.errors)
        }
        return responseObject
      }
      setHeaderSuccess(true)
      dirty.set(false)
      return {}
    } else if (response.status === 401) {
      // Somehow we can trigger `window.location` multiple times, probably because it won't cancel current requests.
      // Make sure it happens only once, as it will unnecessarily trigger the auth flow multiple times.
      controllers.forEach(x => x.abort())

      const locationPart = get(location)
      const queryPart = get(querystring)
      const url = queryPart ? `${locationPart}?${queryPart}` : locationPart
      window.location = erpUrl('session/auto', { crm_return_to: url })

      // TODO: get rid of the `error` property altogether and just act on the `status` code
      return { error: `${response.status}`, status: response.status }
    } else {
      setHeaderSuccess(showErrorMessage ? false : undefined)
      if (hasAttrErrors) {
        const json = await response.json()
        return { errors: attributeErrors(json.errors), error: `${response.status}`, status: response.status }
      } else {
        const json = await response.json()
        const errors = attributeErrors(json.errors)
        if (errors['base']) {
          return { error: errors['base'] }
        }
      }
      return { error: `${response.status}`, status: response.status }
    }
  } catch (error) {
    if (error.name === 'AbortError') return {}
    setHeaderSuccess(showErrorMessage ? false : undefined)
    return { error: error.message }
  } finally {
    controllers.splice(controllers.indexOf(controller), 1)
    setFetching(false)
  }
}

export async function ping(): Promise<boolean> {
  const controller = new AbortController()
  // if the ping takes longer than 3 seconds the connection is too slow, which
  // is equivalent to being offline.
  setTimeout(() => controller.abort(), 3000)

  try {
    const response = await fetch(`${baseUrl()}/ping.jpg?no-cache=${Math.random().toString().substring(2)}`, {
      method: 'HEAD',
      signal: controller.signal,
    })
    if (response.ok) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export function postToken(
  username: string,
  password: string
): Promise<{ error?: string; errors?: AttrErrors; json?: { access_token: string } }> {
  return dataFetch<{ access_token: string }>(`${baseUrl()}/oauth/token`, {
    fetchOptions: {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({ username, password }),
    },
    returnsData: true,
  })
}

export function getFlatFees(): Promise<{ json?: JsonAPI<FlatFee[]> }> {
  return dataFetch<JsonAPI<FlatFee[]>>(`${baseUrl()}/flat_fees`, { returnsData: true })
}

export function getReps(): Promise<{ json?: JsonAPI<Rep[]> }> {
  return dataFetch<JsonAPI<Rep[]>>(`${baseUrl()}/reps`, { returnsData: true })
}

export function getCustomers(
  page: number,
  searchQuery = '',
  params: {
    updated_since?: Date | string
    sort_by?: string
    tour?: string
    rep_category?: string
    per?: number
    includeInactive?: boolean
  },
  sequential: boolean
): Promise<{ error?: string; errors?: AttrErrors; json?: JsonAPI<Customer[]> }> {
  const urlParams = query({ page, per: paginatePer(), q: searchQuery, ...params })
  const url = `${baseUrl()}/crm/customers?${urlParams}`

  let controller
  if (sequential) {
    controller = signals.reset('customer')
  }

  return dataFetch<JsonAPI<Customer[]>>(url, {
    fetchOptions: options(false),
    returnsData: true,
    controller,
  })
}

export function getCustomer(): Promise<{ json?: JsonAPI<Customer>; error?: string }> {
  return dataFetch<JsonAPI<Customer>>(`${baseUrl()}/crm/customers/${get(customerId)}`, {
    fetchOptions: options(false),
    hasAttrErrors: true,
    returnsData: true,
  })
}

export function getUser(userId: User['id']): Promise<{ json?: JsonAPI<User>; error?: string }> {
  return dataFetch<JsonAPI<User>>(`${baseUrl()}/users/${userId}`, { returnsData: true, showErrorMessage: false })
}

export function getDeliveryOptions(params: {
  flat_fee?: boolean
  internal?: boolean
}): Promise<{ json?: JsonAPI<DeliveryOption[]>; error?: string }> {
  const url = params?.internal ? `${baseUrl()}/crm/delivery_options` : `${baseUrl()}/delivery_options?${query(params)}`

  return dataFetch<JsonAPI<DeliveryOption[]>>(url, { returnsData: true })
}

export function getProduct(id: string): Promise<{ json?: JsonAPI<Product>; error?: string }> {
  const url = `${baseUrl()}/products/${id}`
  return dataFetch<JsonAPI<Product>>(url, { returnsData: true })
}

export function getProducts(
  page: number,
  searchTerm?: string,
  params?: { updated_since?: Date | string; locale?: Locale; novelty?: boolean; promotion?: boolean }
): Promise<{ json?: JsonAPI<Product[]>; error?: string }> {
  const urlParams = { page: page, per: paginatePer(), ...params }

  if (searchTerm) {
    urlParams['q'] = searchTerm
    urlParams['locale'] = params.locale
  }

  const url = `${baseUrl()}/crm/products?${query(urlParams)}`
  return dataFetch<JsonAPI<Product[]>>(url, { returnsData: true })
}

export function getPurchasedProducts(
  customer: Customer,
  page: number,
  searchTerm?: string,
  sortBy?: string
): Promise<{ json?: JsonAPI<PurchasedProduct[]>; error?: string }> {
  const params = { page, per: paginatePer() }
  if (searchTerm) params['q'] = searchTerm
  if (sortBy) params['sort_by'] = sortBy

  return dataFetch<JsonAPI<PurchasedProduct[]>>(
    `${baseUrl()}/crm/customers/${customer.id}/purchased_products?${query(params)}`,
    {
      returnsData: true,
    }
  )
}

export function getPurchasedProductsBillingItems(
  customer: Customer | number,
  productId: string,
  per?: number
): Promise<{ json?: JsonAPI<BillingItem[]>; error?: string }> {
  const params = {}
  if (per) params['per'] = per

  const customerId = customer?.['id'] || customer
  productId = encodeURIComponent(productId)

  return dataFetch<JsonAPI<BillingItem[]>>(
    `${baseUrl()}/crm/customers/${customerId}/purchased_products/${productId}?${query(params)}`,
    {
      returnsData: true,
    }
  )
}

export function getCategories(
  page: number,
  params?: { updated_since?: Date; shop?: Shop }
): Promise<{ json?: JsonAPI<Category[]>; error?: string }> {
  const urlParams = { page: page, per: 100, ...params }

  const url = `${baseUrl()}/crm/categories?${query(urlParams)}`

  return dataFetch<JsonAPI<Category[]>>(url, { returnsData: true })
}

export function getCategory(
  id: string,
  params?: { products_page?: number }
): Promise<{ json?: JsonAPI<Category>; error?: string }> {
  const urlParams = { products_per: paginatePer(), ...params }
  const url = `${baseUrl()}/crm/categories/${id}?${query(urlParams)}`

  return dataFetch<JsonAPI<Category>>(url, { returnsData: true })
}

export function getCategorizations(): Promise<{ json?: JsonAPI<Categorization[]>; error?: string }> {
  const url = `${baseUrl()}/crm/categorizations`
  return dataFetch<JsonAPI<Categorization[]>>(url, { returnsData: true })
}

export function getCustomerEngagements(
  page: number,
  pastOnly: boolean,
  rep: Rep,
  searchQuery = '',
  customer?: Customer,
  date?: string
): Promise<{ json?: JsonAPI<CustomerEngagement[]>; error?: string }> {
  const params = { q: searchQuery, page, per: paginatePer() }
  if (customer) params['customer_id'] = customer.id
  if (pastOnly) params['past_only'] = true
  if (rep) params['rep_id'] = rep.id
  if (date) params['date'] = date

  return dataFetch<JsonAPI<CustomerEngagement[]>>(`${baseUrl()}/crm/customer_engagements?${query(params)}`, {
    returnsData: true,
  })
}

export function postCustomerEngagement(
  customerEngagement: CustomerEngagement
): Promise<{ errors?: AttrErrors; error?: string }> {
  return dataFetch(`${baseUrl()}/crm/customer_engagements`, {
    fetchOptions: {
      ...options(),
      method: 'POST',
      body: JSON.stringify({ customer_engagement: customerEngagement }),
    },
    hasAttrErrors: true,
  })
}

export function putCustomerEngagement(
  customerEngagement: CustomerEngagement
): Promise<{ errors?: AttrErrors; error?: string }> {
  return dataFetch(`${baseUrl()}/crm/customer_engagements/${customerEngagement.id}`, {
    fetchOptions: {
      ...options(),
      method: 'PUT',
      body: JSON.stringify({ customer_engagement: customerEngagement }),
    },
    hasAttrErrors: true,
  })
}

export function deleteCustomerEngagement(id: CustomerEngagement['id']): Promise<{ error?: string }> {
  return dataFetch(`${baseUrl()}/crm/customer_engagements/${id}`, {
    fetchOptions: {
      ...options(),
      method: 'DELETE',
    },
  })
}

let getOrderDryRunController
export async function getOrderDryRun(order: Order): Promise<{ json?: JsonAPI<Order>; error?: string }> {
  if (getOrderDryRunController) {
    getOrderDryRunController.abort()
  }

  getOrderDryRunController = new AbortController()

  const response = await dataFetch<JsonAPI<Order>>(
    `${baseUrl()}/crm/orders/dry_run?${query({ locale: order.locale })}`,
    {
      fetchOptions: {
        ...options(),
        method: 'POST',
        body: JSON.stringify({ order: requestOrderModel(order) }),
        signal: getOrderDryRunController.signal,
      },
      hasAttrErrors: true,
      returnsData: true,
    }
  )

  getOrderDryRunController = undefined

  return response
}

export function getSevenDaysList(): Promise<{ json?: JsonAPI<SevenDay[]>; error?: string }> {
  return dataFetch<JsonAPI<SevenDay[]>>(`${baseUrl()}/crm/seven_days_list`, { returnsData: true })
}

export function postDeliveryAddress(
  delivery_address: DeliveryAddress
): Promise<{ errors?: AttrErrors; error?: string }> {
  return dataFetch(`${baseUrl()}/delivery_addresses`, {
    fetchOptions: {
      ...options(),
      method: 'POST',
      body: JSON.stringify({ delivery_address }),
    },
    hasAttrErrors: true,
  })
}

export function postFlatFee(flatFee: FlatFeeParams): Promise<{ error?: string; errors?: AttrErrors }> {
  return dataFetch(`${baseUrl()}/flat_fees`, {
    fetchOptions: {
      ...options(),
      method: 'POST',
      body: JSON.stringify(flatFee),
    },
    hasAttrErrors: true,
  })
}

export function postUser(user: User): Promise<{ error?: string; errors?: AttrErrors }> {
  return dataFetch(`${baseUrl()}/users`, {
    fetchOptions: {
      ...options(),
      method: 'POST',
      body: JSON.stringify({ user }),
    },
    hasAttrErrors: true,
  })
}

export function postOrder(order: Order): Promise<{ error?: string; status?: number; errors?: AttrErrors }> {
  return dataFetch(`${baseUrl()}/crm/orders`, {
    fetchOptions: {
      ...options(),
      method: 'POST',
      body: JSON.stringify({ order: requestOrderModel(order) }),
    },
    hasAttrErrors: true,
  })
}

export function putPassword(
  email: User['email'],
  new_password: string
): Promise<{ errors?: AttrErrors; error?: string }> {
  return dataFetch(`${baseUrl()}/crm/passwords/${email}`, {
    fetchOptions: {
      ...options(),
      method: 'PUT',
      body: JSON.stringify({ new_password }),
    },
    hasAttrErrors: true,
  })
}

export function postPassword(email: User['email']): Promise<{ error?: string }> {
  return dataFetch(`${baseUrl()}/crm/passwords`, {
    fetchOptions: {
      ...options(),
      method: 'POST',
      body: JSON.stringify({ email: email }),
    },
  })
}

export function postCustomer(
  customer: Customer
): Promise<{ json?: JsonAPI<Customer>; errors?: AttrErrors; error?: string }> {
  return dataFetch<JsonAPI<Customer>>(`${baseUrl()}/customers`, {
    fetchOptions: {
      ...options(),
      method: 'POST',
      body: JSON.stringify({ customer: requestModel(customer, ['delivery_addresses', 'users']) }),
    },
    hasAttrErrors: true,
    returnsData: true,
  })
}

export function putCustomer(customer: Customer): Promise<{ errors?: AttrErrors; error?: string }> {
  return dataFetch(`${baseUrl()}/customers/${customer.id}`, {
    fetchOptions: {
      ...options(),
      method: 'PUT',
      body: JSON.stringify({ customer }),
    },
    hasAttrErrors: true,
  })
}

export function putDeliveryAddress(
  delivery_address: DeliveryAddress
): Promise<{ errors?: AttrErrors; error?: string }> {
  return dataFetch(`${baseUrl()}/delivery_addresses/${delivery_address.id}`, {
    fetchOptions: {
      ...options(),
      method: 'PUT',
      body: JSON.stringify({ delivery_address }),
    },
    hasAttrErrors: true,
  })
}

export function putFlatFee(flat_fee: FlatFee): Promise<{ errors?: AttrErrors; error?: string }> {
  return dataFetch(`${baseUrl()}/flat_fees/${flat_fee.id}`, {
    fetchOptions: {
      ...options(),
      method: 'PUT',
      body: JSON.stringify({ flat_fee }),
    },
    hasAttrErrors: true,
  })
}

export function putUser(user: User): Promise<{ errors?: AttrErrors; error?: string }> {
  return dataFetch(`${baseUrl()}/users/${user.id}`, {
    fetchOptions: {
      ...options(),
      method: 'PUT',
      body: JSON.stringify({ user }),
    },
    hasAttrErrors: true,
  })
}

export function deleteDeliveryAddress(id: DeliveryAddress['id']): Promise<{ error?: string }> {
  return dataFetch(`${baseUrl()}/delivery_addresses/${id}`, {
    fetchOptions: {
      ...options(),
      method: 'DELETE',
    },
  })
}

export function deleteUser(userId: User['id']): Promise<{ error?: string }> {
  return dataFetch(`${baseUrl()}/users/${userId}`, {
    fetchOptions: {
      ...options(),
      method: 'DELETE',
    },
  })
}

export function deleteCustomer(id: Customer['id']): Promise<{ error?: string }> {
  return dataFetch(`${baseUrl()}/customers/${id}`, {
    fetchOptions: {
      ...options(),
      method: 'DELETE',
    },
  })
}

export function getSectorGroups(): Promise<{ json?: JsonAPI<SectorGroup[]> }> {
  return dataFetch<JsonAPI<SectorGroup[]>>(`${baseUrl()}/sector_groups`, { returnsData: true })
}

export function getCities(): Promise<{ json?: JsonAPI<City[]> }> {
  return dataFetch<JsonAPI<City[]>>(`${baseUrl()}/cities`, { returnsData: true })
}

export default {
  ping,
  postToken,
  getCustomers,
  getCustomer,
  getFlatFees,
  getReps,
  getUser,
  getDeliveryOptions,
  getSectorGroups,
  getCities,
  getProduct,
  getProducts,
  getPurchasedProducts,
  getPurchasedProductsBillingItems,
  getCategories,
  getCategory,
  getCategorizations,
  getCustomerEngagements,
  getOrderDryRun,
  getSevenDaysList,
  postDeliveryAddress,
  postFlatFee,
  postUser,
  postCustomerEngagement,
  postOrder,
  postPassword,
  putPassword,
  postCustomer,
  putCustomer,
  putDeliveryAddress,
  putFlatFee,
  putUser,
  putCustomerEngagement,
  deleteDeliveryAddress,
  deleteUser,
  deleteCustomer,
  deleteCustomerEngagement,
  dataFetch,
}
