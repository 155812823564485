{#if loaded}
  <p>{value}</p>
{:else}
  <SkeletonText {width} />
{/if}

<script lang="ts">
  import { SkeletonText } from 'carbon-components-svelte'

  export let value: string
  export let loaded = false
  export let width = '100%'
</script>
