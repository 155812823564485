{#if volumePrices[0]?.quantity > 0}
  <Tooltip triggerText={kind} direction="left" class="bx-tooltip--normal-font" tabindex="-1">
    <svelte:fragment slot="icon">
      <Information size={20} />
    </svelte:fragment>
    <StructuredList condensed>
      <StructuredListHead>
        <StructuredListRow head>
          {#each headers.map(header => header.value) as heading}
            <StructuredListCell head>{heading}</StructuredListCell>
          {/each}
        </StructuredListRow>
      </StructuredListHead>
      <StructuredListBody>
        {#each volumePrices as volumePrice}
          <StructuredListRow>
            {#each headers.map(header => header.key) as attribute}
              <StructuredListCell class="right-aligned">{volumePrice[attribute]}</StructuredListCell>
            {/each}
          </StructuredListRow>
        {/each}
      </StructuredListBody>
    </StructuredList>
  </Tooltip>
{:else}
  <div>{kind}</div>
{/if}

<script lang="ts">
  import {
    Tooltip,
    StructuredList,
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListHead,
  } from 'carbon-components-svelte'
  import { Information } from 'carbon-icons-svelte'
  import type { VolumePrice } from '../../../models'

  export let volumePrices: VolumePrice[]

  const headers = [
    { key: 'quantity', value: 'Menge' },
    { key: 'price', value: 'Preis' },
    { key: 'discount', value: 'GR' },
    { key: 'volume_discount', value: 'MR' },
    { key: 'net_price', value: 'Netto' },
  ]

  const kinds = {
    customer_discount: 'Mengenrabatt',
    group_discount: 'Mengenrabatt',
    product_discount: 'Mengenrabatt',
    promotion: 'Aktion',
    special: 'Spezialpreis',
    line_item_one_off: 'Angepasster Preis',
  }

  $: kind = kinds[volumePrices[0]?.kind]
</script>
