{#if $count}
  <Tag type="magenta">{$count}</Tag>
{/if}

<script lang="ts">
  import { Tag } from 'carbon-components-svelte'
  import { liveQuery } from 'dexie'
  import { db } from '../db'
  import type { Customer } from '../models'

  export let customer: Customer

  let count = liveQuery(() => db.orders.where({ customer_id: customer.id }).count())
</script>
