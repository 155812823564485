<Select
  loaded={loaded(customer)}
  labelText="Zahler"
  name="payment_behavior"
  selected={customer.payment_behavior}
  invalid={errors.payment_behavior !== undefined}
  invalidText={errors.payment_behavior}
  on:input
>
  <SelectItem />
  {#each Object.entries(payment_behaviors) as [key, value]}
    <SelectItem value={key} text={value} />
  {/each}
</Select>

<script lang="ts">
  import type { Customer, AttrErrors } from '../../models'
  import Select from '../../form/Select.svelte'
  import { SelectItem } from 'carbon-components-svelte'
  import { loaded } from '../../helper'
  import payment_behaviors from '../../data/customer_payment_behaviors.json'

  export let customer: Customer
  export let errors: AttrErrors
</script>
