<LineItemsTable {order} {customer} on:loading={toggleLoading} on:change />

<StructuredList condensed>
  <StructuredListBody>
    <StructuredListRow>
      <StructuredListCell>Summe</StructuredListCell>
      <StructuredListCell class="right-aligned">CHF</StructuredListCell>
      <StructuredListCell class="right-aligned min-width">
        <PriceCell value={order.sum} {loading} />
      </StructuredListCell>
    </StructuredListRow>
    <StructuredListRow>
      <StructuredListCell>Porto & Verpackung</StructuredListCell>
      <StructuredListCell class="right-aligned">CHF</StructuredListCell>
      <StructuredListCell class="right-aligned min-width">
        <PriceCell value={order.delivery_cost} {loading} />
      </StructuredListCell>
    </StructuredListRow>
    <StructuredListRow>
      <StructuredListCell>MwSt. {order.vat_rate || ''}</StructuredListCell>
      <StructuredListCell class="right-aligned">CHF</StructuredListCell>
      <StructuredListCell class="right-aligned min-width">
        <PriceCell value={order.vat} {loading} />
      </StructuredListCell>
    </StructuredListRow>
    <StructuredListRow>
      <StructuredListCell><strong>Total</strong></StructuredListCell>
      <StructuredListCell class="right-aligned"><strong>CHF</strong></StructuredListCell>
      <StructuredListCell class="right-aligned min-width">
        <PriceCell value={order.total} {loading} strong />
      </StructuredListCell>
    </StructuredListRow>
  </StructuredListBody>
</StructuredList>

<script lang="ts">
  import { StructuredList, StructuredListBody, StructuredListCell, StructuredListRow } from 'carbon-components-svelte'
  import PriceCell from './line_items/PriceCell.svelte'
  import LineItemsTable from './line_items/Table.svelte'
  import type { Customer, Order } from '../models'
  import { extractData } from '../event'

  export let order: Order
  export let customer: Customer

  let loading = false

  const toggleLoading = event => {
    ;({ loading } = extractData(event))
  }
</script>
