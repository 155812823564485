{#if customer.status == 'applied'}
  <Tag size="sm" type="teal">Neukunde</Tag>
{:else if customer.status == 'inactive'}
  <Tag size="sm" type="magenta">Inaktiv</Tag>
{/if}

<script lang="ts">
  import { Tag } from 'carbon-components-svelte'
  import type { Customer } from '../models'

  export let customer: Customer
</script>
