{#if cell.key === 'order'}
  <div class="sub-row__compact">
    <TextInput
      name={getQuantityFieldName(product)}
      value={dirtyPurchasedProducts[product.sku]?.quantity}
      labelText="Menge"
      hideLabel
      loaded
      light
      disabled={!product.published_internally || dirtyPurchasedProducts[product.sku]?.pending}
      on:change={event => setQuantity(product, event)}
      on:keyup={event => {
        if (event.key === 'Enter') {
          addToOrder()
        }
      }}
    />
  </div>
  <PackingUnit {product} oneLine />
{:else if cell.key === 'cart'}
  <div class="sub-row__compact">
    <Button kind="ghost" title="Bestellen" on:click={() => addToOrder()} disabled={!product.published_internally}>
      {#if success[product.sku]}
        <Checkmark class="green" />
      {:else}
        <ShoppingCart />
      {/if}
    </Button>
  </div>
{/if}

<script lang="ts">
  import { Button } from 'carbon-components-svelte'
  import { ShoppingCart, Checkmark } from 'carbon-icons-svelte'
  import TextInput from '../form/TextInput.svelte'
  import PackingUnit from '../orders/line_items/table/PackingUnit.svelte'
  import { extractData } from '../event'
  import { appendToDraftOrder } from '../orders/order'
  import { clear } from '../dirty'
  import type { Product } from '../models'

  export let cell
  export let product: Product
  export let customer
  export let dirtyPurchasedProducts
  export let success

  const addToOrder = async () => {
    const entriesWithQuantity = Object.values(dirtyPurchasedProducts).filter(({ quantity }) => quantity)
    await appendToDraftOrder(customer, entriesWithQuantity)

    for (const { product } of entriesWithQuantity) {
      dirtyPurchasedProducts[product.sku].pending = true
    }

    Object.values(dirtyPurchasedProducts)
      .filter(({ pending }) => pending)
      .forEach(({ product }) => {
        delete dirtyPurchasedProducts[product.sku]
        dirtyPurchasedProducts = { ...dirtyPurchasedProducts }
        success[product.sku] = true
        clear(getQuantityFieldName(product))
        setTimeout(() => (success[product.sku] = false), 3000)
      })
  }

  const getQuantityFieldName = (product: Product) => `quantity-${product.sku}`

  const setQuantity = (product, event) => {
    dirtyPurchasedProducts[product.sku] = {
      product: product,
      quantity: extractData(event)[getQuantityFieldName(product)],
    }
  }
</script>

<style lang="scss" global>
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/themes/scss/generated/_tokens';

  .green {
    fill: $support-success;
  }
</style>
