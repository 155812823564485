<Header company="Hoelzle" platformName="CRM" href="#/">
  <HeaderNav>
    <HeaderNavItem text="Kunden" href="#/" isSelected={$location === '/' || $location.match(/^\/customers/)} />
    <HeaderNavItem
      text="Kundenkontakt"
      href="#/customer_engagements"
      isSelected={$location === '/customer_engagements'}
    />
    {#if hasRole($currentUser, UserRole.rep)}
      <HeaderNavItem text="Meine Aufträge" href="#/my_orders" isSelected={$location === '/my_orders'} />
      <HeaderNavItem text="7-Tages-Liste" href="#/seven_days_list" isSelected={$location === '/seven_days_list'} />
    {/if}
    <HeaderNavItem text="Shop ↗︎" href={shopUrl('shop')} target="_blank" />
  </HeaderNav>
  <HeaderUtilities>
    {#if $fetching}
      <div transition:fade class="center-wheel">
        <Loading withOverlay={false} small class="center-header" />
      </div>
    {:else if $success?.successful === true}
      <div transition:fade={{ duration: transitionDuration }} class="center-header">
        <Notification
          hideCloseButton={false}
          lowContrast={false}
          success="Änderungen erfolgreich gespeichert"
          on:close={() => setHeaderSuccess(undefined)}
        />
      </div>
    {:else if $success?.successful === false}
      <div transition:fade={{ duration: transitionDuration }} class="center-header">
        <Notification
          hideCloseButton={false}
          lowContrast={false}
          error="Änderungen konnten nicht gespeichert werden"
          on:close={() => setHeaderSuccess(undefined)}
        />
      </div>
    {/if}
    <SyncStatusIndicator />
    <HeaderAction bind:open aria-label="Benutzer-Menu">
      <svelte:fragment slot="icon">
        <UserAvatarFilledAlt size={20} fill="white" />
      </svelte:fragment>
      <HeaderPanelLinks>
        <HeaderPanelDivider>
          {#if $currentUser}
            Angemeldet als {$currentUser.full_name} ({getDisplayRoles($currentUser)})
          {/if}
        </HeaderPanelDivider>
        <small class="build-number">Revision {$buildNumber}</small>
      </HeaderPanelLinks>
    </HeaderAction>
  </HeaderUtilities>
</Header>

<script lang="ts">
  import { fetching, success, currentUser } from '../stores'
  import { location } from 'svelte-spa-router'
  import { fade } from 'svelte/transition'
  import isTest from '../testing'
  import { setHeaderSuccess } from '../api'
  import { getDisplayRoles } from '../users/user'
  import {
    Header,
    HeaderUtilities,
    HeaderAction,
    HeaderPanelLinks,
    HeaderNav,
    HeaderNavItem,
    HeaderPanelDivider,
    Loading,
  } from 'carbon-components-svelte'
  import Notification from '../shared/Notification.svelte'
  import { UserAvatarFilledAlt } from 'carbon-icons-svelte'
  import SyncStatusIndicator from './SyncStatusIndicator.svelte'
  import { shopUrl } from '../urls'
  import { hasRole } from '../users/user'
  import { UserRole } from '../models'
  import { buildNumber } from '../stores'

  let open = false

  const transitionDuration = isTest ? 0 : 400
</script>

<style lang="scss" global>
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/_breakpoint';
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/generated/_spacing';
  @import 'carbon-components/scss/globals/scss/typography';

  .center-wheel {
    align-self: center;
    margin-bottom: 4px;
    position: absolute;
    right: $spacing-10 + $spacing-09;
  }
  .center-header {
    > div {
      margin-top: 0;
      margin-bottom: 0;

      @include breakpoint-down('md') {
        min-width: auto;
      }

      > div {
        @include breakpoint-down('md') {
          margin-right: 0;
        }
      }
    }
    .bx--inline-notification__subtitle {
      @include breakpoint-down('md') {
        display: none;
      }
    }
  }

  .build-number {
    position: absolute;
    bottom: $spacing-03;
    left: $spacing-03;
    font-size: map-get($legal-01, font-size);
  }
</style>
