{#if row.phone}
  <Link href={`tel:${noWhiteSpace(row.phone)}`} class="block no-wrap">{`T: ${row.phone}`}</Link>
{/if}

{#if row.mobile}
  <Link href={`tel:${noWhiteSpace(row.mobile)}`} class="block no-wrap">{`M: ${row.mobile}`}</Link>
{/if}

<script>
  import { Link } from 'carbon-components-svelte'
  import { noWhiteSpace } from '../helper'

  export let row
</script>
