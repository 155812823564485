<h4 class="mb-08">Allgemeine Kontaktdaten</h4>
<Row class="mb-10" data-general-contact>
  <Column class="mb-07" md={4}>
    <TextInput model={customer} name="phone" labelText="Allg. Telefon" {errors} on:change={publish} {loaded} />
  </Column>
  <Column class="mb-07" md={4}>
    <TextInput model={customer} name="email" labelText="Allg. E-Mail" {errors} on:change={publish} {loaded} />
  </Column>
  <Column class="mb-07" md={4}>
    <TextInput model={customer} name="website" labelText="Website" {errors} on:change={publish} {loaded} />
  </Column>
  <Column md={4}>
    <Language {customer} {errors} on:input={publish} />
  </Column>
</Row>

<script lang="ts">
  import type { AttrErrors, Customer } from '../models'
  import { Row, Column } from 'carbon-components-svelte'
  import { extractData } from '../event'
  import { createEventDispatcher } from 'svelte'
  import TextInput from '../form/model/TextInput.svelte'
  import Language from './Language.svelte'

  export let customer: Customer
  export let errors: AttrErrors
  export let loaded = false

  const dispatch = createEventDispatcher()

  const publish = event => {
    dispatch('change', { customer: { id: customer.id, ...extractData(event) } })
  }
</script>
