<div class={compact(['availability', row?.availability]).join(' ')} />

<script lang="ts">
  import { compact } from '../../../array'

  export let row
</script>

<style lang="scss">
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/themes/scss/generated/_tokens';
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/generated/_spacing';

  .availability {
    display: inline-block;
    height: $spacing-04;
    width: $spacing-04;

    border-radius: 100%;

    &.available {
      background-color: $support-success;
    }

    &.partially_available {
      background-color: $support-warning;
    }

    &.not_available {
      background-color: $support-error;
    }
  }
</style>
