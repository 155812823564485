export async function getBuildNumber() {
  return new Promise(resolve => {
    const messageChannel = new MessageChannel()
    messageChannel.port1.onmessage = event => {
      const buildNumber = event.data ? event.data.slice(0, 6) : 'dev'
      resolve(buildNumber)
    }

    navigator.serviceWorker.ready.then(registration => {
      registration.active.postMessage({ type: 'GET_REVISION' }, [messageChannel.port2])
    })
  })
}
