<Row>
  <Column class="mb-07" md={2}>
    <TextInput {model} name="zip_code" labelText="Postleitzahl" {errors} on:change on:change={setZipCode} {loaded} />
  </Column>
  <Column class="mb-07" md={6}>
    {#if all}
      <ComboBox
        titleText="Ort"
        {items}
        bind:selectedId
        name="city"
        aria-label="city"
        bind:value
        invalid={errors.city !== undefined}
        invalidText={errors.city}
        bind:open
        on:select={selectCity}
        on:focus={focusCity}
        bind:this={cityRef}
      />
    {:else}
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="bx--label">Ort</label>
      <DropdownSkeleton />
    {/if}
  </Column>
</Row>

<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import type { AttrErrors } from '../../models'
  import { getCities } from '../../persistence'
  import { Row, Column, ComboBox, DropdownSkeleton } from 'carbon-components-svelte'
  import TextInput from '../../form/model/TextInput.svelte'
  import { unique } from '../../array'

  export let model = {}
  export let errors: AttrErrors = {}
  export let loaded

  const dispatch = createEventDispatcher()

  let all
  let items
  let selectedId
  let value
  let open = false
  let ready = false
  let cityRef

  // synchronize the whole async mess we initially try to tame
  onMount(async () => {
    await loadCities()
    while (!loaded) await new Promise(r => setTimeout(r, 100))
    await setItems(true)
    value = model.city
    ready = true
  })

  const setItems = async force => {
    if (!ready && !force) return

    let selection = value || model.zip_code ? [...all] : []

    if (model.zip_code) {
      const zipCodeAsInt = parseInt(model.zip_code)
      selection = all.filter(city => city.zip_code === zipCodeAsInt)
    }

    if (value) {
      selection = selection.filter(city => city.name.toLowerCase().includes(value.toLowerCase()))
    }

    // add the typed in value always to the list so it's considerd a valid value
    if (value) {
      // we need a unique id, otherwise the ComboBox will not assume a new
      // value when typing another custom value
      const id = `new-${Math.random().toString().substring(2)}`
      selection.unshift({ id: id, name: value })
      selectedId = id
    }

    selection = unique(selection, (x, y) => x.name === y.name)

    items = selection.map(item => ({ id: item.id, text: item.name })).slice(0, 20)
  }

  const selectCity = () => {
    dispatch('change', { city: value })
  }

  const focusCity = () => {
    if (selectedId === undefined) {
      open = true
    }
  }

  const loadCities = async () => {
    if (all) return

    all = (await getCities()) || []
  }

  const setZipCode = () => {
    cityRef.clear()
    setTimeout(() => (open = true), 100)
  }

  // whenever the input changes we update our filtered list
  $: {
    value || model.zip_code || selectedId
    setItems()
  }

  // dispatch change event when the combobox has no value
  $: {
    if (ready && selectedId === undefined) {
      dispatch('change', { city: null })
    }
  }
</script>
