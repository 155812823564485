<Row class="mb-07">
  <Column class="mb-07" md={4}>
    {#if loaded(customer)}
      <RadioButtonGroup orientation="vertical" legendText="Papierlose Rechnung" selected={paperless}>
        <RadioButton labelText="keine" name="keine" value="false" on:change={updatePaperless} />
        <RadioButton
          labelText="Faktura via E-Mail"
          name="electronic_billing"
          value="electronic_billing"
          on:change={updatePaperless}
        />
        <RadioButton labelText="eBill" name="ebill" value="ebill" on:change={updatePaperless} />
      </RadioButtonGroup>
    {/if}
  </Column>
  <Column md={4}>
    <div class="mb-07">
      <Tooltip>
        <svelte:fragment slot="triggerText">
          <label for="customer-electronic_billing_address">E-Mail Adresse für Fakturas</label>
        </svelte:fragment>
        <p>
          Wenn leer, wird die Faktura an die Bestellperson oder – falls auch leer – an die allgemeine E-Mail-Adresse des
          Kunden gesendet.
        </p>
      </Tooltip>
      <TextInput
        model={customer}
        name="electronic_billing_address"
        on:change={publish}
        id="customer-electronic_billing_address"
        labelText=""
        disabled={!electronic_billing}
        {errors}
      />
    </div>
    <div class="mb-07">
      <Toggle
        loaded={loaded(customer)}
        labelText="Gedruckte Faktura Kopie"
        labelA="Keine gedruckte Faktura Kopie"
        labelB="Gedruckte Faktura Kopie"
        name="email_invoice_copy"
        toggled={customer.email_invoice_copy}
        on:change={publish}
        disabled={!electronic_billing}
      />
    </div>
    <TextInput model={customer} name="ebill_id" on:change={publish} labelText="eBill RECIPIENTID" {errors} />
  </Column>
</Row>

<script lang="ts">
  import type { AttrErrors, Customer } from '../models'
  import TextInput from '../form/model/TextInput.svelte'
  import Toggle from '../form/Toggle.svelte'
  import { Row, Column, RadioButtonGroup, RadioButton, Tooltip } from 'carbon-components-svelte'
  import { createEventDispatcher } from 'svelte'
  import { loaded } from '../helper'
  import { extractData } from '../event'

  export let customer: Customer
  export let errors: AttrErrors

  let paperless: 'false' | 'electronic_billing' | 'ebill'
  let electronic_billing: boolean
  let ebill: boolean

  $: {
    paperless = (customer.electronic_billing && 'electronic_billing') || (customer.ebill && 'ebill') || 'false'
  }
  $: ({ ebill, electronic_billing } = customer)

  const dispatch = createEventDispatcher()

  const updatePaperless = event => {
    paperless = event.target.value
    electronic_billing = paperless === 'electronic_billing'
    ebill = paperless === 'ebill'

    const changedCustomer = {
      id: customer.id,
      electronic_billing,
      ebill,
    }

    dispatch('change', { customer: changedCustomer })
  }

  const publish = event => {
    const data = extractData(event)

    const changedCustomer = { id: customer.id, ...data }

    Object.entries(data).forEach(([name, value]) => {
      if (name == 'ebill_id' && value) {
        changedCustomer['ebill'] = true
        changedCustomer['electronic_billing'] = false
      }
    })

    dispatch('change', { customer: changedCustomer })
  }
</script>
