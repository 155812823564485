<Modal
  size="xs"
  bind:open={confirmActivate}
  modalHeading="Kunde wieder aktivieren?"
  primaryButtonText="Kunde wieder aktivieren"
  secondaryButtonText="Abbrechen"
  on:close={() => (confirmActivate = false)}
  on:click:button--secondary={() => (confirmActivate = false)}
  on:submit={confirm}
>
  <p>Es wird keine E-Mail zur Bestätigung versendet.</p>
</Modal>

<Modal
  danger
  size="xs"
  bind:open={confirmDeactivate}
  modalHeading="Kunde deaktivieren?"
  primaryButtonText="Kunde deaktivieren"
  secondaryButtonText="Abbrechen"
  on:close={() => (confirmDeactivate = false)}
  on:click:button--secondary={() => (confirmDeactivate = false)}
  on:submit={deactivate}
>
  <p>Möchtest du Kunde {customer.id}, {customer.primary_delivery_address.name} wirklich deaktivieren?</p>
</Modal>

<Modal
  danger
  size="xs"
  bind:open={confirmDestroy}
  modalHeading="Kunde löschen?"
  primaryButtonText="Löschen"
  secondaryButtonText="Abbrechen"
  on:close={() => (confirmDestroy = false)}
  on:click:button--secondary={() => (confirmDestroy = false)}
  on:submit={destroy}
>
  <p>Kunde wird unwiederruflich gelöscht.</p>
</Modal>

<OverflowMenu flipped label="Weitere Optionen" iconDescription="Weitere Optionen" aria-label="customer-actions">
  {#if customer['can_activate?'] && customer['inactive?']}
    <OverflowMenuItem text="Wieder aktivieren" on:click={() => (confirmActivate = true)} />
  {:else}
    <OverflowMenuItem
      text="Deaktivieren"
      disabled={!customer['can_deactivate?']}
      on:click={() => (confirmDeactivate = true)}
    />
  {/if}

  <OverflowMenuItem
    danger
    text="Löschen"
    aria-label="delete-customer"
    disabled={!customer['can_be_deleted?']}
    on:click={() => (confirmDestroy = true)}
  />
</OverflowMenu>

<script lang="ts">
  import { OverflowMenu, OverflowMenuItem } from 'carbon-components-svelte'
  import Modal from '../shared/Modal.svelte'
  import { createEventDispatcher } from 'svelte'
  import type { Customer } from '../models'
  import { slog } from '../logging'

  export let customer: Customer

  let confirmActivate = false
  let confirmDeactivate = false
  let confirmDestroy = false

  const dispatch = createEventDispatcher()

  const confirm = () => {
    confirmActivate = false
    const data = {
      customer: { id: customer.id, status: 'active' },
      successMessage: 'Kunde erfolgreich wieder aktiviert.',
    }

    slog('customer_reactivate', { customer_id: customer.id })
    dispatch('change', data)
  }

  const deactivate = () => {
    confirmDeactivate = false
    const data = {
      customer: { id: customer.id, status: 'inactive' },
      successMessage: 'Kunde erfolgreich deaktiviert.',
    }

    slog('customer_deactivate', { customer_id: customer.id })
    dispatch('change', data)
  }

  const destroy = () => {
    confirmDestroy = false
    const data = {
      customer: { id: customer.id },
      successMessage: 'Kunde erfolgreich gelöscht.',
    }

    slog('customer_delete', { customer_id: customer.id })
    dispatch('delete', data)
  }
</script>
