<div class="list" {...$$restProps}>
  {#if loaded}
    <div class="mb-02">
      <slot />
    </div>
    {#if editable}
      <p>
        <Link href="#" class="pointer" on:click={openModal}>Bearbeiten</Link>
      </p>
    {/if}
  {:else}
    <SkeletonText />
    <SkeletonText />
    <SkeletonText />
    <SkeletonText />
    <SkeletonText />
  {/if}
</div>

<script lang="ts">
  import { Link, SkeletonText } from 'carbon-components-svelte'
  import { createEventDispatcher } from 'svelte'

  export let loaded: boolean
  export let editable: boolean

  const dispatch = createEventDispatcher()

  const openModal = event => {
    event.preventDefault()
    dispatch('openModal')
  }
</script>

<style lang="scss">
  .list {
    p {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
</style>
