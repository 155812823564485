<h4 class="mb-07">Kategorisierungen</h4>
<Row>
  {#if meta?.permitted_attributes?.includes('rep_id')}
    <Column class="mb-07" md={4}>
      <RepSelect selectedId={customer.rep_id} on:change={publishRep} />
    </Column>
  {/if}
  {#if meta?.permitted_attributes?.includes('rep_category') && meta?.permitted_attributes?.includes('tour')}
    <Column class="mb-07" md={4}>
      <TextInput model={customer} name="rep_category" labelText="Kategorie AD" {errors} on:change={publish} />
    </Column>
    <Column class="mb-07" md={4}>
      <TextInput model={customer} name="tour" labelText="Tour" {errors} on:change={publish} />
    </Column>
  {/if}
  <Column class="mb-07" md={4}>
    <Select
      loaded={loaded(customer)}
      labelText="Kategorie"
      name="category"
      selected={customer.category}
      on:input={publish}
    >
      <SelectItem />
      {#each Object.entries(categories) as [key, value]}
        <SelectItem value={key} text={value} />
      {/each}
    </Select>
  </Column>
</Row>
<Row class="mb-10">
  <Column data-sectors md={4}>
    <Sectors {customer} on:reload />
  </Column>
</Row>

<script lang="ts">
  import type { AttrErrors, Customer, Meta } from '../../models'
  import { Row, Column, SelectItem } from 'carbon-components-svelte'
  import { loaded } from '../../helper'
  import { extractData } from '../../event'
  import categories from '../../data/customer_categories.json'
  import { createEventDispatcher } from 'svelte'
  import TextInput from '../../form/model/TextInput.svelte'
  import Select from '../../form/Select.svelte'
  import RepSelect from './RepSelect.svelte'
  import Sectors from './Sectors.svelte'

  export let customer: Customer
  export let errors: AttrErrors
  export let meta: Meta

  const dispatch = createEventDispatcher()

  const publish = event => {
    dispatch('change', { customer: { id: customer.id, ...extractData(event) } })
  }

  const publishRep = event => {
    dispatch('change', { customer: { id: customer.id, rep_id: extractData(event).id } })
  }
</script>
