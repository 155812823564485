<Address model={deliveryAddress} {errors} {loaded} on:change={change} />

<script lang="ts">
  import type { AttrErrors, DeliveryAddress } from '../../models'
  import Address from '../address/Form.svelte'
  import { createEventDispatcher } from 'svelte'
  import { extractData } from '../../event'

  export let deliveryAddress = {} as DeliveryAddress
  export let errors: AttrErrors = {}
  export let loaded

  const dispatch = createEventDispatcher()

  const change = event => {
    dispatch('change', { deliveryAddress: extractData(event) })
  }
</script>
