{#if $online}
  {#if showFilters}
    <Row class="screen-only">
      {#if hasRole($currentUser, UserRole.back_office)}
        <Column sm={4} md={4} lg={4} class="mb-05">
          <RepSelect selectedId={rep?.id} blankItemText="Alle" on:change={setRep} inline size="sm" />
        </Column>
      {/if}

      <Column sm={4} md={4} lg={4} class="mb-05 pt-03">
        <Toggle
          labelA="Geplante Termine ausblenden"
          labelB="Geplante Termine ausblenden"
          bind:toggled={pastOnly}
          name="pastOnly"
          on:change={togglePastOnly}
          disabled={date}
          size="sm"
          hideLabel
        />
      </Column>

      <Column sm={4} md={4} lg={4} class="mb-05">
        <DatePicker
          datePickerType="single"
          locale="de"
          dateFormat="d.m.Y"
          flatpickrProps={{ static: true }}
          bind:value={date}
        >
          <DatePickerInput placeholder="dd.mm.yyyy" size="sm" />
        </DatePicker>
      </Column>

      <Column sm={4} md={4} lg={4} class="mb-05 right-aligned">
        <Button size="sm" kind="ghost" on:click={setToday}>Heute</Button>
        <Button size="sm" kind="ghost" on:click={resetFilters}>Alle anzeigen</Button>
      </Column>
    </Row>
  {/if}

  <Row narrow>
    <Column>
      <DataTable headers={customer ? headers : globalHeaders} rows={engagements} class="flex-content">
        <Toolbar class="screen-only">
          <ToolbarContent>
            <ToolbarSearch
              placeholder="Notizen durchsuchen"
              on:input={debounce(changeQuery, 500)}
              on:clear={() => (query = '')}
              value={query}
              persistent
            />

            {#if showNew}
              <Button icon={Add} on:click={() => (formCustomerEngagement = getNew(customer))}
                >Kundenkontakt hinzufügen</Button
              >
            {/if}
          </ToolbarContent>
        </Toolbar>
        <div slot="cell" let:cell let:row class="row">
          {#if cell.key === 'date'}
            {shortDate(row.date)}
            {@const tag = dateTag(row.date)}
            {#if tag}
              <br />
              <Tag type={tag.type} size="sm" class="no-wrap" style="margin-left: 0">{tag.text}</Tag>
            {/if}
            {#if row.is_connected_customer}
              <div class="mt-04">
                <ShopBadge shop={row.customer.shop} label="KD {row.customer.id}" cssClasses="ml-0" />
              </div>
            {/if}
          {:else if cell.key === 'customer'}
            <Link href={link(customerUrl(row.customer))}>{row.customer.name} ({row.customer.id})</Link>
            <br />
            {row.customer.city}
          {:else if cell.key === 'note'}
            {formatValue(row.note, '')}
            {#if row.created_by}
              <span>[{row.created_by}]</span>
            {/if}
          {:else if cell.key === 'channel'}
            <Channel channel={row.channel} />
          {:else if cell.key === 'outcome'}
            <Outcome outcome={row.outcome} />
          {:else if cell.key === 'edit'}
            {#if !row.is_connected_customer}
              <Button
                kind="ghost"
                title="Bearbeiten"
                on:click={() => (formCustomerEngagement = row)}
                style="padding-top: 0"
              >
                <Edit />
              </Button>
            {/if}
          {:else}
            {row[cell.key]}
          {/if}
        </div>
      </DataTable>

      <PaginationNav bind:page {total} />
    </Column>
  </Row>

  <FormModal
    model={formCustomerEngagement}
    modelName="customerEngagement"
    humanModelName="Kundenkontakt"
    humanModelNamePronoun="der"
    open={formCustomerEngagement}
    selectorPrimaryFocus="[name=note]"
    form={Form}
    on:success={success}
    on:close={() => (formCustomerEngagement = undefined)}
  />
{:else}
  <NotAvailableOfflineNotification />
{/if}

<script lang="ts">
  import {
    DataTable,
    Tag,
    PaginationNav,
    Button,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
    Link,
    Row,
    Column,
    Toggle,
    DatePicker,
    DatePickerInput,
  } from 'carbon-components-svelte'
  import { Edit, Add } from 'carbon-icons-svelte'
  import FormModal from '../shared/FormModal.svelte'
  import Form from './Form.svelte'
  import RepSelect from '../customers/rep_and_sector/RepSelect.svelte'
  import { getCustomerEngagements } from '../persistence'
  import { shortDate } from '../date'
  import { getNew, dateTag } from './customer_engagements'
  import { extractData } from '../event'
  import { customerUrl, link } from '../urls'
  import { hasRole } from '../users/user'
  import { currentUser, online } from '../stores'
  import { UserRole } from '../models'
  import debounce from 'just-debounce'
  import { formatValue } from '../format'
  import Channel from '../customer_engagements/Channel.svelte'
  import Outcome from '../customer_engagements/Outcome.svelte'
  import NotAvailableOfflineNotification from '../shared/NotAvailableOfflineNotification.svelte'
  import ShopBadge from './../customers/ShopBadge.svelte'

  export let customer
  export let showNew = true
  export let showFilters = true

  const headers = [
    { key: 'date', value: 'Datum', width: '125px' },
    { key: 'note', value: 'Notiz' },
    { key: 'channel', value: 'Form', width: '50px' },
    { key: 'outcome', value: 'Ergebnis', width: '150px' },
    { key: 'edit', value: '', width: '80px' },
  ]

  const globalHeaders = [...headers.slice(0, 1), { key: 'customer', value: 'Kunde' }, ...headers.slice(1)]

  const pastOnlyStorageKey = `customer-engagements-past-only`

  let engagements = []
  let total
  let page = 0
  let formCustomerEngagement
  let pastOnly
  let rep
  let query = ''
  let date = ''

  const fetchCustomerEngagements = async () => {
    const result = await getCustomerEngagements(page + 1, pastOnly, rep, query, customer, date)

    if (!result.error) {
      engagements = result.customerEngagements
      total = result.pagination?.last || 0
    }
  }

  const success = async () => {
    formCustomerEngagement = undefined
    await fetchCustomerEngagements()
  }

  const setRep = event => {
    rep = extractData(event)
  }

  const setToday = () => {
    date = shortDate(new Date())
  }

  const changeQuery = event => {
    query = event.target.value
    page = 0
  }

  const persistPastOnly = value => {
    localStorage.setItem(pastOnlyStorageKey, value)
  }

  const togglePastOnly = event => {
    persistPastOnly(extractData(event).pastOnly)
  }

  const resetFilters = () => {
    page = 0
    rep = undefined
    query = ''
    date = ''
    pastOnly = false
    persistPastOnly(false)
  }

  const loadPastOnly = () => {
    const persistedValue = localStorage.getItem(pastOnlyStorageKey)
    if (persistedValue) {
      pastOnly = JSON.parse(persistedValue)
    }
  }

  // reset pastOnly when a date is set
  $: if (date) {
    pastOnly = false
    // restore the pastOnly setting
  } else if (date !== undefined) {
    loadPastOnly()
  }

  $: if (
    customer ||
    page !== undefined ||
    pastOnly !== undefined ||
    rep !== undefined ||
    query !== undefined ||
    date !== undefined
  ) {
    fetchCustomerEngagements()
  }
</script>

<style lang="scss">
  @media print {
    :global {
      .screen-only,
      th:last-child,
      td:last-child {
        display: none;
      }
    }
  }
</style>
