{#if typeof value === 'boolean'}
  {#if value}
    <Checkmark />
  {/if}
{:else}
  {value || ''}
{/if}

<script>
  import { Checkmark } from 'carbon-icons-svelte'
  export let value
</script>
