<Modal
  preventCloseOnClickOutside
  shouldSubmitOnEnter={false}
  {...$$restProps}
  on:keydown
  on:click
  on:mouseover
  on:mouseenter
  on:mouseleave
  on:transitionend
  on:click:button--secondary
  on:submit
  on:click:button--primary
  on:close
  on:open
>
  <slot />
</Modal>

<script lang="ts">
  import { Modal } from 'carbon-components-svelte'
</script>
