<Notification show={errors} on:click={() => (open = true)} />

{#if open}
  <Modal {customer} {errors} {permittedFields} {open} on:change on:close={() => (open = false)} />
{/if}

<script lang="ts">
  import type { AttrErrors, Customer } from '../../models'
  import Notification from './Notification.svelte'
  import Modal from './Modal.svelte'

  export let errors: AttrErrors
  export let permittedFields: string[]
  export let customer: Customer

  let open = false
</script>
