<Row>
  <Column class="mb-05">
    <h3>Meine Aufträge</h3>
  </Column>
</Row>

<Row narrow>
  <Column class="mb-03">
    <Orders />
  </Column>
</Row>

<script type="ts">
  import { Column, Row } from 'carbon-components-svelte'
  import Orders from '../orders/Index.svelte'
</script>
