{#if subtitle}
  <InlineNotification lowContrast hideCloseButton {title} {subtitle} {kind} on:close {...$$restProps} />
{/if}

<script lang="ts">
  import { InlineNotification } from 'carbon-components-svelte'

  export let info = ''
  export let success = ''
  export let warning = ''
  export let error = ''
  export let kind: 'error' | 'info' | 'success' | 'warning'

  let title: string
  let subtitle: string

  $: title = title || (error && 'Fehler')
  $: subtitle = info || success || warning || error
  $: kind = (info && 'info') || (success && 'success') || (warning && 'warning') || 'error'
</script>
