<Row narrow>
  <Column>
    <ClickableTile class="mb-10" on:click={() => (open = true)}>
      <Tag type={state.color} class="float-right">{state.name}</Tag>
      <p class="clearfix">{shortDate(flatFee.starts_on)}</p>
      <p>{flatFee.subscriber_name}</p>
      <p>{flatFee.subscriber_email}</p>
    </ClickableTile>
  </Column>
</Row>

{#if open}
  <Modal bind:open {customer} {flatFee} on:success={() => dispatch('reloadFlatFees')} />
{/if}

<script lang="ts">
  import { Tag, ClickableTile, Row, Column } from 'carbon-components-svelte'
  import type { TagProps } from 'carbon-components-svelte/types/Tag/Tag.svelte'
  import type { FlatFee } from '../models'
  import Modal from './Modal.svelte'
  import { createEventDispatcher } from 'svelte'
  import { shortDate } from '../date'

  export let customer
  export let flatFee: FlatFee

  let open: boolean
  const dispatch = createEventDispatcher()

  let state: { name: string; color: TagProps['type'] }
  const states = {
    subscribed: { name: 'geplant', color: 'cyan' },
    active: { name: 'aktiv', color: 'green' },
    cancelled: { name: 'cancelled', color: 'red' },
  }

  $: state = states[flatFee.status]
</script>
