<FormModal
  model={customer}
  modelName="customer"
  humanModelName="Rechnungsadresse"
  humanModelNamePronoun="die"
  createAction={false}
  updateAction={updateCustomer}
  deleteAction={false}
  form={Form}
  open={customer}
  data-billing-address-modal
  on:success
  on:close
/>

<script lang="ts">
  import type { Customer } from '../../models'
  import FormModal from '../../shared/FormModal.svelte'
  import { updateCustomer } from '../../persistence'
  import Form from './Form.svelte'

  export let customer: Customer
</script>
