<TextInput
  {name}
  {loaded}
  {value}
  invalid={errors[name] !== undefined}
  invalidText={errors[name]}
  aria-label={name}
  bind:ref
  on:change
  on:focus
  on:blur
  {labelText}
  {...$$restProps}
>
  <slot name="labelText" slot="labelText">
    {labelText}
  </slot>
</TextInput>

<script lang="ts">
  import type { AttrErrors } from '../../models'
  import { loaded as modelLoaded } from '../../helper'
  import TextInput from '../TextInput.svelte'

  export let model = {}
  export let errors: AttrErrors = {}
  export let name: string
  export let labelText: string
  export let loaded: boolean
  export let ref = undefined
  export let skipWriteOnFocus = false

  let value

  $: {
    loaded = loaded || modelLoaded(model)
  }

  $: {
    if (!skipWriteOnFocus || document.activeElement !== ref) {
      value = model[name]
    }
  }
</script>
