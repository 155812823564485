<div class="mb-10 button-right">
  <h4 class="mb-06">JLP</h4>
  {#if flatFees}
    {#if flatFees.length}
      <Show {customer} flatFee={flatFees[0]} on:reloadFlatFees />
    {:else}
      <p class="mb-05">Keine</p>
      <Button kind="tertiary" size="field" class="button-right--btn" on:click={() => (open = true)} icon={Add}>
        JLP abschliessen
      </Button>
    {/if}
  {:else}
    <SkeletonText paragraph />
  {/if}
</div>

{#if open}
  <Modal bind:open {customer} {flatFee} on:success={() => dispatch('reloadFlatFees')} />
{/if}

<script lang="ts">
  import type { FlatFee } from '../models'
  import { SkeletonText, Button } from 'carbon-components-svelte'
  import { Add } from 'carbon-icons-svelte'
  import Modal from './Modal.svelte'
  import Show from './FlatFee.svelte'
  import { createEventDispatcher } from 'svelte'

  export let customer
  export let flatFees: FlatFee[]

  let flatFee: FlatFee
  let open: boolean
  const dispatch = createEventDispatcher()

  $: flatFee = flatFees[0] || ({} as FlatFee)
</script>
