import { OrderState, type LineItem, type Order, type Customer } from '../models'
import { getOrderDryRun, getPurchasedProductsBillingItems } from '../persistence'
import { db } from '../db'
import { getNewOrder, getNewLineItem } from '../modelDefaults'
import { slog } from '../logging'

export async function adjust(order: Order): Promise<Order> {
  const updatedOrder = structuredClone(order)
  updatedOrder.vat = undefined
  updatedOrder.vat_rate = undefined
  updatedOrder.delivery_cost = undefined
  updatedOrder.sum = undefined
  updatedOrder.total = undefined
  updatedOrder.line_items = rejectEmptyLineItems(updatedOrder.line_items)

  const { order: dryRunOrder } = await getOrderDryRun(updatedOrder)

  // usually due to aborted request
  if (!dryRunOrder) return {}

  updatedOrder.vat = dryRunOrder.vat
  updatedOrder.vat_rate = dryRunOrder.vat_rate
  updatedOrder.delivery_cost = dryRunOrder.delivery_cost
  updatedOrder.sum = dryRunOrder.sum
  updatedOrder.total = dryRunOrder.total
  updatedOrder.dangerous_goods = dryRunOrder.dangerous_goods
  updatedOrder.available_delivery_options = dryRunOrder.available_delivery_options
  updatedOrder.voucher_code = dryRunOrder.voucher_code
  updatedOrder.joker_used = dryRunOrder.joker_used

  updatedOrder.line_items = order.line_items.map((lineItem: LineItem, i: number) => {
    const updatedLineItem = dryRunOrder.line_items[i]

    // keep line items (mostly empty lines) missing from the dry run
    if (!updatedLineItem) return lineItem

    let updatedOneOffPrice

    if (lineItem.one_off_price) {
      updatedOneOffPrice = {
        ...lineItem.one_off_price,
        price: updatedLineItem.price,
        discount: updatedLineItem.discount,
        volume_discount: updatedLineItem.volume_discount,
      }
    }

    return {
      ...lineItem,
      price: updatedLineItem.price,
      net_price: updatedLineItem.net_price,
      quantity: updatedLineItem.quantity,
      discount: updatedLineItem.discount,
      volume_discount: updatedLineItem.volume_discount,
      total: updatedLineItem.total,
      availability: updatedLineItem.availability,
      adjustment: updatedLineItem.adjustment,
      // TODO: remove once we allow custom products in the BE.
      product: updatedLineItem.product || lineItem.product,
      one_off_price: updatedOneOffPrice,
      alternative_for: updatedLineItem.alternative_for,
      successor: updatedLineItem.successor,
      customization: updatedLineItem.customization,
    }
  })

  return updatedOrder
}

export async function adjustNewLineItem(order, lineItem) {
  lineItem = { ...lineItem }

  const { billingItems } = await getPurchasedProductsBillingItems(order.customer_id, lineItem.product.sku, 1)
  if (billingItems?.length) {
    lineItem.latest_created_on = billingItems[0].invoice.created_on
  }

  return lineItem
}

export function attributeValue(lineItem, attribute) {
  const value = lineItem.one_off_price?.[attribute]

  return value === undefined ? lineItem[attribute] : value
}

export function isEmptyLineItem(lineItem) {
  return !(lineItem.product?.sku && lineItem.quantity)
}

export function rejectEmptyLineItems(lineItems) {
  return lineItems?.filter(lineItem => !isEmptyLineItem(lineItem))
}

export function isEditable(order) {
  return [OrderState.draft, OrderState.draftAdjusted].includes(order.state)
}

export function isDeletable(order) {
  return [OrderState.draft, OrderState.draftAdjusted, OrderState.pending].includes(order.state)
}

export async function getDraftOrder(customer: Customer): Promise<Order> {
  const orders = await db.orders
    .where({ state: OrderState.draft, customer_id: customer.id })
    .reverse()
    .sortBy('created_at')

  const order = orders[0] || (await getNewOrder(customer))
  order.line_items = rejectEmptyLineItems(order.line_items)
  return order
}

export async function appendToOrder(order, entriesWithQuantity) {
  const addedLineItems = []
  for (const { product, quantity } of entriesWithQuantity) {
    let lineItem = { ...getNewLineItem(), product, quantity }
    lineItem = await adjustNewLineItem(order, lineItem)

    addedLineItems.push(lineItem)
    order.line_items.push(lineItem)
  }
  order = await adjust(order)

  await db.orders.put(order, order.id)
  slog('order_line_item_add', { order_offline_id: order.id, line_items: addedLineItems })
}

export async function appendToDraftOrder(customer: Customer, entriesWithQuantity) {
  const order = await getDraftOrder(customer)
  return appendToOrder(order, entriesWithQuantity)
}
