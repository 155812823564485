<Grid noGutter>
  {#if deliveryModalOpen}
    <DeliveryAddressModal
      {deliveryAddress}
      open={deliveryModalOpen}
      on:success={() => dispatch('change')}
      on:close={() => (deliveryModalOpen = false)}
    />
  {/if}

  {#if billingModalOpen}
    <BillingAdressModal {customer} on:success={() => dispatch('change')} on:close={() => (billingModalOpen = false)} />
  {/if}

  <!--css note: reverse margin flow-->
  <div data-side-bar>
    {#if customer['applied?'] && customer.tax_number}
      <Row>
        <Column lg={8} xlg={16} class="mb-07">
          <h6>Details zum Neukundenantrag</h6>
          <UnorderedList expressive>
            <ListItem><strong>MwSt.</strong> {customer.tax_number}</ListItem>
            <ListItem><strong>Interessen</strong> {customer.interests}</ListItem>
            <ListItem><strong>Tätigkeit</strong> {customer.sector}</ListItem>
            <ListItem><strong>Bemerkungen/Anfragen</strong> {customer.comment || '–'}</ListItem>
          </UnorderedList>
        </Column>
      </Row>
    {/if}
    <Row>
      <Column lg={8} xlg={16}>
        <Row>
          <Column class="mb-06" md={4}>
            <h6>Rechnungsadresse</h6>
            <List
              loaded={loaded(customer)}
              {editable}
              on:openModal={() => (billingModalOpen = true)}
              data-side-billing-address
              id="abc"
            >
              <p>{customer.name}</p>
              <p>{customer.industry || ''}</p>
              <p>{customer.street || ''}</p>
              <p>{customer.postbox || ''}</p>
              <p>{`${customer.zip_code} ${customer.city}`}</p>
              <p>{countryNameForDisplay(customer.country) || ''}</p>
            </List>
          </Column>
          <Column class="mb-06" md={4}>
            <h6>Lieferadresse</h6>
            <List
              loaded={loaded(deliveryAddress)}
              {editable}
              on:openModal={() => (deliveryModalOpen = true)}
              data-side-delivery-address
            >
              <p>{deliveryAddress.name}</p>
              <p>{deliveryAddress.industry || ''}</p>
              <p>{deliveryAddress.street || ''}</p>
              <p>{deliveryAddress.postbox || ''}</p>
              <p>{`${deliveryAddress.zip_code} ${deliveryAddress.city}`}</p>
              <p>{countryNameForDisplay(deliveryAddress.country) || ''}</p>
            </List>
            {#if choosableDeliveryAddress}
              <Link
                href="#"
                class="pointer"
                on:click={event => {
                  event.preventDefault()
                  dispatch('chooseDeliveryModal')
                }}>Auswählen</Link
              >
            {/if}
          </Column>
        </Row>
        {#if !showAdressesOnly}
          <Row>
            <Column class="mb-06">
              <StructuredList condensed flush style="margin-bottom: 0;">
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>DJ</StructuredListCell>
                    <StructuredListCell head>LJ</StructuredListCell>
                    <StructuredListCell head>VLJ</StructuredListCell>
                    <StructuredListCell head>VVLJ</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell noWrap>{formatValue(customer.statistics?.year0_revenue)}</StructuredListCell>
                    <StructuredListCell noWrap>{formatValue(customer.statistics?.year1_revenue)}</StructuredListCell>
                    <StructuredListCell noWrap>{formatValue(customer.statistics?.year2_revenue)}</StructuredListCell>
                    <StructuredListCell noWrap>{formatValue(customer.statistics?.year3_revenue)}</StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredList>
            </Column>
          </Row>
          <Row>
            <Column class="mb-06" md={4}>
              <h6>Brutto-Rabatt</h6>
              <p>{customer.discount || 0}%</p>
            </Column>
            <Column class="mb-06" md={4}>
              <h6>Offener Betrag</h6>
              <OutstandingBalance balance={customer.outstanding_balance} />
            </Column>
          </Row>
          <Row>
            <Column class="mb-06" md={4}>
              <h6>Joker</h6>
              <p>
                {#if customer.joker_used}
                  eingelöst
                {:else}
                  nicht eingelöst
                {/if}
              </p>
            </Column>
            <Column class="mb-06" md={4}>
              <h6>JLP</h6>
              {#if flatFee}
                <p>Aktiv</p>
              {:else}
                <p>-</p>
              {/if}
            </Column>
          </Row>
        {/if}
        <slot name="two-column-content" />
      </Column>
      {#if !showAdressesOnly}
        <Column lg={8} xlg={16}>
          {#if meta?.permitted_attributes?.includes('rep_contact')}
            <Note noteObject={notes.rep_contact} {editable} on:change />
          {/if}
          {#if meta?.permitted_attributes?.includes('rep_note')}
            <Note noteObject={notes.rep_note} {editable} on:change />
          {/if}
          <Note noteObject={notes.note} {editable} on:change />
          <Note noteObject={notes.special_note} {editable} on:change />
          <Note noteObject={notes.important_note} {editable} on:change />
          {#if meta?.permitted_attributes?.includes('logistics_note')}
            <Note noteObject={notes.logistics_note} {editable} on:change />
          {/if}
        </Column>
      {/if}
      <slot name="one-column-content" />
    </Row>
  </div>
</Grid>

<script lang="ts">
  import type { Customer, DeliveryAddress, FlatFee } from '../../models'
  import {
    Grid,
    Row,
    Column,
    UnorderedList,
    ListItem,
    Link,
    StructuredList,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
  } from 'carbon-components-svelte'
  import { loaded } from '../../helper'
  import List from './List.svelte'
  import Note from './Note.svelte'
  import DeliveryAddressModal from '../delivery_addresses/Modal.svelte'
  import BillingAdressModal from '../billing_address/Modal.svelte'
  import OutstandingBalance from '../OutstandingBalance.svelte'
  import { createEventDispatcher } from 'svelte'
  import { countryNameForDisplay } from '../../country'
  import { formatValue } from '../../format'

  export let customer: Customer
  export let meta
  export let deliveryAddress: DeliveryAddress
  export let flatFee: FlatFee = undefined
  export let editable = true
  export let choosableDeliveryAddress = false
  export let showAdressesOnly = false

  const dispatch = createEventDispatcher()

  let deliveryModalOpen: boolean
  let billingModalOpen: boolean

  $: notes = {
    note: {
      headline: 'Bemerkung',
      note: customer.note,
      key: 'note',
    },
    special_note: {
      headline: 'Spezielles',
      note: customer.special_note,
      key: 'special_note',
    },
    important_note: {
      headline: 'Wichtig',
      note: customer.important_note,
      key: 'important_note',
    },
    rep_contact: {
      headline: 'Kontaktperson AD',
      note: customer.rep_contact,
      key: 'rep_contact',
    },
    rep_note: {
      headline: 'Bemerkung AD',
      note: customer.rep_note,
      key: 'rep_note',
    },
    logistics_note: {
      headline: 'Lagerbemerkung',
      note: customer.logistics_note,
      key: 'logistics_note',
    },
  }
</script>
