// A model is considered loaded when it has more than one attributes.
// We use type casting, e.g. { id: customerId } as Customer when we have
// a model that has not been loaded yet.
import type { AttrErrors } from './models'

export const BLANK = [null, undefined, '']

export function loaded<T>(model: T): boolean {
  return Object.keys(model).length > 1
}

export function fetchSuccessful(error: string, errors: AttrErrors = {}): boolean {
  return !error && Object.keys(errors).length == 0
}

export function truthy(value: string | boolean | number): boolean {
  return [1, true, '1', 'true', 'yes'].includes(value)
}

export function updateQuerystring(params = {}, querystring = '') {
  const searchParams = new URLSearchParams(querystring)
  for (const [param, value] of Object.entries(params)) {
    if (typeof value === 'boolean' || typeof value === 'number' || (typeof value === 'string' && value.length > 0)) {
      searchParams.set(param, `${value}`)
    } else {
      searchParams.delete(param)
    }
  }
  return searchParams.toString()
}

export function noWhiteSpace(value: string): string {
  return (value || '').replace(/\s/g, '')
}
