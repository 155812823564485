<Address model={customer} {errors} {loaded} on:change={change} />

<script lang="ts">
  import type { AttrErrors, Customer } from '../../models'
  import Address from '../address/Form.svelte'
  import { createEventDispatcher } from 'svelte'
  import { extractData } from '../../event'

  export let customer: Customer
  export let errors: AttrErrors = {}
  export let loaded

  const dispatch = createEventDispatcher()

  const change = event => {
    dispatch('change', { customer: extractData(event) })
  }
</script>
