<h4 class="mb-07">Zahlungsbedingungen</h4>
<Row>
  <Column class="mb-07" md={4}>
    <Discount {customer} {errors} on:change={publish} />
  </Column>
  <Column class="mb-07" md={4}>
    <PaymentBehavior {customer} {errors} on:input={publish} />
  </Column>
  <Column class="mb-07" md={4}>
    <PaymentConditions {customer} {errors} on:input={publish} />
  </Column>
  <Column class="mb-05" md={4}>
    <!-- Less margin due to helper text -->
    <SolvencyCheck {customer} {errors} on:input={publish} />
  </Column>
</Row>
<Row>
  <Column class="mb-07" md={4}>
    <Currency {customer} {errors} on:input={publish} />
  </Column>
  <Column class="mb-07" md={4}>
    <TextInput
      model={customer}
      name="exchange_rate"
      labelText="Fixkurs für Fremdwährung"
      {errors}
      disabled={customer.currency === 'chf'}
      on:change={publish}
    />
  </Column>
</Row>
<Row class="mb-10">
  <Column md={4}>
    <Toggle
      loaded={loaded(customer)}
      labelText="Monatsrechung"
      labelA="Keine Monatsrechnung"
      labelB="Monatsrechnung"
      name="monthly_invoice"
      toggled={customer.monthly_invoice}
      on:change={publish}
    />
  </Column>
  <Column md={4}>
    <Toggle
      loaded={loaded(customer)}
      labelText="Verlustrisiko"
      labelA="Verlustrisiko prüfen"
      labelB="Verlustrisiko ignorieren"
      name="skip_risk_assessment"
      toggled={customer.skip_risk_assessment}
      on:change={publish}
    />
  </Column>
</Row>

<script lang="ts">
  import type { Customer, AttrErrors } from '../models'
  import { Row, Column } from 'carbon-components-svelte'
  import { loaded } from '../helper'
  import { extractData } from '../event'
  import Toggle from '../form/Toggle.svelte'
  import TextInput from '../form/model/TextInput.svelte'
  import Discount from './payment/Discount.svelte'
  import PaymentBehavior from './payment/PaymentBehavior.svelte'
  import PaymentConditions from './payment/PaymentConditions.svelte'
  import Currency from './payment/Currency.svelte'
  import SolvencyCheck from './payment/SolvencyCheck.svelte'
  import { createEventDispatcher } from 'svelte'

  export let customer: Customer
  export let errors: AttrErrors

  const dispatch = createEventDispatcher()

  const publish = event => {
    dispatch('change', { customer: { id: customer.id, ...extractData(event) } })
  }
</script>
