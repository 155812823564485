<script lang="ts">
  import { onMount } from 'svelte'
  import { getDeliveryOptions } from '../../persistence'
  import { db } from '../../db'
  import { syncCount } from '../../stores'

  const fetchDeliveryOptions = async () => {
    const { deliveryOptions = [], error } = await getDeliveryOptions({ internal: true }, true)

    if (!error) {
      await db.deliveryOptions.bulkPut(deliveryOptions)
    }
  }

  onMount(async () => {
    try {
      syncCount.update(count => count + 1)
      await fetchDeliveryOptions()
    } finally {
      syncCount.update(count => count - 1)
    }
  })
</script>
