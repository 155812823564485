<!-- svelte-ignore a11y-click-events-have-key-events -->
<div on:click={event => event.stopPropagation()}>
  <Availability row={product} />
  <Tooltip style="float: right">
    <p>Lagerstand: {product.stock}</p>
  </Tooltip>
</div>

<script lang="ts">
  import { Tooltip } from 'carbon-components-svelte'
  import Availability from '../orders/line_items/table/Availability.svelte'
  import type { Product } from '../models'

  export let product: Product
</script>
