<Modal
  open
  modalHeading="Preis anpassen"
  primaryButtonText="Speichern"
  secondaryButtonText="Abbrechen"
  on:close={cancel}
  on:click:button--secondary={cancel}
  on:submit={submit}
>
  <Column>
    <Row>
      {#if lineItem.one_off_price}
        {#each inputFields as inputField}
          <Column class="mb-07" md={2}>
            <TextInput
              name={inputField.name}
              labelText={inputField.label}
              bind:value={lineItem.one_off_price[inputField.name]}
              on:change={change}
              data-modal-primary-focus
              loaded
            />
            <div class="bx--label">{formatValue(lineItem.one_off_price[`original_${inputField.name}`])}</div>
          </Column>
        {/each}

        <Column class="mb-07" md={2}>
          <div class="bx--label">Netto</div>
          <div class="net-price">{lineItem.net_price}</div>
        </Column>
      {/if}
    </Row>
  </Column>
</Modal>

<script lang="ts">
  import Modal from '../shared/Modal.svelte'
  import TextInput from '../form/TextInput.svelte'
  import { Column, Row } from 'carbon-components-svelte'
  import { createEventDispatcher } from 'svelte'
  import { adjust } from './order'
  import { formatValue } from '../format'
  import type { LineItem, Order } from '../models'

  export let order: Order
  export let lineItem: LineItem

  const inputFields = [
    { name: 'price', label: 'Preis' },
    { name: 'discount', label: 'GR' },
    { name: 'volume_discount', label: 'MR' },
  ]

  const dispatch = createEventDispatcher()

  const change = async () => {
    const orderForAdjust = structuredClone(order)
    // we just need the direct order attributes
    delete orderForAdjust.line_items
    const adjustedOrder = await adjust({ line_items: [lineItem], ...orderForAdjust })
    assignOneOffPrice(adjustedOrder.line_items[0])
    lineItem.net_price = adjustedOrder.line_items[0].net_price
  }

  const submit = () => dispatch('change', lineItem)
  const cancel = () => dispatch('cancel')

  const assignOneOffPrice = from => {
    lineItem.one_off_price = lineItem.one_off_price || {}
    lineItem.one_off_price.price = from.price
    lineItem.one_off_price.discount = from.discount
    lineItem.one_off_price.volume_discount = from.volume_discount
  }

  if (lineItem) {
    lineItem = structuredClone(lineItem)

    if (!lineItem.one_off_price) {
      assignOneOffPrice(lineItem)
      lineItem.one_off_price.original_price = lineItem.price
      lineItem.one_off_price.original_discount = lineItem.discount
      lineItem.one_off_price.original_volume_discount = lineItem.volume_discount
    }
  }
</script>

<style lang="scss">
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/generated/_spacing';

  .net-price {
    display: flex;
    align-items: center;
    height: $spacing-08;
  }
</style>
