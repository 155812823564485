<Select on:input={change} {...$$restProps}>
  {#each countries as [code, country]}
    <SelectItem value={code} text={country} />
  {/each}
</Select>

<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { extractData } from '../event'
  import { SelectItem } from 'carbon-components-svelte'
  import Select from './Select.svelte'
  import countries from '../data/countries.json'

  const dispatch = createEventDispatcher()
  const change = event => {
    dispatch('change', extractData(event))
  }
</script>
