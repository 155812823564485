<!-- See example at https://vite-plugin-pwa.netlify.app/frameworks/svelte.html#prompt-for-update */ -->

{#if showPrompt}
  <ToastNotification kind="warning" title="Neue Version verfügbar">
    <div slot="subtitle">
      Eine neue Version wurde heruntergeladen und steht dir jetzt zur Verfügung. Aktualisiere bitte zeitnah, um mit der
      neusten Version zu arbeiten.
    </div>
    <div slot="caption">
      <Button kind="primary" size="small" on:click={update}>Aktualiseren</Button>
    </div>
  </ToastNotification>
{/if}

<script lang="ts">
  import { useRegisterSW } from 'virtual:pwa-register/svelte'
  import { Button, ToastNotification } from 'carbon-components-svelte'

  $: showPrompt = $needRefresh

  const interval = 10 * 60 * 1000

  let swRegistration

  const { needRefresh, updateServiceWorker } = useRegisterSW({
    onRegistered(registration) {
      if (registration) {
        swRegistration = registration
        setInterval(() => {
          registration.update()
        }, interval)
      }
    },
    onRegisterError() {
      /* registration error */
    },
  })

  const update = () => {
    if (swRegistration && swRegistration.waiting) {
      updateServiceWorker(true)
    } else {
      window.location.reload()
    }
  }
</script>
