import { get } from 'svelte/store'
import { db } from './db'
import { localizedAttribute } from './i18n'
import type { Customer, DeliveryOption, FlatFee, JsonAPI, Locale, Order, Product, Rep, User } from './models'
import { Shop, ShopAssignment } from './models'
import { paginatePer } from './pagination'
import { customerId } from './stores'

export async function getCustomers(page: number, searchQuery = ''): Promise<{ json?: JsonAPI<Customer[]> }> {
  const statusOrderScore = {
    applied: 2,
    active: 1,
    inactive: 0,
  }

  const per = paginatePer()
  const offset = per * (page - 1)
  let customers = await db.customers.toArray()

  if (searchQuery) {
    customers = customers.filter(customer => JSON.stringify(customer).toUpperCase().includes(searchQuery.toUpperCase()))
  }

  customers = customers
    .sort((a, b) => {
      if (a.status === b.status) return a.id - b.id
      return statusOrderScore[b.status] - statusOrderScore[a.status]
    })
    .slice(offset, offset + per)

  return {
    json: {
      data: customers,
    },
  }
}

export async function getCustomer(): Promise<{ json?: JsonAPI<Customer> }> {
  const customer = await db.customers.get(get(customerId))
  return {
    json: {
      data: customer,
    },
  }
}

export async function getFlatFees(): Promise<{ json?: JsonAPI<FlatFee[]> }> {
  return {
    json: {
      data: [],
    },
  }
}

export async function getReps(): Promise<{ json?: JsonAPI<Rep[]> }> {
  return {
    json: {
      data: [],
    },
  }
}

export async function getUser(): Promise<{ json?: JsonAPI<User>; error?: string }> {
  return {
    json: {
      data: undefined,
    },
  }
}

export async function getDeliveryOptions(params: {
  flat_fee?: boolean
  internal?: boolean
}): Promise<{ json?: JsonAPI<DeliveryOption[]>; error?: string }> {
  let deliveryOptions = await db.deliveryOptions.toArray()
  if (!params?.internal) {
    deliveryOptions = deliveryOptions.filter(deliveryOption => deliveryOption.state !== 'internal')
  }

  return {
    json: {
      data: deliveryOptions,
    },
  }
}

export async function getOrderDryRun(order: Order): Promise<{ json?: JsonAPI<Order>; error?: string }> {
  const deliveryOptions = await getDeliveryOptions({ internal: true })
  return {
    json: {
      data: {
        ...order,
        available_delivery_options: deliveryOptions.json.data,
      },
    },
  }
}

export async function getProduct(id: string): Promise<{ json?: JsonAPI<Product>; error?: string }> {
  const product = await db.products.get(id)

  return {
    json: {
      data: product,
    },
  }
}

export async function getProducts(
  page: number,
  searchTerm?: string,
  params?: { updated_since?: Date | string; locale?: Locale; novelty?: boolean; promotion?: boolean; shop?: Shop }
): Promise<{ json?: JsonAPI<Product[]>; error?: string }> {
  const shopAssignment = ShopAssignment[params.shop]
  let products = await db.products.where('shop_assignment').anyOf(shopAssignment).toArray()

  if (searchTerm) {
    const upperCasedSearchTerm = searchTerm.toUpperCase()
    products = products.filter(product =>
      [
        product.sku,
        localizedAttribute(product, 'name', params.locale),
        localizedAttribute(product, 'summary', params.locale),
      ].some(property => property?.toUpperCase().includes(upperCasedSearchTerm))
    )
  }

  if (params?.novelty) {
    products = products.filter(product => product.novelty)
  }

  if (params?.promotion) {
    products = products.filter(product => product.promoted).sort((a, b) => a.promotion_position - b.promotion_position)
  }

  const per = params?.per || paginatePer()
  products = products.slice(0, per)

  return {
    json: {
      data: products,
    },
  }
}

export function getPurchasedProductsBillingItems(): Promise<{ json?: JsonAPI<BillingItem[]>; error?: string }> {
  return {
    json: {
      data: [],
    },
  }
}

export async function getCategories(_page, params): Promise<{ json?: JsonAPI<Category[]>; error?: string }> {
  const categories = await db.categories
    .where({ shop: params.shop })
    .filter(category => category.is_root)
    .sortBy('seq')

  return {
    json: {
      data: categories,
    },
  }
}

export async function getCategory(id: string): Promise<{ json?: JsonAPI<Category>; error?: string }> {
  const category = await db.categories.get(id)

  const categorizations = await db.categorizations.where({ category_id: category.uid }).toArray()
  const products = await db.products
    .where('sku')
    .anyOf(categorizations.map(categorization => categorization.product_id))
    .toArray()
  category.products = products

  return {
    json: {
      data: category,
    },
  }
}

export function getCustomerEngagements(): Promise<{ json?: JsonAPI<CustomerEngagement[]>; error?: string }> {
  return {
    json: {
      data: [],
    },
  }
}

export default {
  getCustomers,
  getCustomer,
  getFlatFees,
  getProduct,
  getProducts,
  getPurchasedProductsBillingItems,
  getReps,
  getUser,
  getDeliveryOptions,
  getOrderDryRun,
  getCategories,
  getCategory,
  getCustomerEngagements,
}
