import Dexie, { type Table } from 'dexie'
import { v4 as uuid } from 'uuid'
import type { Category, Customer, DeliveryOption, Order, Product } from './models'

export class Db extends Dexie {
  orders!: Table<Order>
  products!: Table<Product>
  customers!: Table<Customer>
  deliveryOptions!: Table<DeliveryOption>
  categories!: Table<Category>
  categorizations!: Table<Categorization>

  constructor() {
    super('hoelzle-crm')

    this.version(1).stores({ orders: '++id, customer_id' })
    this.version(2).stores({
      orders: '++id, customer_id',
      products: '++id, &sku',
    })
    this.version(3).stores({
      orders: '++id, customer_id, state',
      products: '++id, &sku',
    })
    this.version(4).stores({
      orders: '++id, customer_id, state',
      products: '++id, &sku',
      customers: 'id, updated_at',
    })
    this.version(5).stores({
      orders: '++id, customer_id, state',
      products: null,
      customers: 'id, updated_at',
    })
    this.version(6).stores({
      orders: '++id, customer_id, state',
      products: 'sku',
      customers: 'id, updated_at',
    })
    this.version(7).stores({
      orders: null,
      products: 'sku',
      customers: 'id, updated_at',
    })
    this.version(8).stores({
      orders: '&id, customer_id, state',
      products: 'sku',
      customers: 'id, updated_at',
    })
    this.version(9)
      .stores({
        orders: '&id, customer_id, state',
        products: 'sku',
        customers: 'id, updated_at',
      })
      .upgrade(transaction => {
        return transaction
          .table('orders')
          .toCollection()
          .modify(order => {
            order.line_items = order.line_items.map(lineItem => {
              return {
                ...lineItem,
                id: uuid(),
              }
            })
          })
      })
    this.version(10).stores({
      orders: '&id, customer_id, state',
      products: 'sku, updated_at',
      customers: 'id, updated_at',
    })
    this.version(11).upgrade(transaction => transaction.table('products').clear())
    this.version(12).upgrade(transaction => transaction.table('customers').clear())
    this.version(13).upgrade(transaction => transaction.table('products').clear())
    this.version(14).stores({
      deliveryOptions: '++id',
    })
    this.version(15).upgrade(transaction => transaction.table('products').clear())
    this.version(16).upgrade(transaction => transaction.table('products').clear())
    this.version(17).stores({
      categories: '&id, is_root, has_children, has_products, updated_at',
    })
    this.version(18).stores({
      categorizations: '[category_id+product_id], category_id, product_id',
    })
    this.version(19)
      .stores({
        categories: null,
      })
      .upgrade(transaction => transaction.table('categories').clear())
    this.version(20).stores({
      categories: '&uid, updated_at',
    })
    this.version(21).upgrade(transaction => transaction.table('products').clear())
    this.version(22).upgrade(transaction => transaction.table('categories').clear())
    this.version(23).upgrade(transaction => transaction.table('customers').clear())
    this.version(24)
      .stores({
        categories: '&uid, shop, updated_at',
      })
      .upgrade(transaction => transaction.table('categories').clear())
      .upgrade(transaction => transaction.table('categorizations').clear())
    this.version(25)
      .stores({
        products: 'sku, updated_at, shop_assignment',
      })
      .upgrade(transaction => transaction.table('products').clear())
    this.version(26).upgrade(transaction => transaction.table('products').clear())
    this.version(27).upgrade(transaction => {
      return transaction
        .table('orders')
        .toCollection()
        .modify(order => {
          order.kind = 'order'
        })
    })
  }
}

export const db = new Db()
