import { UserRole } from '../models'

const displayRoles = {
  [UserRole.it]: 'IT',
  [UserRole.back_office]: 'ID',
  [UserRole.rep]: 'AD',
}

export const getDisplayRoles = user => user.extended_roles.map(role => displayRoles[role])

export const hasRole = (user, role) => hasAnyRole(user, [role])

export const hasAnyRole = (user, roles) => [...roles, UserRole.it].some(role => user?.extended_roles?.includes(role))

export function usersWithEmail(users) {
  return users?.filter(user => user.email)
}

export function activeUsers(users) {
  return users?.filter(user => !user.deactivated)
}
