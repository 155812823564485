{#if outcomes[outcome]}
  <Tag type={outcomes[outcome].color} size="sm" class="no-wrap mt-0">
    {outcomes[outcome].label}
  </Tag>
{/if}

<script lang="ts">
  import { Tag } from 'carbon-components-svelte'
  import outcomeLabels from '../data/customer_engagements_outcomes.json'

  export let outcome

  const outcomes = {
    order: {
      color: 'green',
      label: outcomeLabels.order,
    },
    offer: {
      color: 'purple',
      label: outcomeLabels.offer,
    },
    return: {
      color: 'magenta',
      label: outcomeLabels.return,
    },
    no_contact: {
      color: 'gray',
      label: outcomeLabels.no_contact,
    },
    no_needs: {
      color: 'gray',
      label: outcomeLabels.no_needs,
    },
  }
</script>
