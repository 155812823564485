<div class="mb-05 condensed">
  {#if customer}
    <DataTable
      sortable
      bind:sortKey
      bind:sortDirection
      {headers}
      rows={purchasedProducts || []}
      expandable
      batchExpansion
      bind:expandedRowIds
      on:click:row--expand={expandRow}
      on:click:header={clickHeader}
      on:click:header--expand={expandRows}
    >
      <Toolbar>
        <ToolbarContent>
          <ToolbarSearch
            value={query}
            placeholder="Produkt suchen"
            on:input={debounce(changeQuery, 200)}
            on:clear={() => (query = '')}
            persistent
          />
        </ToolbarContent>
      </Toolbar>
      <div slot="cell" let:cell let:row class="row">
        {#if loading}
          <SkeletonText />
        {:else if cell.key === 'image'}
          {#if row.product}
            <div class="sub-row__compact">
              <Image product={row.product} verticallyCentered />
            </div>
          {/if}
        {:else if cell.key === 'product'}
          {#if row.product}
            <div class="sub-row__compact">
              <strong>{row.product.sku}</strong>
              <ProductStatusTag product={row.product} />
            </div>
            {localizedAttribute(row.product, 'name', localeFromlanguage(customer.language))}
          {:else}
            <div class="sub-row__compact">
              <strong>{row.product_id}</strong>
            </div>
          {/if}
        {:else if cell.key === 'availability'}
          {#if row.product}
            <AvailabilityAndStock product={row.product} />
          {/if}
        {:else if cell.key === 'created_on'}
          <div class="sub-row__compact">
            {shortDate(row.latest_created_on)}
          </div>
        {:else if row.product}
          <ShoppingCartRow {cell} product={row.product} {customer} bind:dirtyPurchasedProducts bind:success />
        {/if}
      </div>
      <svelte:fragment slot="expanded-row" let:row>
        <BillingItems billingItems={billingItems[row.product_id]} />
      </svelte:fragment>
    </DataTable>
  {/if}

  <PaginationNav bind:page {total} />
</div>

<script lang="ts">
  import debounce from 'just-debounce'
  import {
    DataTable,
    SkeletonText,
    PaginationNav,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
  } from 'carbon-components-svelte'
  import { push, location, querystring } from 'svelte-spa-router'
  import BillingItems from './BillingItems.svelte'
  import Image from '../../orders/products/Image.svelte'
  import ShoppingCartRow from '../../products/ShoppingCartRow.svelte'
  import AvailabilityAndStock from '../../products/AvailabilityAndStock.svelte'
  import { shortDate } from '../../date'
  import { getPurchasedProducts, getPurchasedProductsBillingItems } from '../../persistence'
  import { localeFromlanguage, localizedAttribute } from '../../i18n'
  import { onMount } from 'svelte'
  import ProductStatusTag from '../../shared/ProductStatusTag.svelte'

  export let customer

  const headers = [
    { key: 'image', value: '', width: '15%', sort: false },
    { key: 'product', value: 'Artikel', width: '90%' },
    { key: 'availability', value: '', width: '70px', sort: false },
    { key: 'created_on', value: 'Datum', width: '125px' },
    { key: 'order', value: 'Bestellen', width: '100px', sort: false },
    { key: 'cart', value: '', width: '66px', sort: false },
  ]

  const queryDefault = ''

  let purchasedProducts = [{}]
  let billingItems = {}
  let total
  let page = 0
  let query
  let sortKey = 'created_on'
  let sortDirection = 'descending'
  let expandedRowIds
  let dirtyPurchasedProducts = {}
  let success = {}
  let loading = false

  const loadPurchasedProducts = async () => {
    loading = true
    const result = await getPurchasedProducts(customer, page + 1, query, `${sortKey}_${sortDirection}`)
    if (!result.error && result.purchasedProducts) {
      purchasedProducts = result.purchasedProducts.map((purchasedProduct, index) => ({
        ...purchasedProduct,
        id: index,
      }))
      total = result.pagination.last
      if (urlParams.get('expandAll')) expandAllRows()
      loading = false
    }
  }

  const expandAllRows = async () => {
    await expandRows()
    expandedRowIds = purchasedProducts.map((purchasedProduct, index) => index)
  }

  // we don't want to allow 'none' as sort direction, we always want explict
  // 'ascending' or 'descending'
  const clickHeader = async event => {
    if (sortDirection !== 'none') return

    sortKey = event.detail.header.key
    sortDirection = 'ascending'
  }

  const fetchBillingItems = async productId => {
    const result = await getPurchasedProductsBillingItems(customer, productId)

    if (!result.error) {
      billingItems[productId] = result.billingItems
    }
  }
  const expandRow = async event => {
    await fetchBillingItems(event.detail.row.product_id)
  }

  const expandRows = async () => {
    for (const { product } of purchasedProducts) {
      await fetchBillingItems(product.sku)
    }
  }

  const changeQuery = event => {
    expandedRowIds = []
    urlParams.delete('expandAll')
    query = event.target.value
  }

  const updateUrl = () => {
    if (query === queryDefault) {
      urlParams.delete('query')
    } else {
      urlParams.set('query', query)
    }
    if (urlParams.toString()) {
      push(`${$location}?${urlParams.toString()}`)
    } else {
      push($location)
    }
  }

  $: if (
    customer &&
    page !== undefined &&
    query !== undefined &&
    sortKey !== undefined &&
    sortDirection !== undefined
  ) {
    purchasedProducts = [{}]
    loadPurchasedProducts()
    updateUrl()
  }

  // always reset page when searching
  $: page = (query || true) && 0

  // collapse all expanded rows on pagination
  $: if (page !== undefined) {
    expandedRowIds = []
  }

  $: urlParams = new URLSearchParams($querystring)

  onMount(() => {
    query = urlParams?.get('query') || queryDefault
  })
</script>
