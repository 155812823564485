import type { AttrErrors, Error, Order } from './models'

export function attributeErrors(jsonAPIErrors: Error[]): AttrErrors {
  if (!jsonAPIErrors?.every(error => error.source?.pointer)) {
    return {}
  }

  const errors = {}

  jsonAPIErrors.forEach(error => {
    const value = error.detail
    const [key, keyLevel2] = error.source.pointer.split('.')
    let existingValue = errors[key]

    if (keyLevel2) {
      if (typeof existingValue === 'string' || Array.isArray(existingValue)) {
        existingValue = { base: existingValue }
      }
      errors[key] = { ...(existingValue || {}), ...{ [keyLevel2]: value } }
    } else {
      if (existingValue) {
        errors[key] = [existingValue, value].flat()
      } else {
        errors[key] = value
      }
    }
  })

  return errors
}

export function requestModel(model, hasManyAttributes: string | string[]) {
  const attributes = [].concat(hasManyAttributes).reverse()

  const requestModel = structuredClone(model)

  attributes.forEach(attribute => {
    if (attribute.includes('.')) {
      const [parentAttribute, nestedAttribute] = attribute.split('.')
      requestModel[parentAttribute].forEach(nestedModel => {
        nestedModel[`${nestedAttribute}_attributes`] = nestedModel[nestedAttribute]
        delete nestedModel[nestedAttribute]
      })
    } else {
      requestModel[`${attribute}_attributes`] = requestModel[attribute]
      delete requestModel[attribute]
    }
  })

  return requestModel
}

export function requestOrderModel(order: Order) {
  order = structuredClone(order)
  order.line_items = order.line_items.map(lineItem => {
    lineItem.product_id = lineItem.product.sku
    delete lineItem.product
    return lineItem
  })
  return requestModel(order, ['line_items', 'line_items.one_off_price'])
}
