<Select
  loaded={loaded(customer)}
  labelText="Export"
  name="export"
  selected={customer.export}
  on:input
  invalid={errors.export !== undefined}
  invalidText={errors.export}
>
  <SelectItem />
  {#each Object.entries(exports) as [key, value]}
    <SelectItem value={key} text={value} />
  {/each}
</Select>

<script lang="ts">
  import type { Customer, AttrErrors } from '../../models'
  import Select from '../../form/Select.svelte'
  import { SelectItem } from 'carbon-components-svelte'
  import { loaded } from '../../helper'
  import exports from '../../data/customer_exports.json'

  export let customer: Customer
  export let errors: AttrErrors
</script>
