<Row>
  <Column>
    <Notification {error} />
    <h3 class="mb-08">Kunde hinzufügen</h3>
  </Column>
</Row>
<Row>
  <Column md={3}>
    <div class="mb-10">
      <Select name="shop" on:input={changeShop} labelText="Kundensegment" loaded>
        {#each Object.entries(shops) as [value, text]}
          <SelectItem {value} {text} />
        {/each}
      </Select>
    </div>
  </Column>
</Row>
<Row>
  <Column lg={12} xlg={10}>
    <div class="mb-10" data-billing-address>
      <h4 class="mb-07">Rechnungsadresse</h4>
      <BillingAddressForm {customer} {errors} loaded on:change={update} />
    </div>
    <div class="mb-10" data-delivery-address>
      <h4 class="mb-07">Lieferadresse</h4>
      <Toggle
        loaded
        labelA="Abweichend von der Rechnungsadresse"
        labelB="Gleich wie die Rechnungsadresse"
        bind:toggled={sameDeliveryAddress}
        class="mb-07 no-label"
      />
      {#if !sameDeliveryAddress}
        <DeliveryAddressForm
          deliveryAddress={customer.delivery_addresses[0]}
          errors={deliveryAddressErrors}
          loaded
          on:change={changeDeliveryAddress}
        />
      {/if}
    </div>
    <div class="mb-10" data-users>
      <h4 class="mb-07">Ansprechperson</h4>
      {#each userBaseErrors as baseError}
        <div class="error mb-03">
          Kunden {baseError}
        </div>
      {/each}
      <UserForm user={customer.users[0]} errors={userErrors} loaded on:change={changeUser} />
    </div>
    <GeneralContact {errors} {customer} on:change={update} loaded />
  </Column>
</Row>
<Row>
  <Column>
    <Button on:click={submit}>Hinzufügen</Button>
  </Column>
</Row>

<script lang="ts">
  import { push } from 'svelte-spa-router'
  import { Column, Row, Button, SelectItem } from 'carbon-components-svelte'
  import BillingAddressForm from './billing_address/Form.svelte'
  import DeliveryAddressForm from './delivery_addresses/Form.svelte'
  import GeneralContact from './GeneralContact.svelte'
  import UserForm from '../users/Form.svelte'
  import Notification from '../shared/Notification.svelte'
  import Toggle from '../form/Toggle.svelte'
  import Select from '../form/Select.svelte'
  import type { AttrErrors, Customer } from '../models'
  import { user as defaultUser } from '../modelDefaults'
  import { createCustomer } from '../persistence'
  import { customerUrl } from '../urls'
  import { extractData } from '../event'
  import shops from '../data/shops.json'

  let customer = {
    country: 'CH',
    users: [{ ...defaultUser, print: true, owner: true }],
  } as Customer
  let sameDeliveryAddress = true
  let errors = {} as AttrErrors
  let userErrors = {} as AttrErrors
  let userBaseErrors = []
  let deliveryAddressErrors = {} as AttrErrors
  let error: string

  $: userErrors = errors.users || {}
  $: userBaseErrors =
    (userErrors.base && [userErrors.base].flat()) ||
    (Array.isArray(userErrors) && userErrors) ||
    (typeof userErrors === 'string' && [userErrors]) ||
    []
  $: deliveryAddressErrors = errors.delivery_addresses || {}

  $: {
    if (sameDeliveryAddress) {
      customer.delivery_addresses = undefined
    } else {
      customer.delivery_addresses = [{ country: 'CH' }]
    }
  }

  const update = event => {
    const data = event.detail.customer
    customer = { ...customer, ...data }
  }

  const changeDeliveryAddress = event => {
    customer.delivery_addresses = [{ ...customer.delivery_addresses[0], ...event.detail.deliveryAddress }]
  }

  const changeUser = event => {
    customer.users = [{ ...customer.users[0], ...event.detail.user }]
  }

  const changeShop = event => {
    customer = { ...customer, ...extractData(event) }
  }

  const submit = async () => {
    let id
    ;({ customer: { id } = {} as Customer, errors = {}, error } = await createCustomer(customer))
    if (!error) {
      push(customerUrl(id))
    }
  }
</script>
