<Select labelText={label} name="delivery_option_id" {selected} on:input>
  <SelectItem />
  {#each deliveryOptions || [] as option}
    <SelectItem value={option?.id} text={option?.name} />
  {/each}
</Select>

<script lang="ts">
  import { SelectItem, Select } from 'carbon-components-svelte'
  import { getDeliveryOptions } from '../../persistence'
  import type { DeliveryOption } from '../../models'

  export let model
  export let label
  export let internal = false
  export let deliveryOptions: DeliveryOption[]

  let selected

  $: {
    // delivery_option_id may be a string, which won't match
    selected = parseInt(model.delivery_option_id)
    if (deliveryOptions && !deliveryOptions.find(deliveryOption => deliveryOption.id === selected)) {
      selected = undefined
    }
  }

  const fetchDeliveryOptions = async () => {
    const { deliveryOptions: options } = await getDeliveryOptions({ internal })
    // prevent race condition. Because of the `$` assignment the request may
    // get triggered (on mount) and in the meantime the property gets set. In
    // that case the request's value should be discarded.
    if (deliveryOptions === undefined) {
      deliveryOptions = options
    }
  }

  $: {
    if (deliveryOptions === undefined) {
      fetchDeliveryOptions()
    }
  }
</script>
