<TextArea
  {name}
  loaded={loaded(model)}
  value={model[name]}
  invalid={errors[name] !== undefined}
  invalidText={errors[name]}
  aria-label={name}
  on:change
  {labelText}
  {...$$restProps}
>
  <slot name="labelText" slot="labelText">
    {labelText}
  </slot>
</TextArea>

<script lang="ts">
  import type { AttrErrors } from '../../models'
  import { loaded } from '../../helper'
  import TextArea from '../TextArea.svelte'

  export let model = {}
  export let errors: AttrErrors = {}
  export let name: string
  export let labelText: string
</script>
