<Authentication>
  <Router {routes} on:routeLoading={routeLoading} />
</Authentication>

<ServiceWorker />

<script lang="ts">
  import { customerId } from './stores'
  import Authentication from './shared/Authentication.svelte'
  import Customers from './customers/Table.svelte'
  import Customer from './customers/Customer.svelte'
  import CustomerEngagements from './customer_engagements/Index.svelte'
  import SevenDaysList from './SevenDaysList.svelte'
  import Catalog from './catalog/Index.svelte'
  import Router from 'svelte-spa-router'
  import NewCustomer from './customers/New.svelte'
  import OrderForm from './orders/Form.svelte'
  import ServiceWorker from './ServiceWorker.svelte'
  import MyOrders from './orders/MyOrders.svelte'
  import { enableGlobalErrorHandler } from './logging'

  const routes = {
    '/': Customers,
    '/customers/new/': NewCustomer,
    '/customers/:id': Customer,
    '/customers/:customer_id/orders/:id': OrderForm,
    '/customers/:customer_id/catalog': Catalog,
    '/customers/:customer_id/catalog/:category_id': Catalog,
    '/customer_engagements': CustomerEngagements,
    '/seven_days_list': SevenDaysList,
    '/my_orders': MyOrders,
  }

  const routeLoading = event => {
    // clear the global state of `customerId` when navigating away from a
    // customer specific page. when entering a customer specific page the
    // `customerId` is set, but is not unset when navigation away again. `api.ts`
    // includes the `customerId` in each request though, which leads to the FE
    // providing a wrong context to the API.
    const { route } = event.detail
    if (!route.startsWith('/customers/:id') && !route.startsWith('/customers/:customer_id')) {
      $customerId = undefined
    }
  }

  enableGlobalErrorHandler()
</script>
