{#if row.one_off_price}
  <Warning text="Preis wurde angepasst. Der Preis und die Rabatte werden nicht mehr automatisch überschrieben." />
{:else if row.adjustment}
  <Warning text="Die Menge wurde auf die nächsthöhere Verpackungseinheit ({row.adjustment.quantity.to}) aufgerundet." />
{:else}
  <div class="position">
    {index + 1}
  </div>
{/if}

<script lang="ts">
  import Warning from '../Warning.svelte'

  export let row
  export let index
</script>

<style lang="scss">
  .position {
    text-align: center;
  }
</style>
