export function shortDate(date: Date | string) {
  return new Date(date).toLocaleDateString('de-CH', {
    timeZone: 'Europe/Zurich',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

export function tinyDate(date: Date | string) {
  return new Date(date).toLocaleDateString('de-CH', {
    timeZone: 'Europe/Zurich',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })
}

export function daysFromNow(date: string): number {
  // Time zone information is expected otherwise browsers use either UTC or local time.
  // “When the time zone offset is absent, date-only forms are interpreted as a UTC time and date-time forms are interpreted as local time.”
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
  const day = new Date(date).getTime()
  const today = Date.now()
  const difference = Math.ceil((day - today) / (24 * 3600 * 1000))
  return difference
}

export function daysFromNowAsText(days: number) {
  if (days < 0) return null
  if (days === 0) return 'Heute'
  if (days === 1) return 'Morgen'
  if (days === 2) return 'Übermorgen'
  return `In ${days} Tagen`
}
