<Toggle
  loaded={loaded(customer)}
  labelText="Dispobox"
  labelA="Keine Dispobox"
  labelB="Dispobox"
  name="dispobox"
  toggled={customer.dispobox}
  on:change
/>

<script lang="ts">
  import type { Customer } from '../../models'
  import Toggle from '../../form/Toggle.svelte'
  import { loaded } from '../../helper'

  export let customer: Customer
</script>
