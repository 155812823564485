<Column lg={2} md={2} sm={1} xs={2}>
  <ClickableTile light on:click>
    <div class="category">
      <img class="category__image" src={category.image_url} alt={category.image_name} />
    </div>
    <p class="category-text">{localizedAttribute(category, 'name', localeFromlanguage(customer.language))}</p>
  </ClickableTile>
</Column>

<script>
  import { Column, ClickableTile } from 'carbon-components-svelte'
  import { localeFromlanguage, localizedAttribute } from '../i18n'

  export let category
  export let customer
</script>

<style lang="sass">
  .category
    height: 5rem
    text-align: center

  .category__image
    max-width: 7rem
    max-height: 5rem

  .category-text
    text-align: center
</style>
