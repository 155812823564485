{#if loaded}
  <Select bind:selected on:input {...$$restProps}>
    <slot />
  </Select>
{:else if $$props.inline}
  <SkeletonText heading {width} />
{:else}
  <FormGroup legendText={$$props.labelText}>
    <SkeletonText heading {width} />
  </FormGroup>
{/if}

<script lang="ts">
  import { Select, SkeletonText, FormGroup } from 'carbon-components-svelte'

  export let selected: string = undefined
  export let loaded = false
  export let width = '100%'
</script>
