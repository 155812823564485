<Modal
  bind:open
  {modalHeading}
  on:submit={put}
  on:close={clear}
  on:click:button--secondary={clear}
  hasForm
  {primaryButtonText}
  secondaryButtonText="Abbrechen"
  shouldSubmitOnEnter={false}
>
  <Notification {error} />
  <Row>
    <Column>
      <div>
        <TextArea bind:value={editedNote} aria-label={headline} />
      </div>
    </Column>
  </Row>
</Modal>

<script lang="ts">
  import type { Note, Customer } from '../../models'
  import { Row, Column, TextArea } from 'carbon-components-svelte'
  import Modal from '../../shared/Modal.svelte'
  import { createEventDispatcher } from 'svelte'
  import { updateCustomer } from '../../persistence'
  import Notification from '../../shared/Notification.svelte'
  import { customerId } from '../../stores'

  export let noteObject: Note
  export let open: boolean
  export let isNew: boolean

  let error: string
  let editedNote = ''
  let modalHeading
  let primaryButtonText
  const dispatch = createEventDispatcher()

  const clear = () => {
    error = undefined
    editedNote = note
    open = false
  }

  const put = async () => {
    ;({ error } = await updateCustomer({
      id: $customerId,
      [key]: editedNote,
    } as unknown as Customer))
    if (!error) {
      clear()
      dispatch('success')
    }
  }

  $: ({ key, headline, note } = noteObject)
  $: {
    if (isNew) {
      modalHeading = `${headline} hinzufügen`
      primaryButtonText = 'Hinzufügen'
    } else {
      modalHeading = `${headline} ändern`
      primaryButtonText = 'Speichern'
    }
  }
  $: editedNote = note
</script>
