<Row>
  <Column>
    {#each sectorGroups as group}
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="bx--label">{group.name}</label>
      {#each group.sectors as { id, name }}
        <Checkbox labelText={name} bind:group={ids} value={id} />
      {/each}
    {/each}
  </Column>
</Row>

<script lang="ts">
  import type { SectorGroup, Shop } from '../../models'
  import { Row, Column, Checkbox } from 'carbon-components-svelte'
  import { onMount } from 'svelte'
  import { getSectorGroups } from '../../persistence'

  export let shop: Shop
  export let ids = []

  let sectorGroups: SectorGroup[] = []

  onMount(async () => {
    sectorGroups = (await getSectorGroups()) || []
    sectorGroups = sectorGroups.filter(sectorGroup => sectorGroup.shop === shop)
  })
</script>
