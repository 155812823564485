import countries from './data/countries.json'

export const countryNameByCode = countryCode => countries.find(country => country[0] === countryCode)[1]

export const countryNameForDisplay = countryCode => {
  if (!countryCode) return ''
  if (countryCode === 'CH') return ''

  return countryNameByCode(countryCode)
}
