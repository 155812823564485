<Modal
  {open}
  on:submit={put}
  on:close={clear}
  modalHeading="Branche ändern"
  hasForm
  primaryButtonText="Speichern"
  secondaryButtonText="Abbrechen"
  on:click:button--secondary={clear}
  data-sector-modal
>
  <Notification {error} />
  <Form bind:ids shop={customer.shop} />
</Modal>

<script lang="ts">
  import type { Customer } from '../../models'
  import Modal from '../../shared/Modal.svelte'
  import { createEventDispatcher } from 'svelte'
  import { updateCustomer } from '../../persistence'
  import Notification from '../../shared/Notification.svelte'
  import Form from './Form.svelte'

  export let customer: Customer
  export let open = true

  let error: string
  let ids = customer.sectors.map(sector => sector.id)

  const dispatch = createEventDispatcher()
  const clear = () => {
    open = false
    error = undefined
  }
  const put = async () => {
    const customerForPut = {
      id: customer.id,
      sector_ids: ids,
    }
    ;({ error } = await updateCustomer(customerForPut))
    if (!error) {
      dispatch('success')
      clear()
    }
  }
</script>
