import { v4 as uuid } from 'uuid'
import { localeFromlanguage } from './i18n'
import { Language, OrderState, type Customer, type Order, type User } from './models'
import { getDeliveryOptions } from './persistence'

export const user = {
  language: Language.de,
  gender: 'male',
} as User

export async function getNewOrder(customer: Customer): Promise<Order> {
  return {
    id: uuid(),
    customer_id: customer.id,
    created_at: new Date(),
    state: OrderState.draft,
    delivery_option_id: customer.delivery_option_id || (await getDeliveryOptions())?.deliveryOptions?.[0]?.id,
    line_items: [],
    delivery_address_id: customer.primary_delivery_address.id,
    locale: localeFromlanguage(customer.language),
    kind: 'order',
  } as Order
}

export function getNewLineItem() {
  return { id: uuid() }
}
