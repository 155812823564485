<!-- svelte-ignore a11y-click-events-have-key-events -->
{#if products?.length > 0}
  <DataTable {headers} rows={productRows}>
    <svelte:fragment slot="cell-header" let:header>
      {#if header.key === 'sales_volume'}
        <Tooltip triggerText={header.value} direction="bottom" align="end" class="bx-tooltip--table-header">
          <p>Verbrauch: Dieses Jahr / Letztes Jahr</p>
        </Tooltip>
      {:else}
        {header.value}
      {/if}
    </svelte:fragment>
    <div slot="cell" let:cell let:row class="row" on:click={() => showProduct(row)}>
      {#if cell.key === 'image'}
        <div class="sub-row__compact">
          <span class="image1 mr-02">
            <Image product={row} verticallyCentered />
          </span>
          {#if row.image2_name}
            <Image product={row} number="2" verticallyCentered />
          {/if}
        </div>
      {:else if cell.key === 'product'}
        <div class="sub-row__compact">
          <strong>{row.sku}</strong>
        </div>
        {localizedAttribute(row, 'name', localeFromlanguage(customer.language))}
        <br />
        {formatValue(localizedAttribute(row, 'summary', localeFromlanguage(customer.language)), '')}
      {:else if cell.key === 'availability'}
        <AvailabilityAndStock product={row} />
      {:else if cell.key === 'price'}
        {formatValue(row.price, '')}
        {#if row.code_name}
          ({row.code_name})
        {/if}
        {#if row.promotion_price}
          <br />
          <div class="promotion">
            Aktions-Preis: {row.promotion_price}
          </div>
        {/if}
        <div>
          <PerUnit product={row} />
        </div>
      {:else if cell.key === 'order' || cell.key === 'cart'}
        <div on:click={event => (event.cancelBubble = true)}>
          <ShoppingCartRow {cell} product={row} {customer} bind:dirtyPurchasedProducts bind:success />
        </div>
      {:else if cell.key === 'sales_volume'}
        {formatValue(row.year0_volume)} / {formatValue(row.year1_volume)}
      {/if}
    </div>
  </DataTable>

  {#if pagination}
    <PaginationNav
      page={pagination.current - 1}
      total={pagination.last}
      on:change={event => changePagination(event, pagination.current)}
    />
  {/if}

  {#if showDetail}
    <div class="overlay" on:click={closeOverlay}>
      <aside class="catalog">
        <Button kind="ghost" icon={Close} on:click={closeOverlay}>Schliessen</Button>
        <div class="float-right flex-end">
          <Button
            kind="ghost"
            href={shopUrl('product_labels', localeFromlanguage(customer.language), { 'product_ids[]': productId })}
            on:click={event => event.stopPropagation()}
            target="_blank">Etiketten drucken ↗︎</Button
          >
        </div>
        {#if $online}
          <iframe
            src="{shopUrl(
              'shop',
              localeFromlanguage(customer.language),
              {},
              customer.shop
            )}/products/{productId}?layout=none&navigation=none&crm_embedded=true"
            title="Shop"
            allow="clipboard-write"
            on:load={iframeLoaded}
          />
        {:else}
          <NotAvailableOfflineNotification />
        {/if}
      </aside>
    </div>
  {/if}
{/if}

<script lang="ts">
  import { Button, DataTable, Tooltip, PaginationNav } from 'carbon-components-svelte'
  import { Close } from 'carbon-icons-svelte'
  import { shopUrl } from '../urls'
  import { localeFromlanguage, localizedAttribute } from '../i18n'
  import Image from '../orders/products/Image.svelte'
  import { formatValue } from '../format'
  import PerUnit from '../orders/line_items/table/PerUnit.svelte'
  import ShoppingCartRow from '../products/ShoppingCartRow.svelte'
  import AvailabilityAndStock from '../products/AvailabilityAndStock.svelte'
  import { createEventDispatcher } from 'svelte'
  import { online } from '../stores'
  import { appendToDraftOrder } from '../orders/order'
  import NotAvailableOfflineNotification from '../shared/NotAvailableOfflineNotification.svelte'
  import type { Product } from '../models'

  export let products: Product[]
  export let customer
  export let pagination

  let productRows

  $: productRows = products.map(product => ({
    ...product,
    id: product.sku,
  }))

  const headers = [
    { key: 'image', value: '', width: '10%' },
    { key: 'product', value: 'Artikel' },
    { key: 'availability', value: '', width: '70px' },
    { key: 'sales_volume', value: '', width: '150px' },
    { key: 'price', value: 'Preis', width: '15%' },
    { key: 'order', value: 'Bestellen', width: '14%' },
    { key: 'cart', value: '', width: '70px' },
  ]

  let productId
  let dirtyPurchasedProducts = {}
  let success = {}

  const showProduct = product => (productId = product.id)
  const closeOverlay = () => (productId = null)

  const dispatch = createEventDispatcher()

  const changePagination = async (event, page) => {
    const newPage = event.detail.page + 1
    if (page === newPage) return
    dispatch('changePagination', event.detail)
  }

  const iframeLoaded = e => {
    const iframe = e.currentTarget
    const channel = new MessageChannel()
    const port1 = channel.port1

    // Listen for messages on port1
    port1.onmessage = async e => {
      const entriesWithQuantity = e.data.map(({ productId, quantity }) => ({
        product: { sku: productId },
        quantity,
      }))
      await appendToDraftOrder(customer, entriesWithQuantity)
    }

    // Transfer port2 to the iframe
    iframe.contentWindow.postMessage('initPort', '*', [channel.port2])
  }

  $: showDetail = productId
</script>

<style lang="scss">
  @import '../styles/variables';
  @import 'carbon-components/scss/globals/scss/typography';

  .overlay {
    z-index: 3;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .catalog {
    position: fixed;
    top: $header-height;
    right: 0;
    bottom: 0;
    width: 75%;
    background-color: white;

    iframe {
      width: 100%;
      height: calc(100% - $header-height);
    }
  }

  .promotion {
    color: $support-error;
  }

  .image1 {
    display: inline-block;
    /* this is the width as defined in cloudinary.ts */
    width: 40px;
  }
</style>
