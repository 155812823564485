import { sortKeys, compact } from './object'

// A localStorage object
// The retrieved object has sorted keys and pruned empty values.
export class LocalObject {
  #name

  constructor(name) {
    this.#name = name
  }

  get(): object {
    return JSON.parse(localStorage.getItem(this.#name) || '{}')
  }

  set(object) {
    let updated = { ...this.get(), ...object }
    // When serializing an object, the sort order of its keys is lost.
    // Therefore, it makes sense to sort keys when serializing.
    updated = sortKeys(updated)
    // For convenience we also prune empty values here.
    // When accessed after deserialization all empty values will be `undefined`.
    updated = compact(updated)

    if (Object.keys(updated).length > 0) localStorage.setItem(this.#name, JSON.stringify(updated))
    else this.clear()
  }

  clear() {
    localStorage.removeItem(this.#name)
  }
}
