<h4 class="mb-06">Rechnungsadresse</h4>
<Row narrow>
  <Column md={4}>
    <ClickableTile class="mb-10" on:click={() => (customerCopy = buildCustomerForModal())} data-billing-address>
      <Text loaded={loaded(customer)} value={customer.name} width="40%" />
      <Text loaded={loaded(customer)} value={customer.industry || ''} width="40%" />
      <Text loaded={loaded(customer)} value={customer.street || ''} width="40%" />
      <Text loaded={loaded(customer)} value={customer.postbox || ''} width="40%" />
      <Text loaded={loaded(customer)} value={`${customer.zip_code} ${customer.city}`} width="40%" />
      <Text loaded={loaded(customer)} value={countryNameForDisplay(customer.country) || ''} width="40%" />
    </ClickableTile>
  </Column>
</Row>

<Modal
  customer={customerCopy}
  on:success={event => dispatch('updateCustomerObject', returnUpdatedCustomer(extractData(event).customer))}
/>

<script lang="ts">
  import type { Customer } from '../../models'
  import { Row, Column, ClickableTile } from 'carbon-components-svelte'
  import { createEventDispatcher } from 'svelte'
  import { loaded } from '../../helper'
  import Text from '../../form/Text.svelte'
  import Modal from './Modal.svelte'
  import { countryNameForDisplay } from '../../country'
  import { extractData } from '../../event'

  export let customer: Customer
  export let open = false

  let customerCopy: Customer

  const dispatch = createEventDispatcher()
  const modalCustomerKeys = ['name', 'industry', 'street', 'postbox', 'zip_code', 'city', 'country']

  const buildCustomerForModal: () => Customer = () =>
    modalCustomerKeys.reduce<Customer>((obj, key) => ({ ...obj, [key]: customer[key] }), {
      id: customer.id,
    })

  const returnUpdatedCustomer: (Customer) => Customer = updatedCustomerAttributes => ({
    ...customer,
    ...updatedCustomerAttributes,
  })

  $: if (open) customerCopy = buildCustomerForModal()
</script>
