<Notification {success} hideCloseButton={false} on:close={() => (success = undefined)} />
<Notification {error} hideCloseButton={false} on:close={() => (error = undefined)} />

<Confirm
  open={removableUserId !== undefined}
  humanModelName="Ansprechperson"
  humanModelNamePronoun="die"
  on:confirm={removeUser}
  on:cancel={() => (removableUserId = undefined)}
/>

<Table
  {customer}
  users={customer.users}
  on:addUser={add}
  on:editUser={edit}
  on:deleteUser={event => (removableUserId = extractData(event).id)}
  on:resetPassword={resetPassword}
  on:success={event => (success = event.detail)}
/>

{#if formUser}
  <FormModal
    model={formUser}
    modelName="user"
    humanModelName="Ansprechperson"
    humanModelNamePronoun="die"
    open={formUser}
    form={Form}
    {selectorPrimaryFocus}
    primaryButtonTextSetter={(create, user) => {
      if (create) return `Hinzufügen${user?.email && !user?.deactivated ? ' und E-Mail senden' : ''}`
      else return 'Speichern'
    }}
    on:success={formSuccess}
    on:close={() => (formUser = undefined)}
  />
{/if}

<script lang="ts">
  import type { Customer, User } from '../models'
  import Confirm from '../shared/Confirm.svelte'
  import FormModal from '../shared/FormModal.svelte'
  import Form from './Form.svelte'
  import { deleteUser, postPassword } from '../persistence'
  import Table from './Table.svelte'
  import Notification from '../shared/Notification.svelte'
  import { fetchSuccessful } from '../helper'
  import { createEventDispatcher } from 'svelte'
  import { extractData } from '../event'
  import { user as defaultUser } from '../modelDefaults'

  export let customer: Customer

  let formUser: User
  let success: string
  let error: string
  let removableUserId: string
  let selectorPrimaryFocus: string

  const dispatch = createEventDispatcher()

  const formSuccess = event => {
    formUser = undefined

    const { user, action } = extractData(event)
    if (action === 'create') {
      const message = `Du hast ${user.first_name} ${user.last_name} als Ansprechperson hinzugefügt.`
      refresh(
        user.email && !user.deactivated
          ? `${message} Eine Willkommens-E-Mail wurde an ${user.email} versendet.`
          : message
      )
    } else if (action === 'update') {
      refresh('Ansprechperson aktualisiert')
    } else if (action === 'delete') {
      afterRemoveUser()
    }
  }

  const add = () => {
    selectorPrimaryFocus = '[name=first_name]'
    formUser = defaultUser
  }

  const edit = event => {
    selectorPrimaryFocus = '[data-none]'
    formUser = extractData(event)
  }

  const removeUser = async () => {
    ;({ error } = await deleteUser(removableUserId))
    if (fetchSuccessful(error)) afterRemoveUser()
  }
  const afterRemoveUser = async () => {
    refresh('Der Ansprechpartner wurde erfolgreich entfernt.')
    removableUserId = undefined
  }

  const resetPassword = async (event: CustomEvent) => {
    const email = event.detail.email
    ;({ error } = await postPassword(email))
    if (fetchSuccessful(error))
      return (success = `Der Passwort-Zurücksetzen-Link wurde via E-Mail an ${email} versendet.`)
  }

  const refresh = text => {
    success = text
    dispatch('change')
  }
</script>
