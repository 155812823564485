<Form>
  <Row>
    <Column>
      <RadioGroup selected={user.language} {loaded} labelText="Sprache" labelPosition="right" orientation="vertical">
        {#each Object.entries(languages) as [key, value]}
          <RadioButton name="language" labelText={value} value={key} on:change={change} />
        {/each}
      </RadioGroup>
    </Column>
    <Column>
      <RadioGroup selected={user.gender} {loaded} labelText="Anrede" labelPosition="right" orientation="vertical">
        <RadioButton id="gender-m" name="gender" labelText="Herr" value="male" on:change={change} />
        <RadioButton id="gender-w" name="gender" labelText="Frau" value="female" on:change={change} />
      </RadioGroup>
    </Column>
  </Row>

  <Row>
    <Column class="mb-07" md={4}>
      <TextInput model={user} name="first_name" labelText="Vorname" {errors} on:change={change} loaded />
    </Column>
    <Column class="mb-07" md={4}>
      <TextInput model={user} name="last_name" labelText="Nachname" {errors} on:change={change} loaded />
    </Column>

    <Column class="mb-07" md={4}>
      <TextInput model={user} name="email" labelText="E-Mail" {errors} on:change={change} loaded />
    </Column>
    <Column class="mb-07" md={4}>
      <TextInput model={user} name="mobile" labelText="Mobile" {errors} on:change={change} loaded />
    </Column>

    <Column class="mb-07" md={4}>
      <TextInput model={user} name="phone" labelText="Telefon" {errors} on:change={change} loaded />
    </Column>
    <Column class="mb-07" md={4}>
      <Select
        selected={user.role}
        name="role"
        {loaded}
        labelText="Funktion"
        helperText={roleHelperText}
        invalid={errors.role !== undefined}
        invalidText={errors.role}
        on:input={change}
      >
        <SelectItem text="Bitte auswählen…" />
        {#each roles as role}
          <SelectItem text={role} value={role} />
        {/each}
      </Select>
    </Column>
  </Row>
  <Row>
    <Column>
      <Checkbox
        skeleton={!loaded}
        checked={user.print}
        labelText="Print"
        invalid={errors.print !== undefined}
        invalidText={errors.print}
        name="print"
        on:change={change}
      />
    </Column>
  </Row>

  <Row>
    <Column>
      <Checkbox
        bind:checked={shopLogin}
        skeleton={!loaded}
        labelText="Shop Login"
        invalid={errors.deactivated !== undefined}
        invalidText={errors.deactivated}
        name="deactivated"
        on:change={change}
      />
    </Column>
  </Row>

  {#if shopLogin}
    <Row style="padding-left: 1rem">
      <Column>
        <Checkbox
          checked={user.owner}
          skeleton={!loaded}
          labelText="Superuser"
          invalid={errors.owner !== undefined}
          invalidText={errors.owner}
          name="owner"
          on:change={change}
        />
      </Column>
    </Row>
    <Row style="padding-left: 1rem">
      <Column>
        <Checkbox
          checked={user.net_price}
          skeleton={!loaded}
          labelText="Nettopreise anzeigen"
          invalid={errors.net_price !== undefined}
          invalidText={errors.net_price}
          name="net_price"
          on:change={change}
        />
      </Column>
    </Row>
  {/if}
</Form>

<script lang="ts">
  import type { User, UserErrors } from '../models'
  import { Form, Column, Row, SelectItem, RadioButton, Checkbox } from 'carbon-components-svelte'
  import RadioGroup from '../form/RadioGroup.svelte'
  import TextInput from '../form/model/TextInput.svelte'
  import Select from '../form/Select.svelte'
  import { createEventDispatcher } from 'svelte'
  import { extractData } from '../event'
  import roles from '../data/contact_roles.json'
  import languages from '../data/customer_languages.json'

  export let user: User
  export let errors: UserErrors
  export let loaded = false
  let shopLogin = !user.deactivated

  const dispatch = createEventDispatcher()

  $: roleHelperText = user.notes ? `Vorher: ${user.notes}` : null

  const change = event => {
    const data = extractData(event)
    if ('deactivated' in data) {
      data.deactivated = !data.deactivated
    }

    dispatch('change', { user: data })
  }
</script>
