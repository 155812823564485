<DataTable {headers} rows={order.line_items} class="compact-columns overflow-bottom flex-content">
  <svelte:fragment slot="cell-header" let:header>
    {#if ['price', 'net_price', 'discount', 'volume_discount', 'total'].includes(header.key)}
      <div class="right-aligned">
        {header.value}
      </div>
    {:else}
      {header.value}
    {/if}
  </svelte:fragment>
  <svelte:fragment slot="cell" let:cell let:row let:rowIndex={index}>
    <Cell
      {cell}
      {row}
      {index}
      {updateInProgress}
      locale={order.locale}
      {customer}
      on:change={lineItem => updateLineItem(index, lineItem)}
      on:delete={() => deleteLineItem(index)}
      on:setOneOffPrice={() => showOneOffPriceModal(index)}
      on:setAlreadyDelivered={() => updateAlreadyDelivered(index)}
      showDelete={getLastLineItem().id !== row.id}
    />
  </svelte:fragment>
</DataTable>

{#if oneOffPriceIndex !== undefined}
  <OneOffPriceModal
    {order}
    lineItem={order.line_items[oneOffPriceIndex]}
    on:change={changeOneOffPrice}
    on:cancel={hideOneOffPriceModal}
  />
{/if}

<script lang="ts">
  import { createEventDispatcher, tick } from 'svelte'
  import { DataTable } from 'carbon-components-svelte'
  import type { Customer, Order, LineItem } from '../../models'
  import { adjust, adjustNewLineItem, isEmptyLineItem } from '../order'
  import { extractData } from '../../event'
  import OneOffPriceModal from '../OneOffPriceModal.svelte'
  import Cell from './table/Cell.svelte'

  export let order: Order
  export let customer: Customer

  const headers = [
    { key: 'position', value: '', width: '5%' },
    { key: 'product', value: 'Artikelnummer', width: '30%' },
    { key: 'image_name', empty: true },
    { key: 'quantity', value: 'Menge', width: '4.5rem' }, // fit 4 digits on hoelzle tablet screen size
    { key: 'availability', value: '', width: '2.0rem' }, // $spacing-08
    { key: 'price', value: 'Preis' },
    { key: 'discount', value: 'GR' },
    { key: 'volume_discount', value: 'MR' },
    { key: 'net_price', value: 'Netto' },
    { key: 'total', value: 'Total' },
    { key: 'delete', value: '', width: '3rem' }, // $spacing-09
  ]

  const dispatch = createEventDispatcher()

  let oneOffPriceIndex
  let runningUpdates = []
  $: updateInProgress = runningUpdates.length > 0

  const hideOneOffPriceModal = () => {
    oneOffPriceIndex = undefined
  }

  const changeOneOffPrice = async event => {
    await updateOrder(() => {
      order.line_items = [...order.line_items]
      order.line_items[oneOffPriceIndex] = extractData(event)
    }, true)

    hideOneOffPriceModal()
  }

  const showOneOffPriceModal = async index => {
    await synchronize()

    oneOffPriceIndex = index
  }

  const updateAlreadyDelivered = async index => {
    await updateOrder(() => {
      const lineItem = order.line_items[index]
      lineItem.already_delivered = !lineItem.already_delivered
      order.line_items[index] = lineItem
    }, false)
  }

  const updateLineItem = async (index, event) => {
    const lineItem: LineItem = extractData(event)?.lineItem

    const isNew = order.line_items[index].product !== lineItem.product

    await updateOrder(() => {
      order.line_items[index] = lineItem
    }, true)

    if (isNew) {
      order.line_items[index] = await adjustNewLineItem(order, order.line_items[index])
      dispatch('change', order)
    }
  }

  const deleteLineItem = async index => {
    await updateOrder(() => {
      order.line_items.splice(index, 1)
      order.line_items = [...order.line_items]
    }, true)
  }

  const updateOrder = async (updateAction, withAdjustments = false) => {
    updateAction()

    // make a copy to make sure tha change event uses the same data as the adjust request
    let pendingOrder = { ...order }
    // 1. trigger an update with the un-adjusted order already, such that an
    // empty line item is added, and the user can continue editing
    dispatch('change', pendingOrder)
    // wait for updated order before adjusting. this is esp. necessary in order
    // to keep the newly added empty line. we want to keep the empty line,
    // otherwise the user loses focus on it.
    await tick()

    if (withAdjustments) {
      const now = Date.now()
      runningUpdates.push(now)

      pendingOrder = await adjust(pendingOrder)

      const isStale = runningUpdates.length === 0 || runningUpdates.some(x => x > now)
      if (!isStale) {
        runningUpdates = runningUpdates.filter(x => x > now)

        // keep empty line items that have been added in the meantime
        if (
          Object.keys(pendingOrder).length &&
          order.line_items.length > pendingOrder.line_items.length &&
          isEmptyLineItem(order.line_items[order.line_items.length - 1])
        ) {
          pendingOrder.line_items.push(order.line_items[order.line_items.length - 1])
        }
        // reject the update if the number of line items has changed in the meantime
        if (Object.keys(pendingOrder).length && order.line_items.length === pendingOrder.line_items.length) {
          // 2. trigger an update with the adjusted order
          dispatch('change', { ...pendingOrder })
        }
      }

      // wait for the next update before declaring the current update done
      await tick()

      delete runningUpdates[now]
    }
  }

  // make update an atomic operation, otherwise different changes may
  // overwrite each other in race conditions. this happens as
  // a) we dispatch a change event to the parent component, which in turn issues a rerender of this component
  // b) the adjust operation makes a request which may take some time
  const synchronize = async () => {
    while (updateInProgress) await new Promise(r => setTimeout(r, 100))
  }

  const getLastLineItem = () => order.line_items[order.line_items.length - 1]

  $: {
    dispatch('loading', { loading: updateInProgress })
  }
</script>
