<Modal
  {open}
  on:submit={put}
  on:close={close}
  modalHeading="Kunde vervollständigen"
  hasForm
  primaryButtonText="Kunde vervollständigen"
  secondaryButtonText="Abbrechen"
  on:click:button--secondary={close}
  data-invalid-customer-modal
  primaryButtonDisabled={!loaded(customer)}
>
  <Notification {error} />
  <Row>
    {#if errors.rep}
      {#if isPermitted('rep_id')}
        <Column class="mb-07">
          <Select
            loaded={loaded(customer) && reps.length}
            labelText="Aussendienst"
            name="rep_id"
            selected={customer.rep_id}
            invalid={errors.rep !== undefined}
            invalidText={errors.rep}
            on:input={updateField}
          >
            <SelectItem />
            {#each reps as rep}
              <SelectItem value={rep.id} text={rep.name} />
            {/each}
          </Select>
        </Column>
      {:else}
        <Notification
          class="max-width-100"
          warning="Das Feld Aussendienst kann nur vom Innendienst bearbeitet werden. Bitte die IT kontaktieren zur Behebung des Problems"
        />
      {/if}
    {/if}
    {#if errors.name}
      <Column class="mb-07">
        <TextInput model={customer} name="name" on:change={updateField} labelText="Name" {errors} />
      </Column>
    {/if}
    {#if errors.language}
      <Column class="mb-07">
        <Language {customer} {errors} on:input={updateField} />
      </Column>
    {/if}
    {#if errors.street}
      <Column class="mb-07">
        <TextInput model={customer} on:change={updateField} name="street" labelText="Strasse" {errors} />
      </Column>
    {/if}
    {#if errors.zip_code}
      <Column md={2} class="mb-07">
        <TextInput model={customer} name="zip_code" on:change={updateField} labelText="Postleitzahl" {errors} />
      </Column>
    {/if}
    {#if errors.city}
      <Column md={6} class="mb-07">
        <TextInput model={customer} name="city" on:change={updateField} labelText="Ort" {errors} />
      </Column>
    {/if}
    {#if errors.note}
      <Column class="mb-07">
        <TextArea
          value={customer.note}
          aria-label={'note'}
          name="note"
          on:change={updateField}
          labelText="Bemerkung"
          invalid={errors.note !== undefined}
          invalidText={errors.note}
        />
      </Column>
    {/if}
    {#if errors.special_note}
      <Column class="mb-07">
        <TextArea
          value={customer.special_note}
          aria-label={'special_note'}
          name="special_note"
          on:change={updateField}
          labelText="Spezielles"
          invalid={errors.special_note !== undefined}
          invalidText={errors.special_note}
        />
      </Column>
    {/if}
    {#if errors.important_note}
      <Column class="mb-07">
        <TextArea
          value={customer.important_note}
          aria-label={'important_note'}
          name="important_note"
          on:change={updateField}
          labelText="Wichtig"
          invalid={errors.important_note !== undefined}
          invalidText={errors.important_note}
        />
      </Column>
    {/if}
    {#if errors.logistics_note}
      <Column class="mb-07">
        {#if isPermitted('logistics_note')}
          <div>
            <TextArea
              value={customer.logistics_note}
              aria-label={'logistics_note'}
              name="logistics_note"
              on:change={updateField}
              labelText="Lagerbemerkung"
              invalid={errors.logistics_note !== undefined}
              invalidText={errors.logistics_note}
            />
          </div>
        {:else}
          <Notification
            class="max-width-100"
            warning="Das Feld Lagerbemerkung kann nur vom Innendienst bearbeitet werden. Bitte die IT kontaktieren zur Behebung des Problems"
          />
        {/if}
      </Column>
    {/if}
    {#if errors.rep_note}
      <Column>
        {#if isPermitted('rep_note')}
          <TextArea
            value={customer.rep_note}
            aria-label={'rep_note'}
            name="rep_note"
            on:change={updateField}
            labelText="Bemerkung AD"
            invalid={errors.rep_note !== undefined}
            invalidText={errors.rep_note}
          />
        {:else}
          <Notification
            class="max-width-100"
            warning="Das Feld Bemerkung AD kann nur vom Aussendienst bearbeitet werden. Bitte die IT kontaktieren zur Behebung des Problems"
          />
        {/if}
      </Column>
    {/if}
    {#if errors.discount}
      <Column class="mb-07" lg={8} xlg={4}>
        <Discount {customer} {errors} {loaded} on:change={updateField} />
      </Column>
    {/if}
    {#if errors.payment_behavior}
      <Column class="mb-07" lg={8} xlg={4}>
        <PaymentBehavior {customer} {errors} {loaded} on:input={updateField} />
      </Column>
    {/if}
    {#if errors.payment_conditions}
      <Column class="mb-07" lg={8} xlg={4}>
        <PaymentConditions {customer} {errors} {loaded} on:input={updateField} />
      </Column>
    {/if}
    {#if errors.solvency_check}
      <Column class="mb-07" lg={8} xlg={4}>
        <SolvencyCheck {customer} {errors} on:input={updateField} />
      </Column>
    {/if}
    {#if errors.currency}
      <Column class="mb-07" lg={8} xlg={4}>
        <Currency {customer} {errors} {loaded} on:input={updateField} />
      </Column>
    {/if}
    {#if errors.delivery_cost_setting}
      <Column class="mb-07" lg={8} xlg={4}>
        <DeliveryCostSetting {customer} {errors} {loaded} on:input={updateField} />
      </Column>
    {/if}
    {#if errors.dispobox}
      <Column>
        <Dispobox {customer} on:change={updateField} />
      </Column>
    {/if}
    {#if errors.export}
      <Column class="mb-07" lg={8} xlg={4}>
        <Export {customer} {errors} {loaded} on:input={updateField} />
      </Column>
    {/if}
    {#if errors.prints}
      <Column class="mb-07" lg={8} xlg={4}>
        <Prints {customer} {errors} {loaded} on:input={updateField} />
      </Column>
    {/if}
  </Row>
</Modal>

<script lang="ts">
  import type { AttrErrors, Customer } from '../../models'
  import { Row, Column, SelectItem, TextArea } from 'carbon-components-svelte'
  import Modal from '../../shared/Modal.svelte'
  import { createEventDispatcher, onMount } from 'svelte'
  import TextInput from '../../form/model/TextInput.svelte'
  import { getReps, updateCustomer } from '../../persistence'
  import Notification from '../../shared/Notification.svelte'
  import Select from '../../form/Select.svelte'
  import Discount from '../payment/Discount.svelte'
  import PaymentBehavior from '../payment/PaymentBehavior.svelte'
  import PaymentConditions from '../payment/PaymentConditions.svelte'
  import SolvencyCheck from '../payment/SolvencyCheck.svelte'
  import Currency from '../payment/Currency.svelte'
  import DeliveryCostSetting from '../logistics/DeliveryCostSetting.svelte'
  import Export from '../logistics/Export.svelte'
  import Prints from '../logistics/Prints.svelte'
  import Dispobox from '../logistics/Dispobox.svelte'
  import Language from '../Language.svelte'
  import { loaded } from '../../helper'
  import { extractData } from '../../event'
  import { customerId } from '../../stores'
  import type { Rep } from '../../models'

  export let open: boolean
  export let errors: AttrErrors = {}
  export let customer: Customer
  export let permittedFields: string[]
  let error = ''
  let reps: Rep[] = []

  const dispatch = createEventDispatcher()
  let updatedCustomer = { id: $customerId } as Customer

  const isPermitted = field => permittedFields.indexOf(field) !== -1

  const updateField = event => {
    updatedCustomer = { ...updatedCustomer, ...extractData(event) }
  }

  const put = async () => {
    ;({ errors = {}, error } = await updateCustomer(updatedCustomer))
    if (!error) {
      dispatch('change')
      close()
    }
  }

  onMount(() => {
    // make sure that the value gets sent to the server, even if the user
    // doesn't click on the toggle. also make sure that it's set to a boolean
    // value (i.e. nil => false), so we set this to either false or true.
    updatedCustomer.dispobox = !!customer.dispobox
  })

  const close = () => {
    dispatch('close')
  }

  onMount(async () => {
    reps = (await getReps()) || []
  })
</script>
