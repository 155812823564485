<script lang="ts">
  import { onMount } from 'svelte'
  import { getCustomers } from '../../persistence'
  import { db } from '../../db'
  import { syncCount } from '../../stores'
  import { Level, slog } from '../../logging'

  const fetchCustomers = async () => {
    const latestCustomer = await db.customers.orderBy('updated_at').reverse().first()
    if (latestCustomer?.updated_at) {
      let updatedSince = new Date(latestCustomer.updated_at)
      let updatedSinceValue = updatedSince.valueOf()
      if (Number.isNaN(updatedSinceValue)) {
        slog(
          'customer_offline_loader_invalid_date',
          {
            customer_id: latestCustomer.id,
            updated_at: `"${latestCustomer.updated_at}"`,
            updated_at_date: `"${updatedSince}"`,
            updated_at_value: updatedSinceValue,
            updated_at_type: typeof latestCustomer.updated_at,
          },
          Level.warn
        )
        // Full sync in case we can't make sense of the date format. In this
        // case it's the safest to just update everything
        updatedSince = undefined
      }
      await fetchCustomersSince(1, updatedSince)
    } else {
      await fetchCustomersSince(1)
    }
  }

  const fetchCustomersSince = async (page, updatedSince) => {
    const params = { sort_by: 'oldest_updated' }
    if (updatedSince) params.updated_since = updatedSince.toISOString()

    const { customers = [], pagination = {}, error } = await getCustomers(page, '', params, false, true)
    await db.customers.bulkPut(customers)

    if (!error && pagination.next) {
      await fetchCustomersSince(pagination.next, updatedSince)
    }
  }

  onMount(async () => {
    try {
      syncCount.update(count => count + 1)
      await fetchCustomers()
    } finally {
      syncCount.update(count => count - 1)
    }
  })
</script>
