<OverflowMenuItem on:click {...$$restProps}>
  <span class="mr-03 text"><slot>{text}</slot></span>
  <slot name="icon" />
</OverflowMenuItem>

<script lang="ts">
  import { OverflowMenuItem } from 'carbon-components-svelte'

  export let text = ''
</script>

<style lang="scss" global>
  .text {
    white-space: nowrap;
  }
</style>
