{#if product && (product.minimum_quantity || product.industry_quantity)}
  {#if !hideLabel}
    VPE
  {/if}
  {#if !oneLine}
    <br />
  {/if}
  {packingUnit(product)}
{/if}

<script lang="ts">
  import { unique, compact } from '../../../array'
  import type { Product } from '../../../models'

  export let product: Product
  export let oneLine = false
  export let hideLabel = false

  const packingUnit = (product: Product) => {
    return unique(compact([product.minimum_quantity, product.industry_quantity]), (x, y) => x === y).join('/')
  }
</script>
