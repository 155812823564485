<div class="status-cell">
  {#if row.deactivated}
    <Tag size="sm" type="cool-gray">Deaktiviert</Tag>
  {/if}
  {#if row.owner}
    <Tag size="sm" type="teal">Superuser</Tag>
  {/if}
  {#if row.net_price}
    <Tag size="sm" type="cyan">Nettopreise</Tag>
  {/if}
  {#if row.print}
    <Tag size="sm" type="gray">Print</Tag>
  {/if}
  {#if row.language}
    <Tag size="sm" type="warm-gray">{languages[row.language][0]}</Tag>
  {/if}
</div>

<script>
  import { Tag } from 'carbon-components-svelte'
  import languages from '../data/customer_languages.json'

  export let row
</script>

<style lang="scss" global>
  .status-cell {
    .bx--tag {
      white-space: nowrap;

      &:first-child {
        margin-left: 0;
      }
    }
  }
</style>
