<script lang="ts">
  import { onMount } from 'svelte'
  import { getCategories } from '../../persistence'
  import { db } from '../../db'
  import { imagesCache } from '../../caches'
  import { syncCount } from '../../stores'
  import { dataFetch, options } from '../../api'

  const disableImagePrefetch = import.meta.env.VITE_DISABLE_IMAGE_PREFETCH === 'true'

  const fetchCategories = async () => {
    const latest = await db.categories.orderBy('updated_at').reverse().first()
    const updatedSince = latest?.updated_at ? new Date(latest.updated_at) : undefined
    await fetchCategoriesSince(1, updatedSince)
  }

  const fetchCategoriesSince = async (page, updatedSince) => {
    const params = {}
    if (updatedSince) {
      params.updated_since = updatedSince.toISOString()
    } else {
      params.updated_since = new Date(0).toISOString()
    }

    const { categories = [], pagination = {}, error } = await getCategories(page, params, true)

    if (!error) {
      await db.categories.bulkPut(categories)

      if (pagination.next) {
        await fetchCategoriesSince(pagination.next, updatedSince)
      }
    }
  }

  const fetchCategoryImages = async () => {
    if (disableImagePrefetch) return

    const cache = await imagesCache()

    const categories = await db.categories.toArray()
    for (const category of categories) {
      if (category.image_url) {
        const response = await cache.match(category.image_url)
        if (!response) {
          await dataFetch(category.image_url, { showErrorMessage: false, fetchOptions: options(false, false) })
        }
      }
    }
  }

  onMount(async () => {
    try {
      syncCount.update(count => count + 1)
      await fetchCategories()
      await fetchCategoryImages()
    } finally {
      syncCount.update(count => count - 1)
    }
  })
</script>
