<div class="multi-line-combo-box wide-menu-combo-box">
  <ComboBox
    name="Artikelnummer"
    {items}
    bind:selectedId
    bind:value
    bind:open
    on:focus={focus}
    on:blur={blur}
    let:item
    {tabindex}
    autofocus={(tabindex === 1 && !product?.sku) || undefined}
  >
    <ProductComboBoxItem product={getProductFromItem(item)} {locale} />
  </ComboBox>
</div>

<script lang="ts">
  import { onMount, createEventDispatcher } from 'svelte'
  import { ComboBox } from 'carbon-components-svelte'
  import { getProducts } from '../../persistence'
  import ProductComboBoxItem from './ComboBoxItem.svelte'
  import { unique } from '../../array'
  import { isCustom } from '../../products/product'
  import type { Product } from '../../models'

  export let product: Product
  export let locale
  export let tabindex
  export let shop

  export async function refresh() {
    value = product?.sku
  }

  const dispatch = createEventDispatcher()

  let items
  let products: Product[] = []
  let selectedId
  let value
  let open = false

  onMount(refresh)

  const setCustomItem = () => {
    if (value) {
      products = products.filter(product => !isCustom(product))

      const existingProduct = products.find(
        product => !isCustom(product) && product.sku.toUpperCase() === value.toUpperCase()
      )

      if (existingProduct) {
        selectedId = existingProduct.sku
      } else {
        products.unshift({ sku: value, name: value, minimum_quantity: 1 })
        // get the actual product's sku in case there's a case insensitive match.
        // if the input has a different case than the product, the ComboBox
        // doesn't mark it as the selected item, and tabbing out of the ComboBox clears the value
        selectedId = value
      }
    }

    // remove the custom entry if a proper product already exists
    products = unique(products.reverse(), (x, y) => x.sku.toUpperCase() === y.sku.toUpperCase()).reverse()

    items = products.map(product => ({ id: product.sku, text: product.sku }))
  }

  const setItems = async () => {
    if (value) {
      // async fetch the products, such that the custom input from the user is
      // accepted as is without delay, and won't be overwritten by the products
      // collection.
      fetchProducts()
    } else {
      products = []
    }

    setCustomItem()
  }

  const focus = () => {
    if (selectedId === undefined) {
      open = true
    }
  }

  const blur = () => {
    if (!open) {
      dispatch('change', { product: getProductFromItem({ id: selectedId }) })
    }
  }

  const getProductFromItem = item => {
    const product = products.find(product => product.sku === item.id)
    return product
  }

  const fetchProducts = async () => {
    let error
    ;({ products, error } = await getProducts(0, value, { locale, shop: shop, per: 10 }))
    if (error) {
      products = []
    } else {
      setCustomItem()
    }
  }

  $: {
    if (value || selectedId) setItems()
  }
</script>

<style lang="scss">
  :global {
    .bx--list-box__selection {
      display: none;
    }
  }
</style>
