{#if loaded}
  <Toggle bind:toggled on:change {...$$restProps} />
{:else}
  <ToggleSkeleton {width} />
{/if}

<script lang="ts">
  import { Toggle, ToggleSkeleton } from 'carbon-components-svelte'

  export let toggled = false
  export let loaded = false
  export let width = '100%'
</script>
