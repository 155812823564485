{#if $online && hasRole($currentUser, UserRole.rep)}
  <Customers />
  <Products />
  <DeliveryOptions />
  <Categories />
  <Categorizations />
{/if}

<script lang="ts">
  import { online, currentUser } from '../stores'
  import { hasRole } from '../users/user'
  import { UserRole } from '../models'
  import Customers from './offline_loader/Customers.svelte'
  import Products from './offline_loader/Products.svelte'
  import DeliveryOptions from './offline_loader/DeliveryOptions.svelte'
  import Categories from './offline_loader/Categories.svelte'
  import Categorizations from './offline_loader/Categorizations.svelte'
</script>
