{#if balance?.value < 0}
  <p style="color: green;">{balance.localized}</p>
{:else if balance?.value > 0}
  <p style="color: red;">{balance.localized}</p>
{:else}
  <p>{formatValue(balance?.localized)}</p>
{/if}

<script lang="ts">
  import { formatValue } from '../format'

  export let balance
</script>
