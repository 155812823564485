<FormGroup legendText={$$props.labelText}>
  {#if loaded}
    <RadioButtonGroup bind:selected {...$$restProps}>
      <slot />
    </RadioButtonGroup>
  {:else}
    <SkeletonText {width} />
    <SkeletonText {width} />
  {/if}
</FormGroup>

<script lang="ts">
  import { RadioButtonGroup, SkeletonText, FormGroup } from 'carbon-components-svelte'

  export let selected: string = undefined
  export let loaded = false
  export let width = '20%'
</script>
