<Select
  labelText="Sprache"
  name="language"
  selected={customer.language}
  invalid={errors?.language !== undefined}
  invalidText={errors?.language}
  loaded
  on:input
>
  <SelectItem />
  {#each Object.entries(languages) as [key, value]}
    <SelectItem value={key} text={value} />
  {/each}
</Select>

<script lang="ts">
  import type { AttrErrors, Customer } from '../models'
  import { SelectItem } from 'carbon-components-svelte'
  import Select from '../form/Select.svelte'
  import languages from '../data/customer_languages.json'

  export let customer: Customer
  export let errors: AttrErrors
</script>
