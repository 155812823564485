<div class="mb-10 condensed">
  {#if users}
    <DataTable title="Ansprechpersonen" {headers} rows={users} class="overflow-bottom" data-user-table>
      <Toolbar>
        <ToolbarContent>
          <Button icon={Add} on:click={() => dispatch('addUser')}>Ansprechperson hinzufügen</Button>
        </ToolbarContent>
      </Toolbar>
      <span slot="cell" class:flex-end={cell.key === 'edit'} let:cell let:row>
        {#if cell.key === 'edit'}
          <button
            on:click={() => dispatch('editUser', row)}
            class="bx--overflow-menu inline-flex"
            aria-label="edit-{row.id}"
            title="Bearbeiten"
          >
            <Edit />
          </button>
          <OverflowMenu flipped label="Weitere Optionen" iconDescription="Weitere Optionen" class="inline-flex">
            <OverflowMenuItem
              text="Passwort-Zurücksetzen-Link via E-Mail versenden"
              disabled={!row.email}
              on:click={() => dispatch('resetPassword', row)}
            />
            <OverflowMenuItem
              text="Passwort ändern"
              disabled={!row.email}
              on:click={() => (changePasswordUser = row)}
            />
            <OverflowMenuItem
              text="Benutzer imitieren (Shop anzeigen)"
              disabled={row.deactivated}
              target="_blank"
              on:click={() => impersonateUser(row)}
            />
            <OverflowMenuItem danger text="Löschen" on:click={() => dispatch('deleteUser', row)} />
          </OverflowMenu>
        {:else if cell.key === 'name/role'}
          <span class="no-break">{row.full_name || ''}</span><br />
          <span class="no-break">{row.role || ''}</span>
        {:else if cell.key === 'phone'}
          <PhoneCell {row} />
        {:else if cell.key === 'email'}
          <EmailCell value={cell.value} />
        {:else if cell.key === 'owner'}
          <StatusCell {row} />
        {:else}
          <InfoCell value={cell.value} />
        {/if}
      </span>
    </DataTable>

    {#if changePasswordUser}
      <PasswordModal user={changePasswordUser} on:success />
    {/if}
  {:else}
    <DataTableSkeleton rows={3} {headers} />
  {/if}
</div>

<script lang="ts">
  import type { Customer, User } from '../models'
  import { erpUrl } from '../urls'
  import { Add, Edit } from 'carbon-icons-svelte'
  import {
    Button,
    DataTable,
    DataTableSkeleton,
    Toolbar,
    ToolbarContent,
    OverflowMenu,
    OverflowMenuItem,
  } from 'carbon-components-svelte'
  import InfoCell from './InfoCell.svelte'
  import { createEventDispatcher } from 'svelte'
  import PasswordModal from './PasswordModal.svelte'
  import PhoneCell from './PhoneCell.svelte'
  import EmailCell from './EmailCell.svelte'
  import StatusCell from './StatusCell.svelte'

  export let customer: Customer
  export let users: User[]

  const dispatch = createEventDispatcher()

  let changePasswordUser: User

  function impersonateUser(row) {
    const url = erpUrl(`/customers/${customer.id}/users/${row.id}/impersonate`)
    window.open(url, '_blank')
  }

  const headers = [
    { key: 'name/role', value: 'Name / Funktion' },
    { key: 'phone', value: 'Telefon' },
    { key: 'email', value: 'E-Mail' },
    { key: 'owner', value: 'Status' },
    { key: 'edit', empty: true },
  ]
</script>
