<div class="button-right" data-delivery-addresses>
  <h4 class="mb-06">Lieferadressen</h4>
  <Notification {error} />
  <Button
    kind="tertiary"
    size="field"
    class="button-right--btn"
    on:click={() => editAddress({ country: 'CH' })}
    icon={Add}
  >
    Lieferadresse hinzufügen
  </Button>
  <Row narrow>
    {#each deliveryAddresses as deliveryAddress, index}
      <Column md={4} lg={8} xlg={8} class={index < deliveryAddresses.length - 1 ? 'mb-05' : 'mb-10'}>
        <ClickableTile on:click={() => editAddress({ ...deliveryAddresses[index] })}>
          <p>{deliveryAddress.name}</p>
          <p>{deliveryAddress.industry || ''}</p>
          <p>{deliveryAddress.street || ''}</p>
          <p>{deliveryAddress.postbox || ''}</p>
          <p>{deliveryAddress.zip_code} {deliveryAddress.city}</p>
          <p>{countryNameForDisplay(deliveryAddress.country)}</p>
        </ClickableTile>
      </Column>
    {/each}
  </Row>
</div>

<Modal
  {open}
  deliveryAddress={currentDeliveryAddress}
  on:success={() => dispatch('reloadDeliveryAddresses')}
  on:close={() => {
    openExternally = false
    open = false
  }}
/>

<script lang="ts">
  import type { DeliveryAddress } from '../../models'
  import { Row, Column, ClickableTile, Button } from 'carbon-components-svelte'
  import Notification from '../../shared/Notification.svelte'
  import { Add } from 'carbon-icons-svelte'
  import Modal from './Modal.svelte'
  import { createEventDispatcher } from 'svelte'
  import { countryNameForDisplay } from '../../country'

  export let deliveryAddresses: DeliveryAddress[]
  export let openExternally = false

  let error: string
  let currentDeliveryAddress: DeliveryAddress
  let open: boolean

  const dispatch = createEventDispatcher()

  const editAddress = address => {
    currentDeliveryAddress = address
    open = true
  }

  $: if (openExternally) {
    currentDeliveryAddress = { ...deliveryAddresses[0] }
    open = true
  }
</script>
