// This is not yet testable using Jest due to Svelte parsing errors
import { push, location, querystring, replace } from 'svelte-spa-router'
import { get } from 'svelte/store'
import { truthy, updateQuerystring } from './helper'
import { compact } from './array'

export function getQueryParam(name) {
  const params = new URLSearchParams(get(querystring))
  return params.get(name)
}

// Set in public/env.js as uppercase or use kebab case query parameter:
// http://localhost:5000/#/customers/1000000003?feature-customer=1
export function feature(name: string): boolean {
  const param = getQueryParam(`feature_${name}`)
  const env = window[`FEATURE_${name.toUpperCase()}`]

  if (param != undefined) return truthy(param)
  return env
}

export function visit(params, enterHistory = true) {
  const query = updateQuerystring(params, get(querystring))
  const url = compact([get(location), query]).join('?')
  enterHistory ? push(url) : replace(url)
}
