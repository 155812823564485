<Row class="mb-06 no-large-margin">
  <Column>
    <h6 class:inline={true}>{headline}</h6>
    <span> · </span>
    {#if !note && editable}
      <Link href="#" class="pointer" on:click={openModal}>Hinzufügen</Link>
    {/if}
    {#if note}
      {#if editable}
        <Link href="#" class="pointer" on:click={openModal}>Bearbeiten</Link>
      {/if}
      {#if key === 'important_note'}
        <Notification warning={note} class="side-bar-note" />
      {:else}
        <p class="side-bar-note">{note}</p>
      {/if}
    {/if}
  </Column>
</Row>

<Modal bind:open {noteObject} isNew={!note} on:success={() => dispatch('change')} />

<script lang="ts">
  import { Row, Column, Link } from 'carbon-components-svelte'
  import { createEventDispatcher } from 'svelte'
  import Notification from '../../shared/Notification.svelte'
  import type { Note } from '../../models'
  import Modal from './Modal.svelte'

  export let noteObject: Note
  export let editable: boolean

  let open = false

  const dispatch = createEventDispatcher()

  const openModal = event => {
    event.preventDefault()
    open = true
  }

  $: ({ key, headline, note } = noteObject)
</script>

<style lang="scss" global>
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/_breakpoint';
  .no-large-margin:first-of-type {
    @include breakpoint-between('lg', 'xlg') {
      margin-top: 0;
    }
  }
  .side-bar-note {
    white-space: pre-line;
    max-height: 150px;
    overflow-y: scroll;
  }
</style>
