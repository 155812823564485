<Modal
  {open}
  on:submit={isNew ? create : update}
  on:close={clear}
  {modalHeading}
  hasForm
  {primaryButtonText}
  {secondaryButtons}
  on:click:button--secondary={({ detail }) => {
    if (detail.text === deleteLabel) confirm()
    if (detail.text === cancelLabel) clear()
  }}
  {...$$restProps}
>
  <Notification {error} />
  {#if model}
    <svelte:component this={form} {...{ [modelName]: model }} {errors} loaded on:change={changeModel} />
  {/if}
</Modal>

<Confirm {humanModelName} {humanModelNamePronoun} bind:open={confirmOpen} on:confirm={remove} />

<script lang="ts">
  import type { AttrErrors } from '../../models'
  import Modal from './Modal.svelte'
  import { createEventDispatcher } from 'svelte'
  import Notification from './Notification.svelte'
  import Confirm from './Confirm.svelte'
  import { extractData } from '../event'
  import * as persistence from '../persistence'
  import { capitalize } from '../inflect'

  export let model
  export let modelName
  export let humanModelName
  export let humanModelNamePronoun
  export let form
  export let open: boolean

  // in case the button needs to show something other than "Hinzufügen" or
  // "Speichern" a function can be provided
  // @param {boolean} create Whether the action is "create" or not
  // @param {any} model
  export let primaryButtonTextSetter = create => {
    return create ? 'Hinzufügen' : 'Speichern'
  }

  // these actions will be inferred from the `modelName`.
  // 1. they may be overwritten by providing a function.
  // 2. if the value is set to `false` the action won't be available.
  export let createAction
  export let updateAction
  export let deleteAction

  let errors: AttrErrors = {}
  let error: string
  let confirmOpen = false
  let isNew
  let modalHeading
  let primaryButtonText
  let secondaryButtons = []

  const deleteLabel = 'Löschen'
  const cancelLabel = 'Abbrechen'
  $: {
    isNew = !model?.id

    primaryButtonText = primaryButtonTextSetter(isNew, model)
    if (isNew) {
      modalHeading = `${humanModelName} hinzufügen`
      secondaryButtons = []
    } else {
      modalHeading = `${humanModelName} ändern`
      if (deleteAction) secondaryButtons = [{ text: deleteLabel }]
      else secondaryButtons = []
    }
    secondaryButtons.push({ text: cancelLabel })
  }

  $: if (modelName) {
    const capitalizedModelName = capitalize(modelName)
    if (createAction === undefined) createAction = persistence[`create${capitalizedModelName}`]
    if (updateAction === undefined) updateAction = persistence[`update${capitalizedModelName}`]
    if (deleteAction === undefined) deleteAction = persistence[`delete${capitalizedModelName}`]
  }

  const dispatch = createEventDispatcher()

  const clear = () => {
    model = undefined
    errors = {}
    error = undefined
    open = false
    dispatch('close')
  }

  const confirm = () => {
    if (!isNew) confirmOpen = true
  }

  const remove = async () => {
    ;({ error } = await deleteAction(model.id))
    if (!error) {
      dispatch('success', { [modelName]: model, action: 'delete' })
      clear()
    }
  }

  const update = async () => {
    ;({ errors = {}, error } = await updateAction(model))
    if (!error) {
      dispatch('success', { [modelName]: model, action: 'update' })
      clear()
    }
  }

  const create = async () => {
    ;({ errors = {}, error } = await createAction(model))
    if (!error) {
      dispatch('success', { [modelName]: model, action: 'create' })
      clear()
    }
  }

  const changeModel = event => {
    model = { ...model, ...extractData(event)[modelName] }
  }
</script>

<style lang="scss" global>
  @import 'carbon-components/scss/components/button/mixins';

  .bx--modal-footer--three-button {
    .bx--btn--secondary:not(:only-child):first-child {
      margin-right: auto; // Align left

      // Copied from carbon-components/scss/components/button/_button.scss
      @include button-theme(transparent, transparent, $danger-02, $hover-danger, currentColor, $active-danger);

      padding: $button-padding-ghost;

      &:hover,
      &:active {
        color: $text-04;
      }
    }
  }
</style>
