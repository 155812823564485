<FormModal
  model={deliveryAddress}
  modelName="deliveryAddress"
  humanModelName="Lieferadresse"
  humanModelNamePronoun="die"
  {open}
  form={Form}
  data-delivery-address-modal
  on:success
  on:close
/>

<script lang="ts">
  import type { DeliveryAddress } from '../../models'
  import FormModal from '../../shared/FormModal.svelte'
  import Form from './Form.svelte'

  export let deliveryAddress: DeliveryAddress
  export let open: boolean
</script>
