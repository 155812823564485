<HeaderAction aria-label="Online status" {title} class="bx--header__action--status-indicator" bind:isOpen>
  <svelte:fragment slot="icon">
    <svelte:component this={icon} size={20} />
    {#if count}
      <Tag type="teal" size="sm" aria-label="Pendente Aufträge">{count}</Tag>
    {/if}
  </svelte:fragment>

  <div class="bx--switcher--dynamic-height">
    <HeaderPanelLinks>
      {#if count}
        <HeaderPanelDivider>{count} Aufträge nicht übermittelt</HeaderPanelDivider>
      {/if}
      {#if $draftAdjusted.length}
        {#each $draftAdjusted as order}
          <OrderLink {order} on:click={() => (isOpen = false)}>
            <InlineNotification hideCloseButton subtitle="Die Menge wurde aufgerundet" kind="warning-alt" />
          </OrderLink>
        {/each}
      {/if}
      {#if $pending.length}
        {#each $pending as order}
          <OrderLink {order} on:click={() => (isOpen = false)} />
        {/each}
      {/if}
    </HeaderPanelLinks>
  </div>
</HeaderAction>

<script lang="ts">
  import { HeaderAction, HeaderPanelDivider, HeaderPanelLinks, InlineNotification, Tag } from 'carbon-components-svelte'
  import OrderLink from './sync_status_indicator/OrderLink.svelte'
  import { Cloud, CloudOffline, NetworkOverlay } from 'carbon-icons-svelte'
  import { liveQuery } from 'dexie'
  import { online, syncCount } from '../stores'
  import { db } from '../db'
  import { OrderState } from '../models'

  let isOpen = false

  let icon
  let title
  $: {
    if ($online && $syncCount > 0) {
      icon = NetworkOverlay
      title = 'Offline-Daten werden aktualisiert'
    } else if ($online) {
      icon = Cloud
      title = 'Du bist online'
    } else {
      icon = CloudOffline
      title = 'Du bist momentan offline'
    }
  }

  const draftAdjusted = liveQuery(() => db.orders.where('state').equals(OrderState.draftAdjusted).toArray())
  const pending = liveQuery(() => db.orders.where('state').equals(OrderState.pending).toArray())

  let count
  $: {
    count = ($draftAdjusted?.length || 0) + ($pending?.length || 0)
  }
</script>
