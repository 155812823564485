<HeaderPanelLink href={link(orderUrl(order))} on:click>
  Auftrag für Kunde {order.customer_id}<br />({order.line_items.length} Artikel)
  <slot />
</HeaderPanelLink>

<script lang="ts">
  import { HeaderPanelLink } from 'carbon-components-svelte'
  import type { Order } from '../../models'
  import { link, orderUrl } from '../../urls'

  export let order: Order
</script>
