{#await customerPromise then { customer }}
  {#if customer}
    <Row class="mb-05">
      <Column lg={10}>
        <Breadcrumb>
          <BreadcrumbItem href={link(customerUrl(customer))}
            >{customer.primary_delivery_address.name} ({customer.id})</BreadcrumbItem
          >
        </Breadcrumb>
        <h3>Katalog</h3>
      </Column>
      <Column>
        <Search
          placeholder="Suchen"
          on:input={debounce(searchProducts, 500)}
          value={searchTerm}
          on:clear={() => (searchTerm = '')}
        />
      </Column>
    </Row>
    <Row condensed>
      <Column>
        <Tabs autoWidth bind:selected={selectedTab}>
          <Tab label="Übersicht" on:click={() => push(getProductUrl(''))} />
          <Tab label="Neuheiten" on:click={() => push(getProductUrl('novelties'))} />
          <Tab label="Aktionen" on:click={() => push(getProductUrl('promotions'))} />
          <svelte:fragment slot="content">
            <TabContent>
              {#if searchTerm}
                {#await getProductsPromise}
                  <DataTableSkeleton showHeader={false} showToolbar={false} />
                {:then { products, pagination }}
                  <ProductList {products} {customer} {pagination} on:changePagination={changePagination} />
                {/await}
              {:else}
                {#await categoryPromise then { categories, category, pagination }}
                  {#if categories?.length > 0}
                    <Grid noGutter narrow>
                      <Row>
                        {#each categories as category}
                          <Category {category} {customer} on:click={() => showCategory(category.uid)} />
                        {/each}
                      </Row>
                    </Grid>
                  {:else if category}
                    <Breadcrumb class="mb-05" data-catalog-breadcrumb>
                      <BreadcrumbItem href={getProductLinkUrl('')}>Katalog</BreadcrumbItem>
                      {#each category.ancestors as category}
                        <BreadcrumbItem href={getProductLinkUrl(category.uid)}
                          >{localizedAttribute(category, 'name', localeFromlanguage(customer.language))}</BreadcrumbItem
                        >
                      {/each}
                      <BreadcrumbItem
                        >{localizedAttribute(category, 'name', localeFromlanguage(customer.language))}</BreadcrumbItem
                      >
                    </Breadcrumb>
                    <Grid noGutter narrow>
                      <Row>
                        {#each category.children as category}
                          <Category {category} {customer} on:click={() => showCategory(category.uid)} />
                        {/each}
                      </Row>
                    </Grid>
                    <ProductList
                      products={category.products}
                      {customer}
                      {pagination}
                      on:changePagination={changeCategoryPagination}
                    />
                  {/if}
                {:catch error}
                  <p style="color: red">{error.message}</p>
                {/await}
              {/if}
            </TabContent>
            {#each Array(2) as i}
              <TabContent>
                {#await getProductsPromise}
                  <DataTableSkeleton showHeader={false} showToolbar={false} />
                {:then { products, pagination }}
                  <ProductList {products} {customer} {pagination} on:changePagination={changePagination} />
                {:catch error}
                  <p style="color: red">{error.message}</p>
                {/await}
              </TabContent>
            {/each}
          </svelte:fragment>
        </Tabs>
      </Column>
    </Row>

    <OrderPreview {customer} />
  {/if}
{/await}

<script lang="ts">
  import {
    Row,
    Grid,
    Column,
    Breadcrumb,
    BreadcrumbItem,
    Search,
    DataTableSkeleton,
    Tabs,
    Tab,
    TabContent,
  } from 'carbon-components-svelte'
  import { push } from 'svelte-spa-router'
  import Category from './Category.svelte'
  import { getCategories, getCategory, getCustomer } from '../persistence'
  import { customerId } from '../stores'
  import ProductList from './ProductList.svelte'
  import { localizedAttribute, localeFromlanguage } from '../i18n'
  import { getProducts } from '../persistence'
  import debounce from 'just-debounce'
  import { customerUrl, link } from '../urls'
  import OrderPreview from '../customers/OrderPreview.svelte'

  export let params = {}
  let searchTerm
  let selectedTab
  let getProductsPromise = { products: [] }
  let categoryPromise = []

  const getProductUrl = id => `${customerUrl(params.customer_id)}/catalog/${id}`
  const getProductLinkUrl = id => link(getProductUrl(id))
  const showCategory = id => push(getProductUrl(id))

  const fetchProducts = async (page, params = {}) => {
    const { customer } = await customerPromise

    getProductsPromise = getProducts(page, searchTerm, {
      locale: localeFromlanguage(customer.language),
      shop: customer.shop,
      ...params,
    })
  }

  const searchProducts = async event => {
    selectedTab = 0
    searchTerm = event.target.value
    fetchProducts()
  }

  const changePagination = async event => {
    const page = event.detail.page + 1

    fetchProducts(page)
  }
  const changeCategoryPagination = async event => {
    const page = event.detail.page + 1

    categoryPromise = getCategory(params.category_id, { products_page: page })
  }

  const loadData = async category_id => {
    searchTerm = ''

    if (category_id === 'novelties') {
      selectedTab = 1
      fetchProducts(0, {
        novelty: true,
        per: 200,
      })
    } else if (category_id === 'promotions') {
      selectedTab = 2
      fetchProducts(0, {
        promotion: true,
        per: 200,
      })
    } else {
      selectedTab = 0
      const {
        customer: { shop },
      } = await customerPromise
      categoryPromise = params.category_id ? getCategory(params.category_id) : getCategories(0, { shop: shop })
    }
  }

  $: $customerId = params.customer_id
  $: customerPromise = $customerId && getCustomer()
  $: loadData(params.category_id)
</script>
