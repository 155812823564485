import { get } from 'svelte/store'
import { extractData } from './event'
import { dirty } from './stores'

export const enable = () => {
  enabled = true
  window.addEventListener('beforeunload', beforeunloadListener)
}

export const disable = () => {
  enabled = false
  window.removeEventListener('beforeunload', beforeunloadListener)
}

export const focus = (event, name) => {
  if (!enabled) return

  if (values[name] === undefined) {
    const value = extractData(event)[name]
    values[name] = { original: value, updated: value }
  }
}

export const input = (event, name) => {
  if (!enabled) return

  values[name].updated = extractData(event)
  updateStore()
}

export const clear = name => {
  if (!enabled) return

  delete values[name]
  updateStore()
}

let values = {}
let enabled = false

const updateStore = () => {
  const isDirty = Object.keys(values).some(key => values[key].original !== values[key].updated)
  dirty.set(isDirty)
}

const beforeunloadListener = e => {
  if (get(dirty)) {
    e.preventDefault()
    // chrome requires returnValue to be set
    e.returnValue = ''
  } else {
    // the absence of a returnValue property on the event will guarantee the
    // browser unload happens
    delete e['returnValue']

    values = {}
  }
}
