<Modal
  bind:open
  on:submit={confirm}
  on:close={cancel}
  on:click:button--secondary={cancel}
  size="sm"
  id="confirm"
  modalHeading={`${humanModelName} löschen?`}
  danger
  primaryButtonText="Löschen"
  secondaryButtonText="Abbrechen"
>
  <p>Soll {humanModelNamePronoun} {humanModelName} wirklich gelöscht werden?</p>
</Modal>

<script lang="ts">
  import Modal from './Modal.svelte'
  import { createEventDispatcher } from 'svelte'

  export let open: boolean
  export let humanModelName
  export let humanModelNamePronoun

  const dispatch = createEventDispatcher()

  const confirm = () => {
    open = false
    dispatch('confirm')
  }

  const cancel = () => {
    open = false
    dispatch('cancel')
  }
</script>
