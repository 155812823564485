<Select
  loaded={loaded(customer)}
  labelText="Ausdruck"
  name="prints"
  selected={customer.prints}
  on:input
  invalid={errors.prints !== undefined}
  invalidText={errors.prints}
>
  <SelectItem />
  {#each Object.entries(prints) as [key, value]}
    <SelectItem value={key} text={value} />
  {/each}
</Select>

<script lang="ts">
  import type { Customer, AttrErrors } from '../../models'
  import Select from '../../form/Select.svelte'
  import { SelectItem } from 'carbon-components-svelte'
  import { loaded } from '../../helper'
  import prints from '../../data/customer_prints.json'

  export let customer: Customer
  export let errors: AttrErrors
</script>
