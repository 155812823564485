<h4 class="mb-07">Logistik</h4>
<Row class="mb-10">
  <Column class="mb-07" md={4}>
    <DeliveryOption model={customer} label="Bevorzugte Lieferart" on:input={publish} />
  </Column>
  <Column class="mb-07" md={4}>
    <Prints {customer} {errors} on:input={publish} />
  </Column>
  <Column class="mb-07" md={4}>
    <DeliveryCostSetting {customer} {errors} on:input={publish} />
  </Column>
  <Column class="mb-07" md={4}>
    <TextInput
      labelText="Portofrei ab CHF"
      name="free_delivery_from"
      value={customer.free_delivery_from}
      invalid={errors.free_delivery_from !== undefined}
      invalidText={errors.free_delivery_from}
      loaded={loaded(customer)}
      on:change={publish}
    />
  </Column>
  <Column class="mb-07" md={4}>
    <TextInput
      labelText="EORI Nr."
      name="eori"
      value={customer.eori}
      invalid={errors.eori !== undefined}
      invalidText={errors.eori}
      loaded={loaded(customer)}
      on:change={publish}
    />
  </Column>
  <Column class="mb-07" md={4}>
    <Export {customer} {errors} on:input={publish} />
  </Column>
  <Column>
    <Dispobox {customer} on:change={publish} />
  </Column>
</Row>

<script lang="ts">
  import type { Customer, AttrErrors } from '../models'
  import { Row, Column } from 'carbon-components-svelte'
  import TextInput from '../form/model/TextInput.svelte'
  import DeliveryCostSetting from './logistics/DeliveryCostSetting.svelte'
  import Export from './logistics/Export.svelte'
  import Prints from './logistics/Prints.svelte'
  import Dispobox from './logistics/Dispobox.svelte'
  import DeliveryOption from './logistics/DeliveryOption.svelte'
  import { createEventDispatcher } from 'svelte'
  import { extractData } from '../event'
  import { loaded } from '../helper'

  export let customer: Customer
  export let errors: AttrErrors

  let dispatch = createEventDispatcher()

  const publish = event => {
    dispatch('change', { customer: { id: customer.id, ...extractData(event) } })
  }
</script>
