<Row class="mb-07">
  <Column>
    <TextInput {model} name="name" labelText="Name" {errors} on:change {loaded} />
  </Column>
</Row>
<Row class="mb-07">
  <Column>
    <TextInput {model} name="industry" labelText="Zusatz" {errors} on:change {loaded} />
  </Column>
</Row>
<Row class="mb-07">
  <Column>
    <TextInput {model} name="street" labelText="Strasse" {errors} on:change {loaded} />
  </Column>
</Row>
<Row class="mb-07">
  <Column>
    <TextInput {model} name="postbox" labelText="Postfach" {errors} on:change {loaded} />
  </Column>
</Row>
<ZipCodeAndCity {model} {errors} on:change {loaded} />
<Row class="mb-07">
  <Column>
    <CountrySelect selected={model.country} name="country" labelText="Land" {errors} on:change {loaded} />
  </Column>
</Row>

<script lang="ts">
  import type { AttrErrors } from '../../models'
  import { Row, Column } from 'carbon-components-svelte'
  import TextInput from '../../form/model/TextInput.svelte'
  import ZipCodeAndCity from './ZipCodeAndCity.svelte'
  import CountrySelect from '../../form/CountrySelect.svelte'

  export let model = {}
  export let errors: AttrErrors = {}
  export let loaded
</script>
