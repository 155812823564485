<h4 class="mb-07">Shop</h4>
<Row>
  <Column class="mb-07" md={4}>
    <Toggle
      loaded={loaded(customer)}
      labelText="Wiederverkaufspartner auf Website"
      labelA="Kein Wiederverkaufspartner"
      labelB="Wiederverkaufspartner"
      name="reseller"
      toggled={customer.reseller}
      on:change={publish}
    />
  </Column>
  <Column class="mb-07" md={4}>
    <Toggle
      loaded={loaded(customer)}
      labelText="Bestellnummer Kunde"
      labelA="Bestellnummer Kunde nicht erforderlich"
      labelB="Bestellnummer Kunde erforderlich"
      name="external_id_required"
      toggled={customer.external_id_required}
      on:change={publish}
    />
  </Column>
</Row>
<Row>
  <Column md={4}>
    <RadioGroup
      selected={customer.cart_type}
      loaded={loaded(customer)}
      labelText="Webshop Warenkorb"
      name="cart_type"
      orientation="vertical"
      data-cart-type
    >
      <RadioButton on:change={publish} id="cart_type_shared" name="cart_type" labelText="Firma" value="shared" />
      <RadioButton on:change={publish} id="cart_type_personal" name="cart_type" labelText="Person" value="personal" />
    </RadioGroup>
  </Column>
  <Column class="mb-07" md={4}>
    <Toggle
      loaded={loaded(customer)}
      labelText="Datenexport für Dropshipping-Kunden"
      labelA="Kein Datenexport"
      labelB="Datenexport erlaubt"
      name="product_export_enabled"
      toggled={customer.product_export_enabled}
      on:change={publish}
    />
  </Column>
</Row>

<script lang="ts">
  import type { Customer } from '../models'
  import { Row, Column, RadioButton } from 'carbon-components-svelte'
  import { loaded } from '../helper'
  import { extractData } from '../event'
  import Toggle from '../form/Toggle.svelte'
  import { createEventDispatcher } from 'svelte'
  import RadioGroup from '../form/RadioGroup.svelte'

  export let customer: Customer

  const dispatch = createEventDispatcher()

  const publish = event => {
    const data = { customer: { id: customer.id, ...extractData(event) } }

    dispatch('change', data)
  }
</script>
