<Row>
  <Column class="mb-05">
    <h3>7-Tages-Liste</h3>
    {#if from && to}
      <div class="pt-03">
        {shortDate(from)} – {shortDate(to)}
        <span class="float-right mr-05">Gesamt {overall_amount}</span>
      </div>
    {/if}
  </Column>
</Row>

{#if $online}
  {#if sevenDays}
    {#if sevenDays.length === 0}
      <Notification info="Es gibt keine Einträge für die letzten 7 Tage" />
    {/if}

    {#each sevenDays as customer}
      <h5 class="mb-02">
        {customer.name} (<Link href={link(customerUrl(customer.id))} style="font-size: 100%;">{customer.id}</Link>), {customer.zip_code}
        {customer.city}
      </h5>

      <Row narrow>
        <Column class="mb-03">
          <DataTable {headers} rows={customer.billing_items} size="compact">
            <svelte:fragment slot="cell-header" let:header>
              {#if header.key === 'total'}
                <div class="right-aligned">
                  {header.value}
                </div>
              {:else}
                {header.value}
              {/if}
            </svelte:fragment>
            <svelte:fragment slot="cell" let:cell let:row>
              {#if cell.key === 'product.image'}
                <Image product={row.product} small />
              {:else if cell.key === 'created_on'}
                {shortDate(cell.value)}
              {:else if cell.key === 'total'}
                <div class="right-aligned">{cell.value}</div>
              {:else}
                <div class="truncate">{formatValue(cell.value, '')}</div>
              {/if}
            </svelte:fragment>
          </DataTable>
        </Column>
      </Row>
      <Row>
        <Column class="mb-03">
          <div class="customer-total">
            <strong style="width: {quantityWidth}; padding-left: 1rem;">Summe</strong>
            <strong class="right-aligned" style="width: {totalWidth}; padding-right: 1rem;">{customer.total}</strong>
          </div>
        </Column>
      </Row>
    {/each}
  {:else if error}
    <Row>
      <Column class="mb-05">
        <Notification {error} />
      </Column>
    </Row>
  {:else}
    <Row>
      <Column class="mb-05">
        <DataTableSkeleton showHeader={false} showToolbar={false} size="compact" />
      </Column>
    </Row>
  {/if}
{:else}
  <NotAvailableOfflineNotification />
{/if}

<script lang="ts">
  import { Column, DataTable, DataTableSkeleton, Link, Row } from 'carbon-components-svelte'
  import Notification from './shared/Notification.svelte'
  import { getSevenDaysList } from './persistence'
  import Image from './orders/products/Image.svelte'
  import { shortDate } from './date'
  import { formatValue } from './format'
  import { customerUrl, link } from './urls'
  import { online } from './stores'
  import NotAvailableOfflineNotification from './shared/NotAvailableOfflineNotification.svelte'

  let sevenDays
  let from
  let to
  let overall_amount
  let error

  const totalWidth = '100px'
  const quantityWidth = '60px'

  const headers = [
    { key: 'product.image', value: '', width: '60px' },
    { key: 'product.sku', value: 'Artikel-Nr.', width: '13%' },
    { key: 'description', value: 'Bezeichnung' },
    { key: 'created_on', value: 'Datum', width: '100px' },
    { key: 'extra', value: 'Zusatztext' },
    { key: 'quantity', value: 'Menge', width: quantityWidth },
    { key: 'total', value: 'Total', width: totalWidth },
  ]

  const fetchData = async () => {
    ;({ sevenDays, from, to, overall_amount, error } = await getSevenDaysList())
  }
  fetchData()
</script>

<style lang="scss">
  @import 'carbon-components/scss/globals/scss/_typography';

  .customer-total {
    display: flex;
    justify-content: flex-end;
  }
</style>
