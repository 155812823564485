{#if url}
  <img src={url} class:v-center={verticallyCentered} class:small alt="Produktbild" />
{/if}

<script lang="ts">
  import { productImageUrl } from '../../cloudinary'
  import type { Product } from '../../models'

  export let product: Product
  export let number = undefined
  export let verticallyCentered = false
  export let small = false

  let url

  $: if (product) setUrl()

  const setUrl = async () => {
    url = productImageUrl(product, number)
  }
</script>

<style lang="scss">
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/_inlined/_styles';

  .small {
    max-height: 1em * map-get($map: $body-short-01, $key: line-height);
    display: block;
  }
</style>
