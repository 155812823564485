<Modal
  open={user != undefined}
  on:close={clear}
  on:click:button--secondary={clear}
  on:submit={changePassword}
  modalHeading="Passwort ändern"
  hasForm
  primaryButtonText="Speichern"
  secondaryButtonText="Abbrechen"
>
  <Form>
    <Notification {error} />
    <p class="mb-05">Ansprechperson: {user?.full_name} – {user?.email}</p>
    <PasswordInput
      bind:ref={inputElement}
      size="xl"
      required
      labelText="Neues Passwort"
      autocomplete="off"
      type="text"
      tooltipAlignment="start"
      tooltipPosition="left"
      bind:value={new_password}
      invalid={errors.password !== undefined}
      invalidText={errors.password}
    />
  </Form>
</Modal>

<script lang="ts">
  import type { AttrErrors } from '../models'
  import { Form, PasswordInput } from 'carbon-components-svelte'
  import Modal from '../shared/Modal.svelte'
  import Notification from '../shared/Notification.svelte'
  import { updatePassword } from '../persistence'
  import { fetchSuccessful } from '../helper'
  import { createEventDispatcher, afterUpdate } from 'svelte'

  export let user

  let new_password: ''
  let errors: AttrErrors = {}
  let error: string
  let inputElement

  const dispatch = createEventDispatcher()

  const clear = () => {
    user = undefined
    error = undefined
    errors = {}
    new_password = ''
  }

  const changePassword = async () => {
    ;({ errors = {}, error } = await updatePassword(user.email, new_password))
    if (fetchSuccessful(error)) {
      dispatch('success', 'Das Passwort wurde geändert — aber nicht versendet.')
      clear()
    }
  }

  afterUpdate(() => {
    setTimeout(() => inputElement.focus(), 200)
  })
</script>
