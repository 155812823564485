import { type Order, Shop } from './models'

export function shopUrl(path = undefined, language = 'de', params = undefined, shop = Shop.hoelzle) {
  const key = `${shop.toUpperCase()}_SHOP_URL`
  const url = window[key] || import.meta.env[`VITE_${key}`]
  return urlFor([url, language, path], params)
}

export function erpUrl(path, params = undefined) {
  const url = window.ERP_URL !== undefined ? window.ERP_URL : import.meta.env.VITE_ERP_URL
  return urlFor([url, path], params)
}

export function customerUrl(customer, tab = undefined, params = {}) {
  if (tab) {
    params = { ...params, tab }
  }

  return urlFor(['customers', customer.id || customer], params)
}

export function catalogUrl(customer) {
  return urlFor([customerUrl(customer), 'catalog'])
}

export function orderUrl(order: Order, customer = undefined) {
  return urlFor([customerUrl(customer || order.customer_id), 'orders', order.id || order])
}

// This prepends '#' to the url. This is needed when using the url as a link for
// `a#href`.
export function link(url) {
  return `#${url}`
}

export function urlFor(url, params = undefined) {
  if (Array.isArray(url)) {
    url = url
      .filter(part => part)
      .map(part => part.toString().replace(/(^\/+)|(\/+$)/g, ''))
      .filter(part => part)
      .join('/')
  }

  const urlParams = new URLSearchParams()

  if (params && Object.keys(params).length) {
    for (const key in params) {
      let values = params[key]
      if (!Array.isArray(values)) {
        values = [values]
      }
      values.forEach(value => {
        urlParams.append(key, value)
      })
    }
  }

  if (!url.startsWith('http')) {
    url = `/${url}`
  }

  if (urlParams.toString()) {
    return `${url}?${urlParams.toString()}`
  } else {
    return url
  }
}
