<Row>
  <Column class="mb-05">
    <h3>Kundenkontakt</h3>
  </Column>
</Row>

<CustomerEngagements {customer} showNew={false} />

<script lang="ts">
  import { Column, Row } from 'carbon-components-svelte'
  import CustomerEngagements from './Table.svelte'

  export let customer = undefined
</script>
