<div class="slide-out-panel">
  <ExpandableTile bind:expanded tileMaxHeight="auto" {...$$restProps}>
    <div slot="above">
      <p>
        <slot name="title" />
      </p>
    </div>
    <slot slot="below" />
  </ExpandableTile>
</div>

<script lang="ts">
  import { ExpandableTile } from 'carbon-components-svelte'
  import { createEventDispatcher } from 'svelte'

  export let expanded: boolean

  const dispatch = createEventDispatcher()
  $: dispatch('toggled', { expanded: expanded })
</script>

<style lang="scss">
  @use 'carbon-components/scss/globals/scss/vendor/@carbon/styles/scss/utilities/button-reset';
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/styles/scss/components/ui-shell/_functions';
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/styles/scss/utilities/_z-index';
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/generated/_spacing';
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/_breakpoint';
  @import 'carbon-components/scss/components/ui-shell/_theme';
  @import '../styles/variables';
  @import 'carbon-components/scss/globals/scss/typography';

  $grid-gutter-half: $carbon--grid-gutter * 0.5;

  .slide-out-panel {
    position: fixed;
    right: $grid-gutter-half;
    bottom: 0;
    width: calc(map-get($grid-breakpoints, 'md', 'width') - ($grid-gutter-half * 2));
    max-height: $slide-out-max-height;
    z-index: z('dropdown');

    :global {
      button,
      button:hover,
      button:focus {
        @include button-reset.reset;

        background-color: $shell-panel-bg-01;
        color: $shell-panel-text-01;
        outline: none;
        min-height: auto;
        padding: $spacing-04;
      }

      .bx--tile {
        &__chevron {
          padding: $spacing-04;
          top: 2px;
          right: 0;
          transform: rotate(180deg);
          svg {
            fill: currentColor;
          }
        }

        &-content__below-the-fold {
          height: 0;
          display: none; /* needed for FF as otherwise closed tile is not minimizing to bottom */
        }

        &--is-expanded {
          .bx--tile-content__below-the-fold {
            height: auto;
            display: block; /* needed for FF */
          }
        }
      }
    }
  }

  @include breakpoint-down('md') {
    .slide-out-panel {
      left: $grid-gutter-half;
      right: $grid-gutter-half;
      width: auto;
    }
  }

  @include breakpoint-down('sm') {
    .slide-out-panel {
      left: 0;
      right: 0;
    }
  }
</style>
