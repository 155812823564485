<script lang="ts">
  import { onMount } from 'svelte'
  import { getCategorizations } from '../../persistence'
  import { db } from '../../db'
  import { syncCount } from '../../stores'

  const fetchCategorizations = async () => {
    const { categorizations = [], error } = await getCategorizations(true)

    if (!error) {
      await db.categorizations.bulkPut(categorizations)
    }
  }

  onMount(async () => {
    try {
      syncCount.update(count => count + 1)
      await fetchCategorizations()
    } finally {
      syncCount.update(count => count - 1)
    }
  })
</script>
