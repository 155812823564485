{#if loaded}
  <TextInput
    {name}
    bind:value
    {labelText}
    {...$$restProps}
    bind:ref
    on:keydown
    on:keyup
    on:focus
    on:blur
    on:input={event => input(event, name)}
    on:focus={event => focus(event, name)}
    on:change={change}
  >
    <slot name="labelText" slot="labelText">
      {labelText}
    </slot>
  </TextInput>
{:else}
  <FormGroup legendText={$$props.labelText}>
    <SkeletonText heading {width} />
  </FormGroup>
{/if}

<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { TextInput, SkeletonText, FormGroup } from 'carbon-components-svelte'
  import { focus, input } from '../dirty'
  import { extractData } from '../event'

  export let name: string
  export let value: string = undefined
  export let loaded = false
  export let width = '100%'
  export let labelText: string
  export let ref = undefined

  const dispatch = createEventDispatcher()

  const change = value => {
    dispatch('change', { [name]: extractData(value) })
  }
</script>
