<!-- svelte-ignore a11y-label-has-associated-control -->
<label class="bx--label">Branche</label>
<span> · </span>
<Link href="#" class="pointer" on:click={openModal}>Bearbeiten</Link>
<UnorderedList>
  {#each customer.sectors || [] as { name }}
    <ListItem>{name}</ListItem>
  {/each}
</UnorderedList>

{#if open}
  <Modal bind:open {customer} on:success={() => dispatch('reload')} />
{/if}

<script lang="ts">
  import type { Customer } from '../../models'
  import { Link, UnorderedList, ListItem } from 'carbon-components-svelte'
  import { createEventDispatcher } from 'svelte'
  import Modal from './Modal.svelte'

  export let customer: Customer

  let open = false

  const dispatch = createEventDispatcher()

  const openModal = event => {
    event.preventDefault()
    open = true
  }
</script>
