<Select
  loaded={loaded(customer)}
  labelText="Creditreform"
  name="solvency_check"
  selected={customer.solvency_check}
  invalid={errors.solvency_check !== undefined}
  invalidText={errors.solvency_check}
  helperText={customer.solvency_checked_at}
  on:input
>
  <SelectItem />
  {#each Object.entries(solvency_checks) as [key, value]}
    <SelectItem value={key} text={value} />
  {/each}
</Select>

<script lang="ts">
  import type { Customer, AttrErrors } from '../../models'
  import Select from '../../form/Select.svelte'
  import { SelectItem } from 'carbon-components-svelte'
  import { loaded } from '../../helper'
  import solvency_checks from '../../data/customer_solvency_checks.json'

  export let customer: Customer
  export let errors: AttrErrors
</script>
