<script lang="ts">
  import { onDestroy } from 'svelte'
  import { liveQuery } from 'dexie'
  import { db } from '../db'
  import { createOrder } from '../persistence'
  import { OrderKind, OrderState } from '../models'
  import { adjust } from '../orders/order'
  import { online } from '../stores'
  import { Level, slog } from '../logging'

  const ordersObservable = liveQuery(() => db.orders.where('state').equals(OrderState.pending).toArray())

  let subscription
  // liveQuery may fire false positives (i.e. more than once for a match), so
  // we need to manually keep track to avoid double submission
  let processingOrders = {}

  $: {
    if ($online) {
      subscribe()
    } else {
      unsubscribe()
    }
  }

  const subscribe = () => {
    subscription = ordersObservable.subscribe({
      next: async result => {
        for (const order of result) {
          const id = order.id

          if (id in processingOrders) continue

          processingOrders[id] = undefined

          let adjustedOrder = await adjust(order)

          if (adjustedOrder.line_items.some(lineItem => lineItem.adjustment)) {
            adjustedOrder.state = OrderState.draftAdjusted
            await db.orders.update(adjustedOrder.id, adjustedOrder)
            slog('order_adjust', { order_offline_id: id })

            delete processingOrders[id]
            continue
          }

          const state = order.kind === OrderKind.quote ? OrderState.quote : OrderState.completed

          const completedOrder = { ...order, state: state }

          const { errors = {}, error, status } = await createOrder(completedOrder)
          slog(
            'order_upload',
            { order_offline_id: id, customer_id: order.customer_id, errors, error, status },
            Level.info
          )
          if (error) {
            if (status === 422 && errors['offline_id'] === 'ist bereits vergeben') {
              await db.orders.update(completedOrder.id, completedOrder)
              slog('order_upload_id_clash', { order_offline_id: id }, Level.warn)
              delete processingOrders[id]
            } else {
              slog('order_upload_failed', { order_offline_id: id, error, errors }, Level.error)
            }
          } else {
            await db.orders.update(completedOrder.id, completedOrder)
            slog('order_uploaded', { order_offline_id: id })
            delete processingOrders[id]
          }
        }
      },
      error: error => slog('order_observe_failed', { error }, Level.warn),
    })
  }

  const unsubscribe = () => {
    if (subscription) subscription.unsubscribe()
  }

  onDestroy(unsubscribe)
</script>
