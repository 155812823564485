<OverflowMenu flipped label="Weitere Optionen" iconDescription="Weitere Optionen" class="inline-flex" tabindex="-1">
  <OnlineOnlyOverflowMenuItem text="Preis anpassen" on:click={() => dispatch('setOneOffPrice')} />
  <OverflowMenuItemWithIcon on:click={() => dispatch('setAlreadyDelivered')}>
    Bereits geliefert
    {#if alreadyDelivered}
      <Checkmark size={16} slot="icon" />
    {/if}
  </OverflowMenuItemWithIcon>
  {#if showDelete}
    <OverflowMenuItem danger text="Artikel entfernen" on:click={() => dispatch('delete')} />
  {/if}
</OverflowMenu>

<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { OverflowMenu, OverflowMenuItem } from 'carbon-components-svelte'
  import { Checkmark } from 'carbon-icons-svelte'
  import OnlineOnlyOverflowMenuItem from '../../../shared/OnlineOnlyOverflowMenuItem.svelte'
  import OverflowMenuItemWithIcon from '../../../shared/OverflowMenuItemWithIcon.svelte'

  export let showDelete
  export let alreadyDelivered

  const dispatch = createEventDispatcher()
</script>
