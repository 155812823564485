<script lang="ts">
  import { onMount } from 'svelte'
  import { getProducts } from '../../persistence'
  import { db } from '../../db'
  import { productImageUrl } from '../../cloudinary'
  import { imagesCache } from '../../caches'
  import { syncCount } from '../../stores'
  import { dataFetch, options } from '../../api'

  const disableImagePrefetch = import.meta.env.VITE_DISABLE_IMAGE_PREFETCH === 'true'

  const fetchProducts = async () => {
    const latestProduct = await db.products.orderBy('updated_at').reverse().first()
    const updatedSince = latestProduct?.updated_at ? new Date(latestProduct.updated_at) : undefined
    await fetchProductsSince(1, updatedSince)
  }

  const fetchProductsSince = async (page, updatedSince) => {
    const params = {}
    if (updatedSince) {
      params.updated_since = updatedSince.toISOString()
    } else {
      params.updated_since = new Date(0).toISOString()
    }

    const { products = [], pagination = {}, error } = await getProducts(page, '', params, true)

    if (!error) {
      await db.products.bulkPut(products)

      if (pagination.next) {
        await fetchProductsSince(pagination.next, updatedSince)
      }
    }
  }

  const fetchProductImages = async () => {
    if (disableImagePrefetch) return

    const cache = await imagesCache()

    const products = await db.products.toArray()
    for (const product of products) {
      const imageUrl = await productImageUrl(product)
      if (imageUrl) {
        const response = await cache.match(imageUrl)
        if (!response) {
          await dataFetch(imageUrl, { showErrorMessage: false, fetchOptions: options(false, false) })
        }
      }
    }
  }

  onMount(async () => {
    try {
      syncCount.update(count => count + 1)
      await fetchProducts()
      await fetchProductImages()
    } finally {
      syncCount.update(count => count - 1)
    }
  })
</script>
