<Tooltip direction="right" class="line-item-tooltip">
  <svelte:fragment slot="icon">
    <WarningAltFilled size={20} />
  </svelte:fragment>
  <p>{text}</p>
</Tooltip>

<script lang="ts">
  import { Tooltip } from 'carbon-components-svelte'
  import { WarningAltFilled } from 'carbon-icons-svelte'

  export let text
</script>
