<Row>
  <Column>
    {#if customer}
      <Breadcrumb>
        <BreadcrumbItem href={link(customerUrl(customer))}
          >{customer.primary_delivery_address.name} ({customer.id})</BreadcrumbItem
        >
        <BreadcrumbItem href={link(customerUrl(customer, 'orders'))}>Aufträge</BreadcrumbItem>
      </Breadcrumb>
    {/if}
  </Column>
</Row>
{#if order}
  <DisabledFields disabled={!isEditable(order)}>
    <Row>
      <Column>
        <Notification {error} />
        {#if order.dangerous_goods?.has_dangerous_goods}
          <Notification warning="Hinweis: Aufgrund von Gefahrgut sind die Lieferarten eingeschränkt." />
        {/if}
        <h3 class="mb-08">
          {#if order.kind === OrderKind.quote}
            Preisauskunft
          {:else}
            Auftrag
          {/if}

          {#if customer}
            <StatusBadge {order} />
            {#if isEditable(order)}
              <OverflowMenu flipped icon={Settings} style="display: inline" aria-label="Auftragstyp">
                {#if order.kind === OrderKind.quote}
                  <OverflowMenuItem text="Auftrag" on:click={() => updateOrderKind('order')} />
                {:else}
                  <OverflowMenuItem text="Preisauskunft" on:click={() => updateOrderKind('quote')} />
                {/if}
              </OverflowMenu>
            {/if}
            <div class="float-right flex-end">
              <Button size="field" kind="ghost" on:click={() => push(customerUrl(customer, 'purchased_products'))}
                >Bezogene Artikel</Button
              >
              <Button size="field" kind="ghost" on:click={() => push(catalogUrl(customer))}>Katalog</Button>

              {#if isDeletable(order)}
                <OverflowMenu
                  flipped
                  label="Weitere Optionen"
                  iconDescription="Weitere Optionen"
                  aria-label="order-actions"
                  class="mr-07"
                >
                  <OverflowMenuItem danger text="Löschen" on:click={() => (confirmDestroy = true)} />
                  <li role="none" class="bx--overflow-menu-options__option">
                    <a
                      href={productLabelsUrl}
                      target="_blank"
                      rel="noreferrer"
                      role="menuitem"
                      tabindex="-1"
                      class="bx--overflow-menu-options__btn"
                    >
                      <div class="bx--overflow-menu-options__option-content">Etiketten drucken ↗︎</div>
                    </a>
                  </li>
                </OverflowMenu>
              {/if}
              {#if isEditable(order)}
                <Button size="field" disabled={isBlankWithoutComment} on:click={submitOrder} icon={Checkmark}
                  >{order.kind === OrderKind.quote ? 'Erfassen' : 'Bestellen'}</Button
                >
              {/if}
            </div>
          {/if}
        </h3>
      </Column>
    </Row>

    {#if customer}
      <Row>
        <Column xlg={10} class="flex-reverse-mobile condensed">
          <LineItems {order} {customer} on:change={update} />
        </Column>
        <Column xlg={6}>
          <SideBar
            {customer}
            {meta}
            editable={false}
            choosableDeliveryAddress={deliveryAddresses.length > 1}
            {deliveryAddress}
            showAdressesOnly={true}
            on:chooseDeliveryModal={() => (deliveryAddressPickerOpen = true)}
          >
            <svelte:fragment slot="two-column-content">
              <Row>
                <Column class="mb-06" lg={8} xlg={16}>
                  <Select
                    loaded={loaded(order)}
                    labelText="Bestellperson"
                    name="user_id"
                    selected={order.user_id}
                    on:input={partialUpdate}
                  >
                    <SelectItem text=" " />
                    {#each users || [] as user}
                      <SelectItem value={user.id} text={user.full_name} />
                    {/each}
                    <SelectItem value="other" text="Andere…" />
                  </Select>
                </Column>
              </Row>
              {#if order.user_id === 'other'}
                <Row>
                  <Column class="mb-06" lg={8} xlg={16}>
                    <TextInput
                      model={order}
                      name="buyer_name"
                      labelText="Name der Bestellperson"
                      loaded={loaded(order)}
                      on:change={partialUpdate}
                    />
                  </Column>
                </Row>
              {/if}
              <Row>
                <Column class="mb-06" md={4}>
                  <DeliveryOption
                    model={order}
                    deliveryOptions={order.available_delivery_options}
                    label="Lieferart"
                    on:input={partialUpdateWithAdjust}
                    internal
                  />
                </Column>
                <Column class="mb-06" md={4}>
                  <TextInput
                    model={order}
                    name="external_id"
                    labelText="Bestellnummer Kunde"
                    loaded={loaded(order)}
                    errors={validationErrors}
                    on:change={partialUpdate}
                  />
                </Column>
              </Row>
            </svelte:fragment>
            <svelte:fragment slot="one-column-content">
              <Column lg={8} xlg={16}>
                <div class="mb-06">
                  <TextArea
                    model={order}
                    name="comment"
                    labelText="Bemerkungen intern"
                    loaded={loaded(order)}
                    on:change={partialUpdate}
                  />
                </div>
                <div class="mb-06">
                  <Checkbox
                    checked={order.one_time_invoice_in_package}
                    name="one_time_invoice_in_package"
                    labelText="Einmalig Rechnung ins Paket"
                    on:change={partialUpdate}
                  />
                </div>
                <div class="mb-06">
                  <Checkbox
                    checked={order.voucher_code}
                    name="joker"
                    labelText="Joker"
                    disabled={order.joker_used || customer.joker_used}
                    on:change={changeJoker}
                  />
                </div>

                {#if order.dangerous_goods}
                  <div class="mb-06">
                    <h6>Gefahrgut</h6>
                    <p>
                      {#if order.dangerous_goods.has_dangerous_goods}
                        <Checkmark />
                      {:else}
                        <Close />
                      {/if}
                      ({order.dangerous_goods.human_level})
                    </p>
                  </div>
                  <div class="mb-06">
                    <h6>GG-Punkte</h6>
                    <p>{order.dangerous_goods.points}</p>
                  </div>
                  <div class="mb-06">
                    <h6>Kapazität</h6>
                    <p>{order.dangerous_goods.capacity}</p>
                  </div>
                {/if}
              </Column>
            </svelte:fragment>
          </SideBar>
        </Column>
      </Row>
    {/if}
  </DisabledFields>
{/if}

{#if notFound}
  <Row padding>
    <Column>
      <h3>Auftrag nicht gefunden</h3>
    </Column>
  </Row>
  <Row padding>
    <Column>
      <p>Der Auftrag mit der id '{id}' kann leider nicht gefunden werden.</p>
    </Column>
  </Row>
{/if}

<Modal
  danger
  size="xs"
  bind:open={confirmDestroy}
  modalHeading="Auftrag löschen?"
  primaryButtonText="Löschen"
  secondaryButtonText="Abbrechen"
  on:close={() => (confirmDestroy = false)}
  on:click:button--secondary={() => (confirmDestroy = false)}
  on:submit={destroyOrder}
>
  <p>Auftrag wird unwiederruflich gelöscht.</p>
</Modal>

<Modal
  passiveModal
  bind:open={deliveryAddressPickerOpen}
  modalHeading="Lieferadresse auswählen"
  iconDescription="Schliessen"
  on:close={() => (deliveryAddressPickerOpen = false)}
>
  <TileGroup on:select={changeDeliveryAddress}>
    {#each deliveryAddresses as deliveryAddress}
      <RadioTile light value={deliveryAddress.id} checked={selectedDeliveryAddressId === deliveryAddress.id}>
        {[
          deliveryAddress.name,
          deliveryAddress.industry,
          deliveryAddress.street,
          deliveryAddress.postbox,
          `${deliveryAddress.zip_code} ${deliveryAddress.city}`,
        ]
          .filter(s => s)
          .join(', ')}
      </RadioTile>
    {/each}
  </TileGroup>
</Modal>

<script lang="ts">
  import { onMount } from 'svelte'
  import { push } from 'svelte-spa-router'
  import {
    Column,
    Row,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    OverflowMenu,
    OverflowMenuItem,
    Modal,
    TileGroup,
    RadioTile,
    Select,
    SelectItem,
    Checkbox,
  } from 'carbon-components-svelte'
  import { Checkmark, Close, Settings } from 'carbon-icons-svelte'
  import Notification from '../shared/Notification.svelte'
  import SideBar from '../customers/side_bar/Index.svelte'
  import StatusBadge from './StatusBadge.svelte'
  import LineItems from './LineItems.svelte'
  import DeliveryOption from '../customers/logistics/DeliveryOption.svelte'
  import TextInput from '../form/model/TextInput.svelte'
  import TextArea from '../form/model/TextArea.svelte'
  import DisabledFields from '../shared/DisabledFields.svelte'
  import { db } from '../db'
  import {
    type Order,
    type Customer,
    type DeliveryAddress,
    type Meta,
    OrderKind,
    OrderState,
    type User,
  } from '../models'
  import { customerId } from '../stores'
  import { getCustomer } from '../persistence'
  import { extractData } from '../event'
  import { adjust, isDeletable, isEditable, isEmptyLineItem, rejectEmptyLineItems } from './order'
  import { loaded } from '../helper'
  import { activeUsers } from '../users/user'
  import { getNewLineItem } from '../modelDefaults'
  import { catalogUrl, customerUrl, link } from '../urls'
  import { Level, slog } from '../logging'
  import { diff } from 'deep-object-diff'
  import { shopUrl } from '../urls'
  import { localeFromlanguage } from '../i18n'
  import { isBlank } from '../object'

  export let params: { id?: string; customer_id?: string } = {}

  const id = params.id

  $: $customerId = params.customer_id

  let error: string
  let validationErrors
  let customer: Customer
  let users: User[]
  let order: Order
  let meta: Meta = {}
  let deliveryAddresses: DeliveryAddress[] = []
  let confirmDestroy = false
  let deliveryAddressPickerOpen: boolean
  let selectedDeliveryAddressId: string
  let deliveryAddress = {}
  let notFound = false
  let isBlankWithoutComment = true

  let productLabelsUrl
  $: {
    if (order && customer) {
      const ids = rejectEmptyLineItems(order.line_items).map(line_item => line_item.product.sku)
      productLabelsUrl = shopUrl('product_labels', localeFromlanguage(customer.language), { 'product_ids[]': ids })
    }
    if (order) {
      isBlankWithoutComment = order.line_items.length === 1 && isBlank(order.comment?.trim())
    }
  }

  const goToCustomerPage = () => {
    push(customerUrl(customer, 'orders'))
  }

  const submitOrder = async () => {
    // validation
    if (customer.external_id_required && !order.external_id) {
      validationErrors = { ...validationErrors, external_id: 'muss ausgefüllt werden' }
      error = 'Ein Eingabefehler ist aufgetreten.'
      return
    }

    order.state = OrderState.pending
    order.line_items = rejectEmptyLineItems(order.line_items)
    await persist()

    slog('order_submit', { order_offline_id: id, customer_id: customer.id })

    goToCustomerPage()
  }

  const destroyOrder = async () => {
    confirmDestroy = false

    await db.orders.delete(id)

    slog('order_delete', { order_offline_id: id, customer_id: customer.id })
    goToCustomerPage()
  }

  const update = async event => {
    updateFromOrder(extractData(event))
  }

  const partialUpdate = async event => {
    updateFromOrder(extractData(event))
  }

  const partialUpdateWithAdjust = async event => {
    const updatedOrder = await adjust({ ...order, ...extractData(event) })

    await updateFromOrder(updatedOrder)
  }

  const updateFromOrder = async updatedOrder => {
    order = { ...order, ...updatedOrder }
    ensureEmptyLine()

    await persist()
  }

  const persist = async () => {
    const updatedOrder = {
      ...order,
      // a select option with empty value is not selected when the value is empty
      user_id: order.user_id === 'other' ? undefined : order.user_id,
    }
    const dbOrder = await db.orders.get(order.id)
    await db.orders.update(order.id, updatedOrder)
    slog('order_update', { order_offline_id: order.id, diff: diff(dbOrder, updatedOrder) })
  }

  const changeDeliveryAddress = event => {
    selectedDeliveryAddressId = extractData(event)
    order.delivery_address_id = selectedDeliveryAddressId
    db.orders.update(id, order)
    slog('order_change_delivery_address', { order_offline_id: order.id, order })
    deliveryAddress = deliveryAddresses.find(address => address.id === order.delivery_address_id)
    deliveryAddressPickerOpen = false
  }

  const changeJoker = async event => {
    const { joker } = extractData(event)
    const voucher_code = joker ? new Date().getFullYear() : undefined

    const updatedOrder = await adjust({ ...order, voucher_code })

    await updateFromOrder(updatedOrder)
  }

  const ensureEmptyLine = () => {
    // ensure there is always an empty line ready
    if (order.line_items.length === 0 || !isEmptyLineItem(order.line_items[order.line_items.length - 1])) {
      order.line_items = [...order.line_items, getNewLineItem()]
    }
  }

  const updateOrderKind = async kind => {
    order.kind = kind
    await db.orders.update(order.id, order)
  }

  onMount(async () => {
    order = await db.orders.get(id)

    if (!order) {
      notFound = true
      slog('order_not_found', { order_id: id, customer_id: $customerId }, Level.warn)
      return
    }

    if (isEditable(order)) {
      ensureEmptyLine()
    } else {
      order.line_items = rejectEmptyLineItems(order.line_items)
    }

    ;({ customer, meta, error } = await getCustomer())
    users = activeUsers(customer.users)
    if (order.user_id === undefined) {
      if (order.buyer_name !== undefined) {
        updateFromOrder({ user_id: 'other' })
      } else if (users?.length === 1) {
        updateFromOrder({ user_id: users[0].id })
      }
    }

    deliveryAddresses = customer.delivery_addresses

    selectedDeliveryAddressId = order.delivery_address_id
    deliveryAddress =
      deliveryAddresses.find(address => address.id === order.delivery_address_id) || deliveryAddresses[0]
  })
</script>
