<Select
  loaded={loaded(customer)}
  labelText="Porto & Verpackung"
  name="delivery_cost_setting"
  selected={customer.delivery_cost_setting}
  on:input
  invalid={errors.delivery_cost_setting !== undefined}
  invalidText={errors.delivery_cost_setting}
>
  <SelectItem />
  {#each Object.entries(deliveryCostSettings) as [key, value]}
    <SelectItem value={key} text={value} />
  {/each}
</Select>

<script lang="ts">
  import type { Customer, AttrErrors } from '../../models'
  import Select from '../../form/Select.svelte'
  import { SelectItem } from 'carbon-components-svelte'
  import { loaded } from '../../helper'
  import deliveryCostSettings from '../../data/customer_delivery_cost_settings.json'

  export let customer: Customer
  export let errors: AttrErrors
</script>
