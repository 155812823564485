// The single responsibility of this file is to provide simple, usable interfaces
// to components for retrieving and persisting data.

import { get } from 'svelte/store'
import api from './api'
import type {
  AttrErrors,
  Category,
  City,
  Customer,
  CustomerEngagement,
  CustomerStatistics,
  DeliveryAddress,
  DeliveryOption,
  FlatFee,
  FlatFeeParams,
  Locale,
  Meta,
  Order,
  Pagination,
  Product,
  Rep,
  SectorGroup,
  SevenDay,
  Shop,
  User,
} from './models'
import offlineStore from './offlineStore'
import { online } from './stores'

export async function authenticate(
  username: string,
  password: string
): Promise<{ token?: string | null; error?: string | null }> {
  const { json, error } = await api.postToken(username, password)
  if (json) return { token: json.access_token }
  return { error }
}

export function createDeliveryAddress(
  deliveryAddress: DeliveryAddress
): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.postDeliveryAddress(deliveryAddress)
}

function concreteStore(forceOnline = false) {
  if (get(online) || forceOnline) {
    return api
  } else {
    return offlineStore
  }
}

export async function getCustomers(
  page = 0,
  query = '',
  params: {
    updated_since?: Date | string
    sort_by?: string
    tour?: string
    rep_category?: string
    per?: number
    includeInactive?: boolean
  },
  sequential = false,
  forceOnline = false
): Promise<{
  customers?: Customer[]
  pagination?: Pagination
  total: number
  statistics?: CustomerStatistics
  error?: string
}> {
  const { json, error } = await concreteStore(forceOnline).getCustomers(page, query, params, sequential)
  if (json)
    return {
      customers: json.data,
      pagination: json.meta?.pagination,
      total: json.meta?.total,
      statistics: json.meta?.statistics,
    }
  return { error }
}

export async function getCustomer(): Promise<{
  customer?: Customer
  meta?: Meta
  error?: string
  errors?: AttrErrors
}> {
  const { json, error } = await concreteStore().getCustomer()
  if (json) return { customer: json.data, meta: json.meta, errors: json.errors }
  return { error }
}

export async function getFlatFees(): Promise<FlatFee[]> {
  const { json } = await concreteStore().getFlatFees()
  if (json) {
    return json.data
  }
}

export async function getReps(): Promise<Rep[]> {
  const { json } = await concreteStore().getReps()
  if (json) {
    return json.data
  }
}

export async function getUser(userId: User['id']): Promise<{ user?: User; error?: string }> {
  const { json, error } = await concreteStore().getUser(userId)
  if (json) return { user: json.data }
  return { error }
}

export async function getDeliveryOptions(
  params?: {
    flat_fee?: boolean
    internal?: boolean
  },
  forceOnline = false
): Promise<{ deliveryOptions?: DeliveryOption[]; error?: string }> {
  const { json, error } = await concreteStore(forceOnline).getDeliveryOptions(params)
  if (json) return { deliveryOptions: json.data }
  return { error }
}

export async function getProduct(id: string): Promise<{ product?: Product; error?: string }> {
  const { json, error } = await concreteStore().getProduct(id)
  if (json) return { product: json.data }
  return { error }
}

export async function getProducts(
  page = 0,
  searchTerm?: string,
  params?: { updated_since?: Date | string; locale?: Locale; novelty?: boolean; promotion?: boolean },
  forceOnline = false
): Promise<{ products?: Product[]; pagination?: Pagination; error?: string }> {
  const { json, error } = await concreteStore(forceOnline).getProducts(page, searchTerm, params)
  if (json) return { products: json.data, pagination: json.meta?.pagination }
  return { error }
}

export async function getPurchasedProducts(
  customer: Customer,
  page: number,
  searchTerm?: string,
  sortBy?: string
): Promise<{ purchasedProducts?: PurchasedProduct[]; error?: string; pagination?: Pagination }> {
  const { json, error } = await concreteStore().getPurchasedProducts(customer, page, searchTerm, sortBy)
  if (json) return { purchasedProducts: json.data, pagination: json.meta?.pagination }
  return { error }
}

export async function getPurchasedProductsBillingItems(
  customer: Customer | number,
  productId: string,
  per?: number
): Promise<{ billingItems?: BillingItem[]; error?: string; pagination?: Pagination }> {
  const { json, error } = await concreteStore().getPurchasedProductsBillingItems(customer, productId, per)
  if (json) return { billingItems: json.data }
  return { error }
}

export async function getCategories(
  page = 0,
  params?: { updated_since?: Date; shop?: Shop },
  forceOnline = false
): Promise<{ categories?: Category[]; pagination?: Pagination; error?: string }> {
  const { json, error } = await concreteStore(forceOnline).getCategories(page, params)
  if (json) return { categories: json.data, pagination: json.meta?.pagination }
  return { error }
}

export async function getCategory(
  id: string,
  params?: { products_page?: number }
): Promise<{ category: Category; error?: string }> {
  const { json, error } = await concreteStore().getCategory(id, params)
  if (json) return { category: json.data, pagination: json.meta?.pagination }
  return { error }
}

export async function getCategorizations(
  forceOnline = false
): Promise<{ categorizations?: Categorization[]; error?: string }> {
  const { json, error } = await concreteStore(forceOnline).getCategorizations()
  if (json) return { categorizations: json.data }
  return { error }
}

export async function getCustomerEngagements(
  page: number,
  pastOnly: boolean,
  rep: Rep,
  searchQuery = '',
  customer?: Customer,
  date?: string
): Promise<{ customerEngagements?: CustomerEngagement[]; error?: string; pagination?: Pagination }> {
  const { json, error } = await concreteStore().getCustomerEngagements(page, pastOnly, rep, searchQuery, customer, date)
  if (json) return { customerEngagements: json.data, pagination: json.meta?.pagination }
  return { error }
}
export function createCustomerEngagement(
  customerEngagement: CustomerEngagement
): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.postCustomerEngagement(customerEngagement)
}

export function updateCustomerEngagement(
  customerEngagement: CustomerEngagement
): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.putCustomerEngagement(customerEngagement)
}

export async function deleteCustomerEngagement(id: CustomerEngagement['id']): Promise<{ error?: string }> {
  return api.deleteCustomerEngagement(id)
}

export async function getOrderDryRun(order: Order): Promise<{ order?: Order; error?: string }> {
  const { json, error } = await concreteStore().getOrderDryRun(order)
  if (json) return { order: json.data }
  return { error }
}

export async function getSevenDaysList(): Promise<{
  sevenDays?: SevenDay[]
  from: Date
  to: Date
  overall_amount: number
  error?: string
}> {
  const { json, error } = await concreteStore().getSevenDaysList()
  if (json)
    return { sevenDays: json.data, from: json.meta.from, to: json.meta.to, overall_amount: json.meta.overall_amount }
  return { error }
}

export async function createUser(user: User): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.postUser(user)
}

export async function createOrder(order: Order): Promise<{ errors?: AttrErrors; error?: string; status?: number }> {
  return api.postOrder(order)
}

export function createFlatFee(flatFee: FlatFeeParams): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.postFlatFee(flatFee)
}

export async function createCustomer(
  customer: Customer
): Promise<{ customer?: Customer; errors?: AttrErrors; error?: string }> {
  const { error, errors, json } = await api.postCustomer(customer)
  return { error, errors, customer: json?.data }
}

export function updateCustomer(customer: Customer): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.putCustomer(customer)
}

export async function deleteCustomer(id: Customer['id']): Promise<{ error?: string }> {
  return api.deleteCustomer(id)
}

export function updateDeliveryAddress(
  deliveryAddress: DeliveryAddress
): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.putDeliveryAddress(deliveryAddress)
}

export function updateFlatFee(flatFee: FlatFee): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.putFlatFee(flatFee)
}

export function updateUser(user: User): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.putUser(user)
}

export async function deleteDeliveryAddress(id: DeliveryAddress['id']): Promise<{ error?: string }> {
  return api.deleteDeliveryAddress(id)
}

export async function deleteUser(userId: User['id']): Promise<{ error?: string }> {
  return api.deleteUser(userId)
}

export function updatePassword(
  email: User['email'],
  newPassword: string
): Promise<{ errors?: AttrErrors; error?: string }> {
  return api.putPassword(email, newPassword)
}

export function postPassword(email: User['email']): Promise<{ error?: string }> {
  return api.postPassword(email)
}

export async function getSectorGroups(): Promise<SectorGroup[]> {
  const { json } = await api.getSectorGroups()
  if (json) {
    return json.data
  }
}

export async function getCities(): Promise<City[]> {
  const { json } = await api.getCities()
  if (json) {
    return json.data
  }
}
