{#if loaded(customer)}
  <InvalidCustomer
    errors={validationErrors}
    permittedFields={meta?.permitted_attributes || []}
    {customer}
    on:change={loadCustomer}
  />

  <Heading {customer} {success} {error} on:change={update} />
  <Row>
    <Column xlg={10} class="flex-reverse-mobile">
      <Tabs type="container" class="bx--tabs--condensed" selected={selectedTab}>
        <Tab label="Allgemein" on:click={() => changeTab()} />
        <Tab on:click={() => changeTab('purchased_products')} disabled={!$online}>
          Bezogene Artikel
          {#if !$online}
            <CloudOffline class="v-center" />
          {/if}
        </Tab>
        <Tab label="Kundenkontakt" on:click={() => changeTab('customer_engagements')} disabled={!$online} />
        <Tab on:click={() => changeTab('orders')}>
          Aufträge
          <OrdersTag {customer} />
        </Tab>
        <Tab label="Einstellungen" on:click={() => changeTab('settings')} />
        <svelte:fragment slot="content">
          <TabContent class="bx--tab-content--condensed">
            <DisabledFields disabled={!$online}>
              <Users {customer} on:change={loadCustomer} />
            </DisabledFields>
            <DisabledFields {disabled}>
              <RepAndSector {customer} {meta} {errors} on:change={update} on:reload={loadCustomer} />
              <GeneralContact {customer} {errors} on:change={update} />
              <BillingAddress
                {customer}
                bind:open={billingModalOpen}
                on:updateCustomerObject={event => (customer = event.detail)}
                on:close={() => (billingModalOpen = false)}
              />
              <DeliveryAddresses
                deliveryAddresses={customer.delivery_addresses}
                bind:openExternally={deliveryModalOpen}
                on:reloadDeliveryAddresses={loadCustomer}
              />
              {#if meta?.permitted_associations?.includes('flat_fees') && loaded(customer)}
                <FlatFees {customer} {flatFees} on:reloadFlatFees={loadFlatFees} />
              {/if}
            </DisabledFields>
          </TabContent>
          <TabContent class="bx--tab-content--condensed">
            {#if $online}
              <PurchasedProducts {customer} />
            {:else}
              <NotAvailableOfflineNotification />
            {/if}
          </TabContent>
          <TabContent class="bx--tab-content--condensed">
            {#if $online}
              <CustomerEngagements {customer} />
            {:else}
              <NotAvailableOfflineNotification />
            {/if}
          </TabContent>
          <TabContent class="bx--tab-content--condensed">
            <div class="mb-05 condensed">
              <Orders {customer} />
            </div>
          </TabContent>
          <TabContent class="bx--tab-content--condensed">
            <DisabledFields {disabled}>
              <Logistics {customer} {errors} on:change={update} />
              <Payment {customer} {errors} on:change={update} />
              <Invoice {customer} {errors} on:change={update} />
              <Shop {customer} on:change={update} />
            </DisabledFields>
          </TabContent>
        </svelte:fragment>
      </Tabs>
    </Column>
    <Column xlg={6} class="tabs-margin-top">
      <DisabledFields {disabled}>
        <SideBar
          {customer}
          {meta}
          flatFee={flatFees[0]}
          deliveryAddress={customer.primary_delivery_address}
          on:change={loadCustomer}
        />
      </DisabledFields>
    </Column>
  </Row>

  <OrderPreview {customer} />
{/if}

{#if error === '404'}
  <Row padding>
    <Column>
      <h3>Kunde nicht gefunden</h3>
    </Column>
  </Row>
  <Row padding>
    <Column>
      <p>Der Kunde mit der id '{$customerId}' kann leider nicht gefunden werden.</p>
    </Column>
  </Row>
{/if}

<script lang="ts">
  import { CloudOffline } from 'carbon-icons-svelte'
  import type { Customer, AttrErrors, FlatFee, Meta } from '../models'
  import { Row, Column, Tabs, Tab, TabContent } from 'carbon-components-svelte'
  import Users from '../users/Users.svelte'
  import Logistics from './Logistics.svelte'
  import FlatFees from '../flat_fees/List.svelte'
  import { online, customerId } from '../stores'
  import { getCustomer, getFlatFees, updateCustomer } from '../persistence'
  import Heading from './Heading.svelte'
  import GeneralContact from './GeneralContact.svelte'
  import { loaded } from '../helper'
  import { getQueryParam } from '../query'
  import BillingAddress from './billing_address/Index.svelte'
  import DeliveryAddresses from './delivery_addresses/Index.svelte'
  import SideBar from './side_bar/Index.svelte'
  import OrderPreview from './OrderPreview.svelte'
  import Orders from '../orders/Index.svelte'
  import OrdersTag from '../orders/Tag.svelte'
  import Payment from './Payment.svelte'
  import Invoice from './Invoice.svelte'
  import Shop from './Shop.svelte'
  import PurchasedProducts from './purchased_products/Index.svelte'
  import CustomerEngagements from './customer_engagements/Index.svelte'
  import InvalidCustomer from './invalid_customer/Index.svelte'
  import RepAndSector from './rep_and_sector/Index.svelte'
  import DisabledFields from '../shared/DisabledFields.svelte'
  import { push } from 'svelte-spa-router'
  import { enable as enableDirty, disable as disableDirty } from '../dirty'
  import { onMount, onDestroy } from 'svelte'
  import { extractData } from '../event'
  import { customerUrl } from '../urls'
  import NotAvailableOfflineNotification from '../shared/NotAvailableOfflineNotification.svelte'

  export let params: { id?: string } = {} // router params

  let selectedTab = { purchased_products: 1, customer_engagements: 2, orders: 3, settings: 4 }[getQueryParam('tab')]

  let customer: Customer = { id: params.id } as Customer
  let flatFees: FlatFee[] = []
  let billingModalOpen: boolean
  let deliveryModalOpen: boolean
  let success: string
  let error: string
  let errors: AttrErrors = {}
  let meta: Meta = {}
  let validationErrors: AttrErrors

  const update = async event => {
    ;({ errors = {}, error } = await updateCustomer(extractData(event).customer as Customer))
    if (!error) {
      success = event.detail.successMessage
      loadCustomer()
    }
  }

  const loadCustomer = async () => {
    ;({ customer = { id: $customerId }, meta, error, errors: validationErrors } = await getCustomer())
  }

  const loadFlatFees = async () => {
    flatFees = (await getFlatFees()) || []
  }

  const changeTab = tab => {
    push(customerUrl(customer, tab))
  }

  $: $customerId = params.id
  $: if ($customerId) {
    loadFlatFees()
    loadCustomer()
  }
  $: disabled = validationErrors || customer.status == 'inactive' || !$online

  onMount(enableDirty)

  onDestroy(disableDirty)
</script>

<style lang="scss" global>
  @import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/modules/_breakpoint';
  .flex-reverse-mobile {
    @include breakpoint-down('xlg') {
      order: 1;
    }
  }
</style>
