<Select
  loaded={reps.length > 0}
  labelText="Aussendienst"
  name="id"
  selected={selectedId}
  on:input={change}
  {...$$restProps}
>
  <SelectItem text={blankItemText} />
  {#each reps as rep}
    <SelectItem value={rep.id} text={rep.name} />
  {/each}
</Select>

<script lang="ts">
  import type { Rep } from '../../models'
  import { SelectItem } from 'carbon-components-svelte'
  import { getReps } from '../../persistence'
  import { createEventDispatcher, onMount } from 'svelte'
  import Select from '../../form/Select.svelte'
  import { extractData } from '../../event'

  export let selectedId
  export let blankItemText = ''

  let reps: Rep[] = []

  onMount(async () => {
    reps = (await getReps()) || []
  })

  const dispatch = createEventDispatcher()

  const change = event => {
    const data = extractData(event)
    const rep = reps.filter(rep => rep.id.toString() === data.id)[0]

    dispatch('change', rep)
  }
</script>
