{#if loading}
  <SkeletonText />
{:else if strong}
  <strong>{displayValue}</strong>
{:else}
  {displayValue}
{/if}

<script lang="ts">
  import { SkeletonText } from 'carbon-components-svelte'
  import { formatValue } from '../../format'

  export let value = undefined
  export let loading = false
  export let strong = false

  let displayValue
  $: displayValue = formatValue(value)
</script>
