{#if billingItems}
  <StructuredList condensed>
    <StructuredListHead>
      <StructuredListRow head>
        <StructuredListCell head>Fakturadatum</StructuredListCell>
        <StructuredListCell head>Geliefert</StructuredListCell>
        <StructuredListCell head>Preis</StructuredListCell>
        <StructuredListCell head>GR</StructuredListCell>
        <StructuredListCell head>MR</StructuredListCell>
        <StructuredListCell head>Netto</StructuredListCell>
        <StructuredListCell head>Total</StructuredListCell>
        <StructuredListCell head>Faktura</StructuredListCell>
        <StructuredListCell head />
      </StructuredListRow>
    </StructuredListHead>
    <StructuredListBody>
      {#each currentBillingItems as billingItem}
        <StructuredListRow>
          <StructuredListCell>{shortDate(billingItem.invoice.created_on)}</StructuredListCell>
          <StructuredListCell>{billingItem.quantity_delivered}</StructuredListCell>
          <StructuredListCell>{billingItem.price}</StructuredListCell>
          <StructuredListCell>{billingItem.discount || ''}</StructuredListCell>
          <StructuredListCell>{billingItem.volume_discount || ''}</StructuredListCell>
          <StructuredListCell>{billingItem.net_price}</StructuredListCell>
          <StructuredListCell>{billingItem.total}</StructuredListCell>
          <StructuredListCell>
            <Link href={erpUrl(`/invoices/${billingItem.invoice.id}`)} target="_blank">{billingItem.invoice.id} ↗︎</Link
            >
          </StructuredListCell>
          <StructuredListCell>
            <Link
              title="Retoure erfassen"
              target="_blank"
              href={erpUrl(`customers/${billingItem.invoice.customer_id}/return_shipments/new`, {
                'billing_item_ids[]': billingItem.id,
              })}
            >
              <TextNewLine />
            </Link>
          </StructuredListCell>
        </StructuredListRow>
      {/each}
      {#if billingItems.length > currentLimit}
        <Button kind="ghost" on:click={showAll}>Mehr anzeigen</Button>
      {/if}
    </StructuredListBody>
  </StructuredList>
{:else}
  <StructuredListSkeleton />
{/if}

<script lang="ts">
  import {
    StructuredList,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
    StructuredListSkeleton,
    Button,
    Link,
  } from 'carbon-components-svelte'
  import { TextNewLine } from 'carbon-icons-svelte'
  import { shortDate } from '../../date'
  import { erpUrl } from '../../urls'

  export let billingItems

  $: currentBillingItems = (billingItems || []).slice(0, currentLimit)

  const defaultLimit = 4
  let currentLimit = defaultLimit

  const showAll = () => {
    currentLimit = billingItems.length
  }
</script>
